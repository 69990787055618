import React, { useState } from 'react';
import classNames from 'classnames';
import MotionVisible from 'src/components/MotionVisible';
import slipper from 'src/assets/images/howtoplay/sneaker/slipper.png';
import pumper from 'src/assets/images/howtoplay/sneaker/pumper.png';
import clever from 'src/assets/images/howtoplay/sneaker/clever.png';
import unknown from 'src/assets/images/howtoplay/sneaker/unknown.png';
import {
  animationVariant,
  boxItemLeftVariant,
} from 'src/variables/animationVariant';
import { BubbleInfo, SneakerTab, StyledNFTDetailMobile } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import { renderImgModel, renderInfoDesc, renderInfoName } from '../method';

const SneakerNFTMobile = () => {
  const [selected, setSelected] = useState('slipper');

  return (
    <StyledNFTDetailMobile>
      <div className="container">
        <MotionVisible variants={animationVariant} className="title">
          Sneaker NFTs
        </MotionVisible>
        <MotionVisible variants={animationVariant} className="description">
          Different types of shoes give different access to certain games of the
          system
        </MotionVisible>
        <div className="content-wrapper">
          <MotionVisible
            variants={boxItemLeftVariant}
            className="nft-model"
            key={selected}
          >
            <BubbleInfo>
              <div className="wrapper flex-center">
                <TitleEffect text={renderInfoName(selected)} fontSize="36px" />
                <div className="desc">{renderInfoDesc(selected)}</div>
              </div>
            </BubbleInfo>
            <div className="img-model">
              <img className="img-fluid" src={renderImgModel(selected)} />
            </div>
          </MotionVisible>
          <div className="box-tabs">
            <div className="item-tabs flex-between">
              <SneakerTab
                className={classNames({
                  active: selected === 'slipper',
                })}
                onClick={() => setSelected('slipper')}
                key="slipper"
              >
                <div className="wrapper">
                  <img className="img-fluid" src={slipper} alt="slipper" />
                  <span className="name flex-center">Slipper</span>
                </div>
              </SneakerTab>
              <SneakerTab
                className={classNames({
                  active: selected === 'pumper',
                })}
                onClick={() => setSelected('pumper')}
                key="pumper"
              >
                <div className="wrapper">
                  <img className="img-fluid" src={pumper} alt="pumper" />
                  <span className="name flex-center">Pumper</span>
                </div>
              </SneakerTab>
              <SneakerTab
                className={classNames({
                  active: selected === 'clever',
                })}
                onClick={() => setSelected('clever')}
                key="clever"
              >
                <div className="wrapper">
                  <img className="img-fluid" src={clever} alt="clever" />
                  <span className="name flex-center">Clever</span>
                </div>
              </SneakerTab>
              <SneakerTab
                className={classNames({
                  active: selected === 'unknown',
                })}
                onClick={() => setSelected('unknown')}
                key="unknown"
              >
                <div className="wrapper text-center unknown">
                  <img className="img-fluid" src={unknown} alt="unknown" />
                </div>
              </SneakerTab>
            </div>
          </div>
        </div>
      </div>
    </StyledNFTDetailMobile>
  );
};

export default SneakerNFTMobile;
