import React from 'react';
import redOLayer from 'src/assets/images/redOLayer.png';
import MotionVisible from 'src/components/MotionVisible';
import TitleEffect from 'src/components/TitleEffect';
import { animationVariant } from 'src/variables/animationVariant';
import { teamMember } from '../teamMember';
import { StyledTeam, StyledTeamWrapper } from './styled';

const TeamDesktop = () => {
  return (
    <StyledTeamWrapper>
      <StyledTeam>
        <div className="container">
          <MotionVisible className="teamTitle" variants={animationVariant}>
            <TitleEffect text="Core Team" fontSize="100px" />
          </MotionVisible>
          <MotionVisible className="description" variants={animationVariant}>
            We cooperate with experienced professors who are blockchain,
            e-commerce associates, gaming studios, and finance experts. And
            dynamic young members who adapt quickly to technology and experience
            in the fitness & healthy lifestyle field.
          </MotionVisible>
          <div className="teamSlider">
            {teamMember
              .filter((t) => t.position !== 'Advisor')
              .map((member, i) => (
                <MotionVisible className="teamCard" key={i} variants={animationVariant}>
                  <div className="cardImage">
                    <img src={member.avatar} />
                  </div>
                  <div className="teamInfo">
                    <div className="position">{member.position}</div>
                    <div className="name">{member.name}</div>
                    {/* <div className="link flex-center">
                      <a href="">
                        <img src={iconTwitterSm} />
                      </a>
                      <a href="">
                        <img src={iconLinkedinSm} />
                      </a>
                    </div> */}
                  </div>
                </MotionVisible>
              ))}
          </div>
        </div>
      </StyledTeam>
      <StyledTeam>
        <div className="container">
          <div className="advisors">
            <MotionVisible className="teamTitle" variants={animationVariant}>
              <TitleEffect text="Our Advisors" fontSize="100px" />
            </MotionVisible>
            <div className="cardWrapper">
              {teamMember
                .filter((t) => t.position === 'Advisor')
                .map((member, i) => (
                  <MotionVisible className="teamCard" key={i} variants={animationVariant}>
                    <div className="cardImage">
                      <img src={member.avatar} />
                    </div>
                    <div className="teamInfo">
                      <div className="position">{member.position}</div>
                      <div className="name">{member.name}</div>
                      {/* <div className="jobDesc">{member.jobDesc}</div> */}
                    </div>
                  </MotionVisible>
                ))}
            </div>
          </div>
        </div>
      </StyledTeam>
    </StyledTeamWrapper>
  );
};

export default TeamDesktop;
