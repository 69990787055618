import styled from 'styled-components';
import notFoundBg from 'src/assets/images/notFoundBg.png';
import notFoundBgMb from 'src/assets/images/notFoundBgMb.png';

export const StyledPage404NotFound = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${notFoundBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media (max-width: 767px) {
    background-image: url(${notFoundBgMb});
  }

  .wrapper {
    flex-direction: column;
    width: 100%;
    height: 100%;

    .btn-home {
      margin-top: 32px;
    }

    @media (max-width: 768px) {
      .t-1 {
        font-size: 80px;
      }
      .t-2 {
        font-size: 40px;
      }
    }
  }
`;
