import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import NFTDetailDesktop from './NFTDetailDesktop';
import NFTDetailMobile from './NFTDetailMobile';

const NFTDetail = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<NFTDetailDesktop {...props} />}
      mobileView={<NFTDetailMobile {...props} />}
    />
  );
};

export default NFTDetail;
