import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import TokenDesktop from './TokenDesktop';
import TokenMobile from './TokenMobile';

const Token = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<TokenDesktop {...props} />}
      mobileView={<TokenMobile {...props} />}
    />
  );
};

export default Token;
