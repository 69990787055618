import styled from 'styled-components';
import bgTab from 'src/assets/images/bgTab.png';
import bgTabActive from 'src/assets/images/bgTabActive.png';

const StyledFilterButtonTabs = styled.ul`
  display: flex;
  overflow: auto;
  margin: 0;
  padding: 0;
`;

const StyledFilterButton = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  text-algin: center;
  height: 48px;
  width: 130px;
  padding: 0 12px;
  &:not(:last-child) {
    margin-right: 12px;
  }

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;

  background: ${({ isSelected }) =>
    isSelected ? `url(${bgTabActive})` : `url(${bgTab})`};
  background-size: 100% 100%;
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#d0d1e0')};

  &:hover {
    background: url(${bgTabActive});
    background-size: 100% 100%;
    color: #ffffff;
  }
`;

export { StyledFilterButtonTabs, StyledFilterButton };
