import styled from 'styled-components';

export const StyledTopics = styled.section`
  padding: 40px 0;
  background: linear-gradient(180deg, #0d2553 5.48%, #0b1d41 84.67%);
  .topicWrapper {
    .header {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #b0b4e4;
      padding-bottom: 8px;
    }
    .topicTitle {
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.25px;
      color: ${({ theme }) => theme.title};
      margin-bottom: 16px;
    }
    .desc {
      padding: 0 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.5px;
      color: #ffffff;
    }
    .counterContainer {
      text-align: center;
      padding-top: 40px;
      .counterItem {
        width: 100%;
        margin: 0 0 32px 0;
        padding: 24px 0;
        background: rgba(255, 158, 88, 0.1);
        box-shadow: inset 0px 5px 34px rgba(255, 255, 255, 0.25);
        border-radius: 18.8475px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border-radius: 18.8475px;
          padding: 2px;
          background: linear-gradient(
            100.01deg,
            #ff8a34 5.41%,
            rgba(255, 138, 52, 0) 32.38%,
            rgba(255, 138, 52, 0) 65.19%,
            #ff8a34 89.79%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
        }
        .number {
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 8px;
        }
        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #ff9e58;
        }
      }
    }

    .activitiesContainer {
      max-width: 1292px;
      margin: auto;
      .activityItem {
        img {
          cursor: pointer;
          display: block;
          width: 100%;
          border-radius: 24px;
        }
        .ant-image-mask {
          display: none;
          .ant-image-preview-img {
            /* cursor: none !important; */
            pointer-events: none !important;
          }
        }
      }
      @media (max-width: 568px) {
        /* .slick-list {
          .slick-slide {
            position: relative;
            opacity: 0.7;
            top: 0;
            transition: all ease 0.5s;
          }
          .slick-active {
            position: relative;
            opacity: 1;
            top: -16px;
            transition: top ease 0.5s;
          }
        } */
      }
    }
  }

  @media (min-width: 767px) {
    .topicWrapper {
      .counterContainer {
        max-width: 500px;
        margin: 0 auto !important;
        .counterItem {
          .number {
            font-size: 26px;
          }
          .label {
            font-size: 14px;
          }
        }
      }
      .activitiesContainer {
        margin: 0 -15px;
        .activityItem {
          max-width: 100%;
        }
      }
    }
  }
  @media (max-width: 428px) {
    .topicWrapper {
      .activitiesContainer {
        margin: 0 -15px;
      }
    }
  }
`;
