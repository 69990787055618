import React from 'react';
import tokenDemand from 'src/assets/images/onepaper/tokenDemand2.png';
import tokenDemandMb from 'src/assets/images/onepaper/tokenDemandMb2.png';
import tokenDemandTxtMb from 'src/assets/images/onepaper/tokenDemandTxtMb.png';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import lineArrowSm from 'src/assets/images/onepaper/lineArrowSm.svg';
import { StyledTokenDemand } from './styled';
import ResponsiveLayout from '../ResponsiveLayout';
import MotionVisible from '../MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';
import TitleEffect from '../TitleEffect';

const TokenDemand = () => {
  const contentDesktop = () => {
    return (
      <div className="container">
        <MotionVisible className="header" variants={animationVariant}>
          <TitleEffect className="t-2" text="TOKEN DEMAND" fontSize="70px" />
          <img className="text-center" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible className="img-wrapper" variants={animationVariant}>
          <img className="img-fluid" src={tokenDemand} />
        </MotionVisible>
      </div>
    );
  };

  const contentMobile = () => {
    return (
      <div className="container">
        <MotionVisible className="header-sm" variants={animationVariant}>
          <TitleEffect className="t-1" text="TOKEN DEMAND" fontSize="44px" />
          <img className="text-center img-fluid" src={lineArrowSm} alt="line" />
        </MotionVisible>
        <MotionVisible
          className="img-wrapper text-center"
          variants={animationVariant}
        >
          <img className="img-fluid img-demand" src={tokenDemandMb} />
          <img className="img-fluid text-demand mt-4" src={tokenDemandTxtMb} />
        </MotionVisible>
      </div>
    );
  };

  return (
    <StyledTokenDemand>
      <ResponsiveLayout
        desktopView={contentDesktop()}
        mobileView={contentMobile()}
      />
    </StyledTokenDemand>
  );
};

export default TokenDemand;
