import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import B2ETokenDesktop from './B2ETokenDesktop';
import B2ETokenMobile from './B2ETokenMobile';

const B2EToken = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<B2ETokenDesktop {...props} />}
      mobileView={<B2ETokenMobile {...props} />}
    />
  );
};

export default B2EToken;
