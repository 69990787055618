import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import IntroHTPDesktop from './IntroHTPDesktop';
import IntroHTPMobile from './IntroHTPMobile';

const IntroHTP = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<IntroHTPDesktop {...props} />}
      mobileView={<IntroHTPMobile {...props} />}
    />
  );
};

export default IntroHTP;
