import styled from 'styled-components';

export const StyledTokenomicMobile = styled.section`
  .container {
    padding-top: 64px;
    width: 100%;
    .header {
      text-align: center;
      .t-1 {
        line-height: 32px;
      }
    }
    .content-box {
      margin: 68px 0 92px 0;
      padding: 0 24px;
      width: 100%;
      text-align: center; 
      img {
        @media (min-width: 767px) {
          width: 350px;
          margin: 0 auto;
        }
      }
    }
  }
`;
