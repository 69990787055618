/* eslint-disable indent */
import styled from 'styled-components';
import { Modal } from 'antd';
import modalDetailBg from 'src/assets/images/modalDetailBg.png';

const StyledModalDetailSneaker = styled(Modal)`
  width: 950px !important;
  height: 450px;
  background-image: url(${modalDetailBg});
  background-size: 100% 100%;
  .ant-modal-content {
    background: transparent;
    height: 100%;
    .ant-modal-close {
      img {
        width: 100%;
      }
    }
    @media only screen and (max-width: 576px) {
      .ant-modal-body {
      }
    }
  }
  .ant-modal-body {
    height: 100%;
  }
  .detail-content {
    height: 100%;
    padding: 0 80px;
    .description {
      padding-top: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #e9e9ec;
    }
  }
`;

export { StyledModalDetailSneaker };
