import { Col, Row } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import iconClose from 'src/assets/images/howtoplay/iconClose.png';
import iconCloseMb from 'src/assets/images/howtoplay/iconCloseMb.png';
import TitleEffect from 'src/components/TitleEffect';
import { gameInfo } from './gameInfo';
// styled
import { StyledModalGameInfo } from './styled';

const ModalGameInfo = (props) => {
  const { selected, visible, onCancel, ...other } = props;

  return (
    <StyledModalGameInfo
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      destroyOnClose
      closable
      closeIcon={
        <img src={isMobile ? iconCloseMb : iconClose} alt="iconClose" />
      }
      {...other}
    >
      <Row
        className="detail-content"
        align="middle"
        justify="center"
      >
        <Col sm={24} md={11}>
          <img
            className="img-fluid"
            src={gameInfo[selected]?.img}
            alt="sneakerSample"
          />
        </Col>
        <Col sm={24} md={13} className="col-content">
          <TitleEffect
            text={gameInfo[selected]?.name}
            fontSize={isMobile ? '28px' : '36px'}
          />
          <div className="description">{gameInfo[selected]?.desc}</div>
        </Col>
      </Row>
    </StyledModalGameInfo>
  );
};

export default ModalGameInfo;
