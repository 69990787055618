import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import AnimatedCursor from 'react-animated-cursor';
import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import { StyledAnimatedCursor, StyledLayout, StyledContainer } from './styled';
import Header from 'src/components/Header/Header';
import { Affix, BackTop } from 'antd';
import { scrollTo } from 'src/utils';

const PrivacyPage = (props) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    handleScrollTop();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const isEmbed = parseInt(params.get('isEmbed')) === 1;

  return (
    <ThemeProvider theme={themeMode}>
      {/* <StyledAnimatedCursor>
        <AnimatedCursor
          innerSize={12}
          outerSize={30}
          color={theme === 'dark' ? '255, 255, 255' : '90, 91, 116'}
          outerAlpha={0.2}
          innerScale={1}
          outerScale={2}
          trailingSpeed={2}
        />
      </StyledAnimatedCursor> */}
      <StyledLayout>
        {!isEmbed && (
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
        )}

        <StyledContainer
          className="container"
          style={{ paddingTop: isEmbed ? '20px' : '0' }}
        >
          <h1>PRIVACY POLICY</h1>
          <p>Effective date: 11th Jan, 2022</p>
          <div>
            <h2>I. OVERVIEW</h2>
            <ol>
              <li>
                <b>METAVERSE APPLICATION PTE. LTD.</b>
                <span>
                  which is a company duly incorporated in Singapore (hereinafter
                  referred to as the “
                </span>
                <b>Company</b>
                <span>
                  ”), operates the app called CALO (hereinafter referred to as
                  the “
                </span>
                <b>App</b>
                <span>”).</span>
              </li>
              <li>
                <span>
                  This privacy policy (hereinafter referred to as the “
                </span>
                <b>Privacy Policy</b>
                <span>
                  ”) describes how the Company collects, uses, and disclosures
                  the user’s personal data and how to manage the user’s privacy
                  controls and rights when the user (hereinafter referred to as
                  the “
                </span>
                <b>Client</b>
                <span>
                  ”) uses the App and the choices the Client has associated with
                  that data. By using the App, the Client agrees to the
                  collection and use of information in accordance with the
                  Privacy Policy. The Privacy Policy is a legally binding
                  agreement between the Client and the Company. By accessing and
                  using the App, the Client acknowledges that the Client has
                  read, understood, and agreed to be bound by the terms of the
                  Privacy Policy.
                </span>
              </li>
              <li>
                <span>Please also read CALO Terms and Conditions at </span>
                <span>
                  <a
                    href="https://indoor.fun/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://indoor.fun/terms-and-conditions
                  </a>
                </span>
                <span>
                  {' '}
                  which sets out the terms governing the App. Unless otherwise
                  defined in the Privacy Policy, the terms used in the Privacy
                  Policy have the same meanings as in CALO Terms and Conditions.
                </span>
              </li>
              <li>
                <span>
                  The Privacy Policy does not apply to third-party’s products or
                  services or the practices of companies that the Company does
                  not own or control, or to individuals that the Company does
                  not employ or manage.
                </span>
              </li>
            </ol>
            <h2>II. COLLECTION OF INFORMATION</h2>
            <p>
              The Company collects information about the Client, including
              information that directly or indirectly identifies the Client, if
              the Client or the Client’s other members choose to share it with
              the Company. The Company collects information when the Client uses
              the App in a few different ways, including but not limited to
              tracking, completing, or uploading activities. There are also
              several opportunities for the Client to share information about
              himself/herself, the Client’s friends and the Client’s activities
              on or through the App.
            </p>
            <ol>
              <li>
                <b>Account, profile, activity and use information</b>
                <p>
                  While using the App, the Company may collect basic account
                  information including but not limited to the Client’s name,
                  email address, date of birth, gender, weight, phone number,
                  address (state, province, city), ZIP/Postal code, photos,
                  selfies, username and password that helps secure and provide
                  the Client with access to the App. The Company may request
                  access to the Client's connected device in order to use the
                  camera, microphone, or any other functions that assist the
                  Client in taking photos or creating content for his/her
                  profile.
                </p>
                <p>
                  Profile, activity and use information are collected about the
                  Client when the Client chooses to upload the content and
                  activity (including date, time and geo-location information as
                  well as the Client’s speed and pace and perceived exertion) or
                  post, join a challenge, add the Client’s equipment usage, view
                  others’ activities or otherwise use the App.
                </p>
              </li>
              <li>
                <b>Location information</b>
                <p>
                  The Company collects and processes location information when
                  the Client signs up and uses the App. The Company does not
                  track the Client’s device location while the Client is not
                  using the App, but in order to run and monitor the App, it is
                  necessary for the Company to track the Client’s device
                  location while the Client uses the App. If the Client would
                  like to stop the device location tracking, the Client may do
                  so at any time by adjusting the Client’s device settings.
                </p>
              </li>
              <li>
                <b>Content the Client shares</b>
                <p>
                  The Company gathers information from the photos, posts,
                  comments, kudos, ratings, reviews, and other content the
                  Client shares on or through the App, including when the Client
                  participates in the challenges and/or event on or through the
                  App.
                </p>
              </li>
              <li>
                <b>Contacts Information</b>
                <p>
                  The Client can choose to add his/her contacts’ information by
                  connecting his/her contacts from the Client’s mobile device or
                  social networking accounts to the App. If the Client chooses
                  to share the Client’s contacts with the App, the Company will,
                  in accordance with the Client’s instructions, access and store
                  his/her contacts’ information in order to identify connections
                  and help the Client connect with them. The company collects
                  this information only with the Client’s explicit consent, and
                  the Client may remove that consent at any time from the
                  Client’s device privacy settings.
                </p>
              </li>
              <li>
                <b>Connected Devices and Apps Information</b>
                <p>
                  The Company collects information from devices and apps the
                  Client connects to the App. Such information can be synced to
                  the App for certain purposes upon the Client’s explicit
                  consent. For example, the Client may connect his/her
                  smartwatch or Strava account to the App and information from
                  these devices and apps will be passed along to the App.
                </p>
              </li>
              <li>
                <b>Health Information</b>
                <p>
                  The Company may collect or infer health information. Certain
                  health information may be inferred from sources such as heart
                  rate or other measurements, including power, cadence, and
                  weight or other indicators.
                </p>
                <p>
                  Before the Client can upload health information to the App,
                  the Client must give his/her explicit consent to the
                  processing of that health information by the Company. The
                  Client can withdraw the Client’s consent to the Company
                  processing the Client’s health information at any time from
                  the Client’s device privacy settings.
                </p>
              </li>
              <li>
                <b>Third-party accounts Information</b>
                <p>
                  The Company allows the Client to sign up and log in to the App
                  using accounts the Client creates with third-party products
                  and services, such as Google (collectively referred to as,
                  “Third-Party Accounts”). If the Client accesses the App with
                  Third-Party Accounts, the Company will collect information
                  that the Client has agreed to make available such as the
                  Client’s name, email address, profile information and
                  preferences and other information. This information is
                  collected by the Third-Party Account provider and is provided
                  to the Company under their privacy policies. The Client
                  generally controls the information that the Company receives
                  from these sources using the privacy controls in the Client’s
                  Third-Party Account.
                </p>
              </li>
              <li>
                <b>Technical Information and Log Files</b>
                <p>
                  The Company may also collect information from the Client’s
                  browser, computer, or mobile device, which provides the
                  Company with technical information when the Client accesses or
                  uses the App. This technical information includes the Client’s
                  device and network information, computer’s Internet Protocol
                  address, browser type, browser version, the pages of the App
                  that the Client visits, the time and date of the Client’s
                  visit, the time spent on those, unique device identifiers and
                  other diagnostic data, cookies, log files and analytics
                  information.
                </p>
              </li>
              <li>
                <b>Tracking Data</b>
                <p>
                  The Company may use third-party web analytics services such as
                  those provided by Google Analytics. These service providers
                  use cookies and other technologies to help the Company analyze
                  how the Client interacts with the App. The information
                  collected by the technology will be disclosed to or collected
                  directly by these service providers. The Client can opt-out of
                  having made his/her activity on the App available to Google
                  Analytics by installing the Google Analytics opt-out browser
                  add-on.
                </p>
              </li>
              <li>
                <b>Other Information</b>
                <p>
                  The Company may, at any time, seek to obtain information about
                  the Client from other sources. The Company may receive or
                  affirmatively gather information about the Client from such
                  sources and may combine it with the information that the
                  Company already has for any purpose described throughout the
                  Privacy Policy. The extent to which the Company may obtain
                  information from such sources is governed by the terms of the
                  Client’s relationship with that source.
                </p>
              </li>
            </ol>
            <h2>III. USE OF INFORMATION</h2>
            <p>
              In order to make the App available to the Client and/or to meet
              the legal obligation, the Company may need to collect and use
              certain information of the Client. If the Client does not provide
              the information that the Company requests, the Company may not be
              able to provide the Client with the requested products or services
              on or through the App. Any information the Company collects from
              the Client may be used for the following purposes:
            </p>
            <ol>
              <li>
                <b>To provide and maintain the App</b>
                <p>
                  The Company uses the information the Company collects and
                  receives to provide and maintain the App, including providing
                  the Client with the ability to:
                </p>
                <p>
                  a. Participate in the challenges and events on or through the
                  App.
                </p>
                <p>
                  b. Record the Client’s activities and analyze the Client’s
                  performance.
                </p>
                <p>c. Interact with other Clients.</p>
                <p>d. Manage the Client’s training.</p>
                <p>e. Visualize the Client’s activities.</p>
              </li>
              <li>
                <b>To customize the Client’s experience</b>
                <p>
                  The Company uses the information the Company collects about
                  the Client, the Client’s followers, and the Client’s
                  activities to customize his/her experience. For example, the
                  company may suggest the activities, challenges, or events that
                  may interest the Client, athletes that the Client may want to
                  follow, or new features that the Client may want to try.
                </p>
              </li>
              <li>
                <b>To protect the Client and the App</b>
                <p>
                  The Company uses the information the Company collects to
                  protect Clients, enforce the CALO Terms and Conditions and
                  promote safety. The Company can find and remove content that
                  violates the CALO Terms and Conditions such as hate speech or
                  spam, as well as suspend or terminate accounts that share such
                  content.
                </p>
              </li>
              <li>
                <b>To improve the App</b>
                <p>
                  The Company also uses the information the Company collects to
                  analyze, develop and improve the App. To do this, the Company
                  may use third-party analytics providers to gain insights into
                  how the App is used and to help the Company improve the App.
                </p>
              </li>
              <li>
                <b>To communicate with the Client</b>
                <p>
                  The Company uses the information the Company collects to
                  provide support in response to the Client’s requests and
                  comments. The Company may also use the information the Company
                  collects to market and promote the App, activities and events
                  on or through the App, and other commercial products or
                  services. This includes marketing and push communications,
                  where the Client has not opted out of receiving such messages
                  and notifications.
                </p>
              </li>
              <li>
                <b>To aggregate information</b>
                <p>
                  The Company does not sell the Client’s personal information.
                  The Company may aggregate and centralize the information the
                  Client and others make available in connection with the App
                  and post it publicly or share it with third parties for
                  purposes of analytics, innovation, and to provide enhanced
                  services on the App. Examples of the type of information the
                  Company may aggregate include information about equipment,
                  usage, demographics, routes and performance.
                </p>
              </li>
              <li>
                <b>To detect, prevent and address technical issues</b>
                <p>
                  The Company may use the information the Company collects to
                  detect, prevent and address technical issues.
                </p>
              </li>
            </ol>
            <h2>IV. TRANSFER OF INFORMATION</h2>
            <p>
              The App is operated from Singapore. If the Client is located
              outside of Singapore and chooses to use the App or provide
              information to the Company, the Client acknowledges and
              understands that the Client’s information will be transferred,
              processed and stored in Singapore, as it is necessary to provide
              the App and perform the CALO Terms and Conditions. The data
              protection laws in Singapore may differ from those of the country
              in which the Client is located, and the Client’s personal
              information may be subject to access requests from governments,
              courts, or law enforcement in Singapore according to the laws of
              Singapore. Singapore privacy laws may not be as protective as
              those in the Client’s jurisdiction.
            </p>
            <h2>V. SHARING OF INFORMATION</h2>
            <p>
              The Company may share the Client’s personal information in
              accordance with the Client’s preferences, as needed to run the
              Company’s business and provide the App, and where required for
              legal purposes, as set forth below.
            </p>
            <ol>
              <li>
                <b>Service Providers</b>
                <p>
                  The Company may share the Client’s personal information with
                  third-parties who provide services to the Company such as
                  supporting, improving, promoting and securing the App, or
                  fulfilling orders. These service providers only have access to
                  the information necessary to perform these limited functions
                  on the Company’s behalf and are required to protect and secure
                  the Client’s information. The Company may also engage service
                  providers to collect information about the Client’s use of the
                  App over time on the Company’s behalf, so that the Company or
                  they may promote the Company or display information that may
                  be relevant to the Client’s interests on the App or other
                  websites or services.
                </p>
              </li>
              <li>
                <b>Publicly Available Information</b>
                <p>
                  When the Client joins the Company’s community, the Client’s
                  profile and activities are set to be viewable by everyone by
                  default. The Client’s name and other profile information are
                  viewable by other CALO members and the public and, subject to
                  the Client’s privacy controls, additional information and
                  content the Client shares may also be viewable. For example,
                  the Client’s photos may be accessed by other CALO members and
                  non-members or viewable on publicly accessible the Company’s
                  pages or in search engine results. The Company provides a
                  variety of tools to control the sharing of the Client’s
                  information and encourage the CALO members to adjust their
                  controls to align with their desired experience.
                </p>
              </li>
              <li>
                <b>Sharing Information and Activities</b>
                <p>
                  As a social network, the Company shares the Client’s
                  information with other CALO members and the public in
                  accordance with the Client’s privacy controls. The Clients can
                  change the visibility of the activities the Client uploads to
                  the App. Also, the Client can tell the Company about important
                  locations, such as the Client’s home or work address, by
                  enabling a privacy in the Client’s settings. The Company will
                  make private any portion of the Client’s activity that starts
                  or ends in Client’s privacy. The Client can also share his/her
                  activities with his/her contacts via text, email, or on social
                  media applications like Facebook, Instagram, and Twitter. If
                  the Client links his/her CALO account to other social networks
                  and shares his/her activities, they will be viewable on such
                  third-party platforms, including the Client’s location
                  information. The Client should use caution when sharing
                  information via third-parties and the Client should carefully
                  review the privacy practices of such third-parties.
                </p>
              </li>
              <li>
                <b>
                  Affiliates and Acquirers of the Company’s Business or Assets
                </b>
                <p>
                  The Company may share the Client’s information with affiliates
                  under common control with the Company, who are required to
                  comply with the terms of the Privacy Policy with regard to the
                  Client’s information. If the Company becomes involved in a
                  business combination, securities offering, bankruptcy,
                  reorganization, dissolution or other similar transaction, the
                  Company may share or transfer the Client’s information in
                  connection with such transaction.
                </p>
              </li>
              <li>
                <b>Legal Requirements</b>
                <p>
                  The Company may preserve and share the Client’s information
                  with third-parties, including law enforcement, public or
                  governmental agencies, or private litigants, within or outside
                  the Client’s country of residence, if the Company determines
                  that such disclosure is allowed by the law or reasonably
                  necessary to comply with the law, including to respond to
                  court orders, warrants, subpoenas, or other legal or
                  regulatory processes. The Company may also retain, preserve or
                  disclose the Client’s information if the Company determines
                  that this is reasonably necessary or appropriate to prevent
                  any person from death or serious bodily injury, to address
                  issues of national security or other issues of public
                  importance, to prevent or detect violations of the CALO Terms
                  and Conditions or fraud or abuse of the Company or its
                  members, or to protect its operations or its property or other
                  legal rights, including by disclosure to the Company’s legal
                  counsel and other consultants and third-parties in connection
                  with actual or potential litigation.
                </p>
              </li>
            </ol>
            <h2>VI. PROTECTION OF INFORMATION</h2>
            <p>
              The Company takes several measures to safeguard the collection,
              transmission and storage of the data the Company collects. The
              Company employs reasonable protections for the Client’s
              information that are appropriate to its sensitivity.
            </p>
            <p>
              The security of the Client’s information is important to the
              Company, but remember that no method of transmission over the
              Internet, or method of electronic storage is 100% secure. While
              the Company strives to use commercially acceptable means to
              protect the Client’s information, the Company cannot guarantee its
              absolute security.
            </p>
            <ol>
              <li>
                <b>Privacy Controls</b>
                <p>
                  The Company offers several features and settings to help the
                  Client manage his/her privacy and shares his/her activities.
                  Most privacy controls are located on the Client’s privacy
                  controls page, but some are specific to individual activities
                  or athletes. The Company provides the Client the option to
                  make the Client’s activities private.
                </p>
              </li>
              <li>
                <b>Adjust Notification and Email Preferences</b>
                <p>
                  The Company offers various ways to manage the notifications
                  the Client receives. The Client can choose to stop receiving
                  certain emails and notifications by indicating his/her
                  preference.
                </p>
              </li>
              <li>
                <b>Updating Account Information</b>
                <p>
                  The Client may correct, amend or update profile or account
                  information at any time by adjusting that information in the
                  Client’s account settings.{' '}
                </p>
              </li>
              <li>
                <b>Deleting Information and Accounts and Downloading Data</b>
                <p>
                  The Client can delete his/her account or download his/her data
                  using the Company’s self-service tools. After the Client makes
                  a deletion request, the Company permanently and irreversibly
                  deletes the Client’s personal data from its systems, including
                  backups. Once deleted, the Client’s data, including his/her
                  account, activities and place on leaderboards cannot be
                  reinstated. Following the Client’s deletion of his/her
                  account, it may take up to a day to delete the Client’s
                  personal information and system logs from the Company’s
                  systems. Additionally, the Company may retain information
                  where deletion requests are made to comply with the law and
                  take other actions permitted by law.
                </p>
                <p>
                  Note that content the Client has shared with others, such as
                  photos, or that others have copied may also remain visible
                  after the Client has deleted his/her account or deleted
                  specific information from the Client’s own profile. The
                  Client’s public profile may be displayed in search engine
                  results until the search engine refreshes its cache.
                </p>
                <p>
                  The Company also provides the Client the option to remove
                  individual activities the Client has posted without deleting
                  his/her account. Once removed, the activities may remain on
                  the Company’s systems until the Client deletes his/her
                  account.
                </p>
              </li>
            </ol>
            <h2>VII. RETENTION OF INFORMATION</h2>
            <p>
              The Company retains information as long as it is necessary to
              provide the App to the Client and others, subject to any legal
              obligations to further retain such information. Additionally, the
              Company may retain information to comply with the law, prevent
              fraud, collect fees, resolve disputes, troubleshoot problems,
              assist with investigations, enforce the CALO Terms and Conditions
              and take other actions permitted by law. The information the
              Company retains will be handled in accordance with the Privacy
              Policy. Information connected to the Client that is no longer
              necessary and relevant to provide the App may be de-identified or
              aggregated with other non-personal data to provide insights that
              are commercially valuable to the Company, such as statistics of
              the use of the App. This information will be de-associated with
              the Client’s name and other identifiers.
            </p>
            <h2>VIII. LINKS TO OTHER SITES</h2>
            <p>
              The App may contain links to other sites that are not operated by
              the Company. If the Client clicks on a third-party’s link, the
              Client will be directed to that third-party’s site. The Company
              strongly advises the Client to review the privacy policy of every
              site the Client visits. The Company has no control over and
              assumes no responsibility for the content, privacy policies or
              practices of any third-party sites or services.
            </p>
            <h2>IX. CHANGES TO THE PRIVACY POLICY</h2>
            <p>
              The Company may update the Privacy Policy from time to time
              without notification. The Company will update the “effective date”
              at the top of the Privacy Policy. The Client is advised to review
              the Privacy Policy periodically for any changes. Changes to the
              Privacy Policy are effective when they are posted on this page and
              the Client agrees to be bound by such modifications or updates.
            </p>
            <h2>X. CONTACT US</h2>
            <p>
              If the Client has any questions about the Privacy Policy, please
              contact the Company via email:{' '}
              <a href="mailto:tech@calo.run">tech@calo.run</a>
            </p>
          </div>
        </StyledContainer>
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default PrivacyPage;
