import React from 'react';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { useAppStore } from 'src/hooks/useAppStore';
import { teamMember } from '../teamMember';
import { StyledTeamMobile } from './styled';
import breakpoint from 'src/variables/breakpoint';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant, boxItemRightVariant } from 'src/variables/animationVariant';

const TeamMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const slideSettings = {
    className: 'center',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    centerMode: true,
    centerPadding: '0px',
    speed: 500,
    dots: true,
    rows: 1,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: breakpoint.lgMin,
        settings: {
          centerPadding: '200px',
          infinite: false,
          centerMode: false,
          rows: 1,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '0',
          infinite: true,
          centerMode: true,
          rows: 1,
          slidesPerRow: 2,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: '#AAB2C8',
          borderRadius: '50%',
        }}
      ></div>
    ),
  };

  return (
    <StyledTeamMobile isDarkMode={isDarkMode}>
      <div className="container">
        <MotionVisible className="teamTitle" variants={animationVariant}>
          <TitleEffect text="Core Team" />
        </MotionVisible>
        <MotionVisible className="description" variants={animationVariant}>
          We cooperate with experienced professors who are blockchain,
          e-commerce associates, gaming studios, and finance experts. And
          dynamic young members who adapt quickly to technology and experience
          in the fitness & healthy lifestyle field.
        </MotionVisible>
        <div className="teamSlider">
          <SliderSlick
            showArrow={false}
            settings={slideSettings}
            paddingX="0"
            paddingY="10px"
          >
            {teamMember
              .filter((t) => t.position !== 'Advisor')
              .map((member, i) => (
                <MotionVisible className="teamCard" key={i} variants={boxItemRightVariant}>
                  <div className="cardImage">
                    <img src={member.avatar} />
                  </div>
                  <div className="teamInfo">
                    <div className="position">{member.position}</div>
                    <div className="name">{member.name}</div>
                    {/* <div className="jobDesc">{member.jobDesc}</div> */}
                  </div>
                </MotionVisible>
              ))}
          </SliderSlick>
        </div>
        <div className="advisors">
          <MotionVisible className="teamTitle" variants={animationVariant}>
            <TitleEffect text="Our Advisors" />
          </MotionVisible>
          <div className="teamSlider">
            <SliderSlick
              showArrow={false}
              settings={slideSettings}
              paddingX="0"
              paddingY="10px"
            >
              {teamMember
                .filter((t) => t.position === 'Advisor')
                .map((member, i) => (
                  <MotionVisible className="teamCard" key={i} variants={animationVariant}>
                    <div className="cardImage">
                      <img src={member.avatar} />
                    </div>
                    <div className="teamInfo">
                      <div className="position">{member.position}</div>
                      <div className="name">{member.name}</div>
                      {/* <div className="jobDesc">{member.jobDesc}</div> */}
                    </div>
                  </MotionVisible>
                ))}
            </SliderSlick>
          </div>
        </div>
      </div>
    </StyledTeamMobile>
  );
};

export default TeamMobile;
