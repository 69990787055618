import styled from 'styled-components';
import boxPolygonMb from 'src/assets/images/boxPolygon.png';
import roadmapBgMb from 'src/assets/images/roadmapBgMb.png';

export const StyledRoadmapMobile = styled.div`
  padding: 100px 0;
  position: relative;
  background-image: url(${roadmapBgMb});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .container {
    padding: 0;
    max-width: 1216px;
    margin: 0 auto;
    .title {
      font-weight: 400;
      font-size: 60px;
      line-height: 70px;
      text-align: center;
      color: #e9e9ec;
      padding-bottom: 24px;
    }
    .roadmap-wrapper {
      width: 100%;
      margin-top: 32px;
      text-align: center;
      @media (max-width: 568px) {
        .roadmap-mb {
          display: block;
          width: 100%;
        }
        .roadmap-tbl {
          display: none;
        }
      }
      @media (min-width: 569px) {
        .roadmap-mb {
          display: none;
        }
        .roadmap-tbl {
          display: block;
          width: 100%;
        }
      }
    }
  }
`;
