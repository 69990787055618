import { Col, Row } from 'antd';
import React from 'react';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import lineArrowSm from 'src/assets/images/onepaper/lineArrowSm.svg';
import { animationVariant } from 'src/variables/animationVariant';
import MotionVisible from '../MotionVisible';
import TitleEffect from '../TitleEffect';
import { StyledSlogan } from './styled';

const Slogan = () => {
  return (
    <StyledSlogan id="slogan">
      <div className="container">
        <MotionVisible className="header-lg" variants={animationVariant}>
          <TitleEffect text="UNLIMITED TO" fontSize="72px" />
          <img className="text-center img-fluid" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible className="header-sm" variants={animationVariant}>
          <TitleEffect text="UNLIMITED TO" fontSize="40px" />
          <img className="text-center img-fluid" src={lineArrowSm} alt="line" />
        </MotionVisible>
        <MotionVisible variants={animationVariant}>
          <Row gutter={[0, 0]} className="content-wrapper" justify="space-around">
            <Col xs={24} md={24} lg={8} className="content-item">
              <div className="text-wrapper flex-center">
                <TitleEffect text="PLAY" fontSize="80px" />
                <div className="label">AT ANYWHERE</div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} className="content-item">
              <div className="text-wrapper flex-center">
                <TitleEffect text="EARN" fontSize="80px" />
                <div className="label">EVERY WIN</div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={8} className="content-item">
              <div className="text-wrapper flex-center">
                <TitleEffect text="TRADE" fontSize="80px" />
                <div className="label">AT ANYTIME</div>
              </div>
            </Col>
          </Row>
        </MotionVisible>
      </div>
    </StyledSlogan>
  );
};

export default Slogan;
