import React from 'react';
import lineArrowSm from 'src/assets/images/onepaper/lineArrowSm.svg';
import tokenomicsMb from 'src/assets/images/onepaper/tokenomicMb3.png';
import MotionVisible from 'src/components/MotionVisible';
import TitleEffect from 'src/components/TitleEffect';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledTokenomicMobile } from './styled';

const TokenomicsMobile = () => {
  return (
    <StyledTokenomicMobile id="tokenomics">
      <div className="container">
        <MotionVisible className="header" variants={animationVariant}>
          <TitleEffect className="t-1" text="TOKENOMICS" fontSize="48px" />
          <img className="text-center" src={lineArrowSm} alt="line" />
        </MotionVisible>
        <MotionVisible variants={animationVariant} className="content-box">
          <img className="img-fluid text-center" src={tokenomicsMb} />
        </MotionVisible>
      </div>
    </StyledTokenomicMobile>
  );
};

export default TokenomicsMobile;
