import styled from 'styled-components';
import teamBg from 'src/assets/images/teamBg3.png';

export const StyledTeamWrapper = styled.section`
  background-image: url(${teamBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

export const StyledTeam = styled.section`
  padding: 80px 0;
  margin-top: -1px;
  position: relative;
  z-index: 3;

  .layer {
    position: absolute;
    top: -50%;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    width: 100%;
    img {
      width: 100%;
    }
  }
  .container {
    max-width: 1223px;
    margin: auto;
    .teamTitle {
      text-align: center;
      padding-bottom: 16px;
    }
    .description {
      max-width: 672px;
      margin: auto;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #e9e9ec;
    }

    .advisors {
      /* padding-top: 100px; */
      position: relative;
      .cardWrapper {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .teamCard {
          width: 25%;
        }
      }
    }

    .teamSlider {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 170px;
      row-gap: 40px;
      @media (max-width: 1024px) {
        column-gap: 120px;
        row-gap: 20px;
      }
      .slick-slider {
        .slick-dots {
          bottom: -60px;
        }
      }
    }

    .teamCard {
      /* padding: 38px; */
      width: 170px;
      @media (max-width: 1024px) {
        width: 140px;
      }
      .cardImage {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 176px;
          @media (max-width: 1024px) {
            width: 156px;
          }
        }
      }
    }
    .teamInfo {
      padding-top: 14px;
      .position {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #fea090;
        padding-bottom: 8px;
      }
      .name {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #e9e9ec;
      }
      .jobDesc {
        max-width: 244px;
        margin: 0 auto;
        padding-top: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #8c8c8c;
      }
      .link {
        padding-top: 16px;
        a {
          &:not(:last-child) {
            margin-right: 24px;
          }
        }
      }
    }
    :global(.slick-slide) {
      > div {
        display: flex;
        align-items: flex-start;
      }
    }
    @media (max-width: 1200px) {
      .advisors {
        .cardWrapper {
          justify-content: center;
          .teamCard {
            width: 300px;
          }
        }
      }
      .teamInfo {
        .name {
          font-size: 14px;
        }
        .position {
          font-size: 11.5px;
        }
        .jobDesc {
          font-size: 12px;
        }
      }
    }
  }
`;
