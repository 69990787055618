import React from 'react';
import TitleEffect from 'src/components/TitleEffect';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import lynnHTP from 'src/assets/images/howtoplay/lynnHTP.png';
import btnDownload from 'src/assets/images/howtoplay/btnDownload.png';
import { scrollTo } from 'src/utils';
import { StyledIntroHTPDesktop } from './styled';

const IntroHTPDesktop = ({ fireEvent }) => {
  const navigateScroll = (toId) => scrollTo({ id: toId });

  return (
    <StyledIntroHTPDesktop>
      <div className="container flex-center">
        <div className="box-center">
          <div className="box-img text-center">
            <img className="img-fluid" src={lynnHTP} alt="main-img" />
          </div>
          <div className="title-wrapper">
            <TitleEffect text="BURN TO EARN" className="main-title" fontSize={'80px'}>
            </TitleEffect>
            <div
              text="Shaping Your Body With AR Technology"
              className="sub-title"
              fontSize={'50px'}
            >
              Shaping Your Body With AR Technology
            </div>
          </div>
          <div className="download-box flex-center">
            <div className="btn-download">
              <a
                href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <div
            className="scroll-down-wrapper"
            onClick={() => navigateScroll('gameplay-htp')}
          >
            <div className="chevron" />
            <div className="chevron" />
            <div className="chevron" />
          </div>
        </div>
      </div>
    </StyledIntroHTPDesktop>
  );
};

export default IntroHTPDesktop;
