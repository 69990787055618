import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledFilterButtonTabs, StyledFilterButton } from './styled';

const FilterButtonGameplay = (props) => {
  const { options, selected, onSelect, ...other } = props;

  return (
    <StyledFilterButtonTabs {...other}>
      {options?.map((option) => (
        <StyledFilterButton
          isSelected={selected === option.value}
          onClick={() => onSelect(option.value)}
          key={option.value}
        >
          {option.label}
        </StyledFilterButton>
      ))}
    </StyledFilterButtonTabs>
  );
};

FilterButtonGameplay.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  onSelect: PropTypes.func,
};

export default FilterButtonGameplay;
