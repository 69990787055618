import styled from 'styled-components';
import introEventGaBgMb from 'src/assets/images/introduction/mobile/introEventGaBgMb.png';

export const StyledIntroEventGAMobile = styled.div`
  margin: 0 auto;
  overflow: visible;
  width: 100%;
  /* background-image: url(${introEventGaBgMb}); */
  background-position: center;
  background-size: 100% 100%;
  .container {
    padding: 40px 16px 70px 16px;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    .box-center {
      position: relative;
      text-align: center;
      width: 100%;
      .token-corner {
        position: absolute;
        right: 20px;
        bottom: 200px;
        width: 84px;
      }

      .main-title {
        margin-bottom: 12px;
      }
      .sub-title {
        max-width: 272px;
        margin-bottom: 24px;
      }
      .box-img {
        margin-bottom: 18px;
        text-align: center;
        img {
          max-width: 300px;
        }
      }
      .desc {
        font-size: 24px;
        line-height: 106.6%;
        text-align: center;
        letter-spacing: 0.04em;
        color: #ffffff;
        text-shadow: 1px 1px 0px #ff2e00;
      }
      .time {
        max-width: 220px;
        margin: 0 auto;
        border: 2px solid #fff1f8;
        filter: drop-shadow(1.81524px 0px 2.42032px #ff459e);
        border-radius: 9px;
        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 29px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
    .download-box {
      flex-direction: column;
      margin: 24px 0;
      .btn-download {
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }
`;
