import styled from 'styled-components';

export const StyledTokenmetricsDesktop = styled.section`
  padding: 64px 0 206px 0;
  position: relative;
  .container {
    max-width: 1216px;
    margin: auto;
    .header {
      text-align: center;
    }
    .token-desc {
      .desc {
        margin-top: 70px;
        margin-bottom: 16px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
      .contract-box {
        padding: 18px 40px 18px 0;
        margin: 0 auto;
        max-width: 800px;
        text-align: left;
        .label {
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: #ffffff;
          position: relative;
          z-index: 1;
          .address {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            padding-left: 24px;
          }
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-10deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-10deg);
        }
        .icon-copy {
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
      }
    }

    .img-box {
      margin-top: 48px;
    }
  }
`;
