import styled from 'styled-components';
import gameplayBg from 'src/assets/images/gameplayBg.png';

export const StyledGameplayDesktop = styled.section`
  height: 900px;
  position: relative;
  background-image: url(${gameplayBg});
  background-size: cover;
  background-repeat: round;
  .container {
    max-width: 1246px;
    margin: auto;
    position: relative;
    .title {
      margin-bottom: 18px;
    }
    .row-content {
      padding-top: 64px;
      height: 813px;
      .col-img {
        height: 100%;
      }
      .col-content {
        padding-right: 64px !important;
      }
      .slider-content {
        padding-top: 24px;
        height: 350px;
      }
      .content-box {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-10deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-10deg);
        }
      }
      .content-title {
        font-weight: 700;
        font-size: 30px;
        line-height: 70px;
        color: #ffffff;
        position: relative;
        z-index: 1;
        @media (max-width: 1024px) {
          font-size: 24px;
        }
      }
      .content-img {
        height: 100%;
        width: 638px;
        position: absolute;
        top: 10px;
      }
      .content-desc {
        padding-right: 16px;
        padding-bottom: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.0125em;
        color: #e9e9ec;
        position: relative;
        z-index: 1;
        @media (max-width: 1024px) {
          font-size: 14px;
          min-height: 200px;
        }
      }
    }
    .slick-slider {
      padding-top: 24px;
      .slick-dots {
        text-align: left;
        bottom: -100px;
      }
    }
  }
`;
