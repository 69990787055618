import React from 'react';
import lynnGameplay from 'src/assets/images/introduction/mobile/lynnGameplay.png';
import caloIndoorTitle from 'src/assets/images/introduction/desktop/caloIndoorTitle3.png';
import releaseTitle from 'src/assets/images/introduction/desktop/releaseTitle2.png';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import ifitToken from 'src/assets/images/ifitToken.png';
import { StyledIntroEventGAMobile } from './styled';
import Button from 'src/components/Button';

const IntroEventGAMobile = ({ fireEvent }) => {
  return (
    <StyledIntroEventGAMobile>
      <div className="container">
        <div className="box-center">
          <img className="img-fluid token-corner" src={ifitToken} alt="token" />
          <img
            className="img-fluid sub-title"
            src={caloIndoorTitle}
            alt="indoor-app"
          />
          <img
            className="img-fluid main-title"
            src={releaseTitle}
            alt="title-app"
          />
          <div className="box-img">
            <img className="img-fluid main" src={lynnGameplay} alt="img" />
          </div>
        </div>
        <div className="download-box flex-center">
          <div className="btn-download" onClick={() => fireEvent('ios')}>
            <a
              href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
              target="_blank"
              rel="noreferrer"
            >
              <img src={btnIos} alt="download" />
            </a>
          </div>
          <div className="btn-download" onClick={() => fireEvent('android')}>
            <a
              href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
              target="_blank"
              rel="noreferrer"
            >
              <img src={btnAndroid} alt="download" />
            </a>
          </div>
        </div>
        <div className="flex-center">
          <a
            href="https://t.me/caloindoorglobal"
            target="_blank"
            rel="noreferrer"
          >
            <Button>JOIN NOW!</Button>
          </a>
        </div>
      </div>
    </StyledIntroEventGAMobile>
  );
};

export default IntroEventGAMobile;
