import styled from 'styled-components';
import appScreenBgMb from 'src/assets/images/appScreenBgMb2.png';

export const StyledAppScreenMobile = styled.div`
  padding-bottom: 100px;
  overflow: hidden;
  background-image: url(${appScreenBgMb});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .desc {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #ffffff;
    padding-top: 16px;
  }
  .container {
    padding: 0 10px;
    .img-wrapper {
      text-align: center;
      position: relative;
      margin-top: 64px;
      .img-app {
        position: relative;
        z-index: 2;
        max-width: 316px;
        @media (min-width: 767px) {
          max-width: 428px;
        }
      }
    }
    .box-content {
      text-align: center;
      margin-top: 64px;
      .title {
        /* line-height: 54px; */
      }
    }
  }
`;
