import styled from 'styled-components';
import gameplayBgMb from 'src/assets/images/gameplayBgMb.png';

export const StyledGameplayMobile = styled.section`
  position: relative;
  padding: 40px 0;
  background-image: url(${gameplayBgMb});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .container {
    padding: 0 16px;
    max-width: 500px;
    margin: 0 auto;
    .title {
      line-height: 38px;
      text-align: center;
      color: #e9e9ec;
    }
    .row-content {
      padding: 32px 0 50px 0;
      .col-img {
        text-align: center;
        min-height: 400px;
      }
      .content-img {
        position: relative;
        top: 10px;
        transform: translate(-50%, -50%);
        padding-bottom: 24px;
      }
      .content-box {
        position: relative;
        padding: 10px;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-5deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-5deg);
        }
      }
      .content-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        padding-bottom: 24px;
      }
      .content-desc {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.0125em;
        color: #ffffff;
        z-index: 1;
        min-height: 200px;
      }
    }
    ul {
      margin-top: 24px;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 45%;
        margin-bottom: 16px;
      }
    }
  }
`;
