import React, { useState } from 'react';
import classNames from 'classnames';
import GameCard from './GameCard';
import { GameTab, StyledGameplayDesktop } from './styled';
import MotionVisible from 'src/components/MotionVisible';
import {
  animationVariant,
  boxItemRightVariant,
} from 'src/variables/animationVariant';

const games = [
  {
    label: 'REFLEX',
    value: 'reflex',
  },
  {
    label: 'FIT THE SHAPE',
    value: 'fitShape',
  },
  {
    label: 'JUMPING BATTLE',
    value: 'jumping',
  },
];

const GaneplayDesktop = () => {
  const [selected, setSelected] = useState('reflex');

  return (
    <StyledGameplayDesktop id="gameplay-htp">
      <div className="container">
        <MotionVisible className="title" variants={animationVariant}>
          Game Play
        </MotionVisible>
        <MotionVisible
          className="title"
          variants={boxItemRightVariant}
          key={selected}
        >
          <GameCard game={selected} />
        </MotionVisible>
        <MotionVisible
          className="game-tabs flex-between"
          variants={animationVariant}
        >
          {games.map((game, key) => (
            <GameTab
              className={classNames({
                active: selected === game.value,
              })}
              onClick={() => setSelected(game.value)}
              key={key}
            >
              <div className="name flex-center">{game.label}</div>
            </GameTab>
          ))}
        </MotionVisible>
      </div>
    </StyledGameplayDesktop>
  );
};

export default GaneplayDesktop;
