import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import {
  animationVariant,
  boxItemLeftVariant,
  boxItemRightVariant,
  nonAnimatingContainer,
} from 'src/variables/animationVariant';
import caloTokenStr from 'src/assets/images/caloTokenStr.png';
import iFitTokenStr from 'src/assets/images/iFitTokenStr.png';
import arrowToken from 'src/assets/images/token/arrowToken.svg';
import tokenBgMb from 'src/assets/images/token/tokenBgMb.png';
import { StyledTokenMobile } from './styled';
import TitleEffect from 'src/components/TitleEffect';

const TokenMobile = () => {
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });
  const controls = useAnimation();

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledTokenMobile ref={inViewRef}>
      <AnimatePresence>
        <motion.div
          className="container"
          initial="hidden"
          animate={controls}
          variants={nonAnimatingContainer}
        >
          <div className="box-top">
            <motion.div className="title" >
              <TitleEffect text="Tokenomic" variants={animationVariant} />
            </motion.div>
            <div className="indoor-logo">
              <img className="img-fluid" src={tokenBgMb} />
            </div>
            <motion.div className="description" variants={animationVariant}>
              CALO Token and IFIT Token are both blockchain based currencies and
              belong to the Calo Ecosystem.
            </motion.div>
          </div>
          <motion.div className="arrow text-center" variants={animationVariant}>
            <img src={arrowToken} />
          </motion.div>
          <div className="box-token">
            <div className="box-left box-item">
              <motion.div
                animate={{
                  y: ['10px', '0px', '10px'],
                }}
                variants={boxItemLeftVariant}
                transition={{ repeat: Infinity, duration: 2 }}
                className="coinLogo"
              >
                <img src={iFitTokenStr} />
              </motion.div>
              <div className="name">IFIT TOKEN</div>
              <div className="desc">UNLIMITED</div>
            </div>
            <div className="box-right box-item">
              <motion.div
                animate={{
                  y: ['10px', '0px', '10px'],
                }}
                variants={boxItemRightVariant}
                transition={{ repeat: Infinity, duration: 2 }}
                className="coinLogo"
              >
                <img src={caloTokenStr} />
              </motion.div>
              <div className="name">CALO TOKEN</div>
              <div className="desc">LIMITED</div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </StyledTokenMobile>
  );
};

export default TokenMobile;
