/* eslint-disable indent */
import styled from 'styled-components';
import { Modal } from 'antd';
import gameInfoBg from 'src/assets/images/howtoplay/gameInfoBg.png';
import gameInfoBgMb from 'src/assets/images/howtoplay/gameInfoBgMb.png';

const StyledModalGameInfo = styled(Modal)`
  width: 800px !important;
  height: 423px;
  background-image: url(${gameInfoBg});
  background-size: 100% 100%;
  .ant-modal-content {
    background: transparent;
    height: 100%;
    .ant-modal-close-x {
      width: 65px;
      cursor: pointer;
    }
    .ant-modal-close {
      img {
        width: 100%;
      }
    }
  }
  .ant-modal-body {
    height: 100%;
  }
  .detail-content {
    height: 100%;
    padding: 0 24px;
    .description {
      padding-top: 24px;
      font-family: 'Boxed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
    }
  }
  @media only screen and (max-width: 578px) {
    background-image: url(${gameInfoBgMb});
    width: 312px !important;
    height: 684px;
    .detail-content {
      padding: 0;
      .description {
        padding-top: 24px;
        font-size: 14px;
      }
    }
  }
`;

export { StyledModalGameInfo };
