import lynnReflex from 'src/assets/images/howtoplay/gameplay/lynnReflex.png';
import lynnFit from 'src/assets/images/howtoplay/gameplay/lynnFit.png';
import lynnJumping from 'src/assets/images/howtoplay/gameplay/lynnJumping.png';

export const gameInfo = {
  reflex: {
    name: 'REFLEX GAME',
    desc: 'You need to own at least a Slipper NFT because this game is just available for the owner of these shoes. You need to use your hand to reach the circles which can be seen on your phone screen.',
    img: lynnReflex,
  },
  fitShape: {
    name: 'FIT THE SHAPE',
    desc: 'You need to own the Pumper Sneaker to be allowed to participate. There are some squares and rectangles that appear randomly on the screen. You need to use your whole body to make sure you are able to move and to make yourself fit accurately in those shapes.',
    img: lynnFit,
  },
  jumping: {
    name: 'JUMPING BATTLE',
    desc: 'You need to own the Clever Sneaker as a condition to activate the game. The mission of the players is to jump as much as the squares show off on their phone screen within 60 seconds. Points will be counted continuously throughout the jump and stop counting after the time has ended.',
    img: lynnJumping,
  },
};
