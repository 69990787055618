import React from 'react';
import prevArrow from 'src/assets/images/prevArrow.svg';
import nextArrow from 'src/assets/images/nextArrow.svg';
import { StyledSliderArrow, StyledSliderSlick } from './styled';

const SliderSlick = (props) => {
  const {
    children,
    showArrow,
    topArrow,
    paddingX,
    paddingY,
    settings,
    ...other
  } = props;

  const defaultDots = {
    appendDots: (dots) => (
      <div
        className="link"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="link"
        style={{
          width: '100%',
          height: '3px',
          marginRight: '12px',
          backgroundColor: '#D2D3D9',
          borderRadius: '4px',
        }}
      />
    ),
  };

  const settingsDefault = {
    dots: true,
    infinite: false,
    arrows: showArrow,
    centerPadding: '0',
    speed: 300,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    ...defaultDots,
    prevArrow: (
      <StyledSliderArrow data-top={topArrow}>
        <img src={prevArrow} />
      </StyledSliderArrow>
    ),
    nextArrow: (
      <StyledSliderArrow data-top={topArrow}>
        <img src={nextArrow} />
      </StyledSliderArrow>
    ),
  };

  const config = settings ? { ...settings, ...defaultDots } : settingsDefault;

  return (
    <StyledSliderSlick
      data-slide-paddingX={paddingX}
      data-slide-paddingY={paddingY}
      {...other}
      {...config}
    >
      {children}
    </StyledSliderSlick>
  );
};

export default SliderSlick;
