import React from 'react';
import { Col, Row } from 'antd';
import Button from 'src/components/Button';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import lynnGameplay from 'src/assets/images/introduction/desktop/lynnGameplay3.png';
import caloIndoorTitle from 'src/assets/images/introduction/desktop/caloIndoorTitle3.png';
import releaseTitle from 'src/assets/images/introduction/desktop/releaseTitle2.png';
import caloTokenIntro from 'src/assets/images/introduction/desktop/caloTokenIntro.png';
import ifitToken from 'src/assets/images/ifitToken.png';
import { scrollTo } from 'src/utils';
import { StyledIntroEventGADesktop } from './styled';

const IntroEventGADesktop = ({ fireEvent }) => {
  const navigateScroll = (toId) => scrollTo({ id: toId });

  return (
    <StyledIntroEventGADesktop>
      <Row gutter={0} className="container" align="middle">
        <Col md={12} xl={12} className="col-img">
          <div className="box-img">
            <img className="img-fluid" src={lynnGameplay} alt="model" />
          </div>
        </Col>
        <Col md={12} xl={12} className="col-content">
          <img
            className="img-fluid sub-title"
            src={caloIndoorTitle}
            alt="indoor-app"
          />
          <img
            className="img-fluid main-title"
            src={releaseTitle}
            alt="title-app"
          />

          <div className="download-box flex-center">
            <div className="btn-download" onClick={() => fireEvent('ios')}>
              <a
                href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download" onClick={() => fireEvent('android')}>
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
          <div className="flex-center btn-bottom">
            <a
              href="https://t.me/caloindoorglobal"
              target="_blank"
              rel="noreferrer"
            >
              <Button>JOIN US!</Button>
            </a>
          </div>
        </Col>
        <div
          className="scroll-down-wrapper"
          onClick={() => navigateScroll('topics')}
        >
          <div className="chevron" />
          <div className="chevron" />
          <div className="chevron" />
        </div>
        <img className="img-fluid ifit-token" src={ifitToken} alt="token" />
        <img
          className="img-fluid token-corner"
          src={caloTokenIntro}
          alt="token"
        />
      </Row>
    </StyledIntroEventGADesktop>
  );
};

export default IntroEventGADesktop;
