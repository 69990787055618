import styled from 'styled-components';
import sneakerNFTBg from 'src/assets/images/howtoplay/sneakerNFTBg4.png';
import sneakerTab from 'src/assets/images/howtoplay/sneakerTab.png';
import sneakerTabActive from 'src/assets/images/howtoplay/sneakerTabActive.png';
import bubbleBg from 'src/assets/images/howtoplay/bubbleBg.png';
import bubbleArrow from 'src/assets/images/howtoplay/bubbleArrow.png';

export const StyledNFTDetailDesktop = styled.div`
  min-height: 900px;
  padding: 80px 0;
  background-image: url(${sneakerNFTBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .container {
    max-width: 1076px;
    margin: auto;
    padding: 0;
    .title {
      font-family: 'Protector';
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 92px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
    }
    .description {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-align: center;
    }
    .content-wrapper {
      padding-top: 100px;
      position: relative;
      .col-tabs {
        position: absolute;
        left: 0;
        top: 100px;
        z-index: 99;
        .item-tabs {
          position: relative;
          height: 100%;
          flex-direction: column;
          gap: 24px;
        }
      }
      .nft-model {
        z-index: 5;
        overflow: visible;
        position: relative;
        top: 50px;
        min-height: 580px;
        canvas {
          height: 650px;
        }
        .img-model {
          width: 570px;
          margin: 0 auto;
        }
      }
    }
  }
`;

export const BubbleInfo = styled.div`
  width: 310px;
  height: 100px;
  background-image: url(${bubbleBg});
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: -50px;
  .wrapper {
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-transform: uppercase;
  }
  .desc {
    font-family: 'Boxed';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 12px;
    color: #ffffff;
  }
  &::before {
    content: '';
    width: 104px;
    height: 123.5px;
    position: absolute;
    top: 90px;
    left: 60px;
    background-image: url(${bubbleArrow});
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

export const SneakerTab = styled.div`
  width: 107px;
  height: 107px;
  background-image: url(${sneakerTab});
  background-size: 100% 100%;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  position: relative;
  left: 0;
  &:hover {
    background-image: url(${sneakerTabActive});
    /* left: 24px; */
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  }
  &.active {
    position: relative;
    left: 24px;
    background-image: url(${sneakerTabActive});
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  }
  .wrapper {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.unknown {
      img {
        width: 85px;
      }
    }
  }
  .name {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    color: #ffffff;
    height: 100%;
    margin-top: -16px;
  }
`;
