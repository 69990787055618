import lynnGame from 'src/assets/images/lynnGame.png';
import lynnGame1 from 'src/assets/images/lynnGame1.png';
import lynnGame2 from 'src/assets/images/lynnGame2.png';
import lynnGame3 from 'src/assets/images/lynnGame3.png';

const gameplayContents = [
  {
    img: lynnGame,
    url: 'https://docs.calo.run/app/gameplay-system/game-play#single-mode',
    storyTitle: 'Overview Story',
    storyDesc:
      'The story tells about a group of 3 friends stuck in a strange world created by a monster. The mission for all of them is to help each other and find their way back to the world that belongs to them. Since then, they have also realized the valuable health implications through this journey',
  },
  {
    img: lynnGame1,
    url: 'https://docs.calo.run/app/gameplay-system/game-play#single-mode',
    storyTitle: 'Reflex Game',
    storyDesc:
      '<p>In the beginning, Lory gets lost in a strange world and she is stuck in a loop. The only way to temporarily disable the loop is to find the hands of the clock to rotate it anti-clockwise before midnight. The player plays the role of Lory to find them by reaching the items that appear on the screen in order within a specified period of time.</p>',
  },
  {
    img: lynnGame2,
    url: 'https://docs.calo.run/app/gameplay-system/game-play#team-challenge-mode',
    storyTitle: 'Fit the shape',
    storyDesc:
      '<p>On the other hand, players as Lynne who is also transported into the same world as Lory and she has another challenge to survive. In this challenge, Lynne plays her role in a script belonging to the monster which creates this world. Lynne has to treat Lory as a stranger and lead her to the room of clocks. During this process, Lynne has to stay out of Lory&#39;s sight or the loop will be activated.</p>',
  },
  {
    img: lynnGame3,
    url: 'https://docs.calo.run/app/gameplay-system/shoe-minting',
    storyTitle: 'Jumping battle',
    storyDesc:
      '<p>Besides, Carl is the one who plays the same role as Lynne but unfortunately gets lost in a maze. However, he discovers that this is the monster&#39;s database, which contains countless scenarios and Carl&#39;s challenge is to find the right one and rewrite it. Moreover, Carl has to pay a hefty price for opening the wrong script by being hunted by the monster&#39;s entourage.</p>',
  },
];

export { gameplayContents };
