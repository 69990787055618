import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import caloIndoorLogo from 'src/assets/images/caloIndoorWhiteMb.svg';
import iconDownload from 'src/assets/images/iconDownload.svg';
import coinGeckoLogo from 'src/assets/images/coinGeckoLogoWhite.png';
import cmcLogo from 'src/assets/images/cmcLogo.png';
import { scrollTo } from 'src/utils';
import { StyledFooter, StyledInputEmail } from './styled';

const FooterMobile = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  const navigateScroll = (toId) => scrollTo({ id: toId });

  const onNavigatePage = (id) => {
    if (isHomePage) {
      navigateScroll(id);
    } else {
      history.push(`/#${id}`);
    }
  };

  return (
    <StyledFooter>
      <div className="container">
        <div className="caloInfo">
          <div className="logoBox">
            <img src={caloIndoorLogo} />
          </div>
          <div className="companyInfo">
            <div>Metaverse Application Pte, Ltd.</div>
            <div>UEN: 202142943M</div>
            <div>1B Trengganu Street Singapore (058455)</div>
            <div>
              <a href="mailto:hi@calo.run" target="_blank" rel="noreferrer">
                hi@calo.run
              </a>
            </div>
          </div>
        </div>
        <div className="containerBottom">
          <div className="menuGroupBox">
            <ul className="footerMenu">
              <li
                className="footerLink"
                onClick={() => onNavigatePage('gameplay')}
              >
                Gameplay
              </li>
              <li
                className="footerLink"
                onClick={() => onNavigatePage('nft-system')}
              >
                NFT Systems
              </li>
              <li
                className="footerLink"
                onClick={() => onNavigatePage('roadmap')}
              >
                Roadmap
              </li>
            </ul>
            <ul className="footerMenu">
              <li className="footerLink" onClick={() => onNavigatePage('team')}>
                Team
              </li>
              <li className="footerLink">
                <Link to="/privacy-policy" className="footerLink">
                  Privacy Policy
                </Link>
              </li>
              <li className="footerLink">
                <Link to="/terms-and-conditions" className="footerLink">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="coin-gecko text-center">
            <a href="https://dl.indoor.fun/calo-indoor-media-kit.zip" download>
              <div className="btn-download flex-center">
                <img className="icon" src={iconDownload} alt="icon" />
                <span className="label">DOWNLOAD MEDIA KIT</span>
              </div>
            </a>
            <p className="text">Data provided by</p>
            <a
              href="https://www.coingecko.com/en/coins/calo"
              target="_blank"
              rel="noreferrer"
            >
              <img src={coinGeckoLogo} width={150}/>
            </a>
            <a
              href="https://coinmarketcap.com/currencies/calo-app/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={cmcLogo} width={150}/>
            </a>
          </div>
        </div>
        <div className="copyright">© 2021 Calo Co,.</div>
      </div>
    </StyledFooter>
  );
};

export default FooterMobile;
