import styled from 'styled-components';
import startGameBgMb from 'src/assets/images/onepaper/startGameBgMb.png';
import startGameBg from 'src/assets/images/onepaper/startGameBg.png';

export const StyledStartGame = styled.section`
  background-image: url(${startGameBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 767px) {
    background-image: url(${startGameBgMb});
  }
  .container {
    max-width: 1216px;
    margin: 0 auto;
    padding-top: 64px;
    width: 100%;
    @media (min-width: 767px) {
      .header-lg {
        text-align: center;
        display: block;
      }
      .header-sm {
        text-align: center;
        display: none;
      }
    }
    @media (max-width: 767px) {
      .header-lg {
        text-align: center;
        display: none;
      }
      .header-sm {
        text-align: center;
        display: block;
        img {
          margin-top: 16px;
        }
      }
    }
    .content-wrapper {
      padding-top: 64px;
      padding-bottom: 230px;
      @media (max-width: 768px) {
        padding-top: 48px;
        padding-bottom: 80px;
      }
      .content-item {
        max-width: 372px;
        @media (max-width: 1024px) {
          max-width: 252px;
        }
        @media (max-width: 768px) {
          max-width: 385px;
          padding: 16px 64px;
        }
        @media (max-width: 568px) {
          padding: 16px 24px;
        }
        @media (max-width: 375px) {
          padding: 16px;
        }
      }
    }
  }
`;
