import styled from 'styled-components';
import spaceLayer from 'src/assets/images/spaceLayer2.png';
import downloadBgMb from 'src/assets/images/downloadBgMb.png';

export const StyledDownloadPromoteMobile = styled.section`
  .layer {
    position: absolute;
    bottom: 0;
    height: 263px;
    width: 100%;
    background-image: url(${downloadBgMb});
    background-size: cover;
    background-repeat: round;
    mix-blend-mode: color-dodge;
  }
  .container {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    .box-top {
      .red {
        position: absolute;
        top: -50px;
        left: 0;
        width: 170px;
        z-index: 1;
      }
      .title {
        position: relative;
        z-index: 2;
        margin-top: 60px;
        padding: 0 24px;
        font-family: 'Airstrike';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 28px;
        color: #e9e9ec;
        text-shadow: 1px -1px 10px #ff3f2a;
        p {
          margin: 0;
        }
      }
    }
    .box-bottom {
      padding-bottom: 80px;
      .download-box {
        flex-direction: column;
        .btn-download {
          &:last-child {
            margin-top: 16px;
          }
        }
      }
      .layer {
        position: absolute;
        bottom: 0;
        height: 139px;
        width: 100%;
        background-image: url(${spaceLayer});
        background-size: cover;
        background-repeat: round;
        mix-blend-mode: color-dodge;
      }
    }
  }
`;
