import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import NFTSystemDesktop from './NFTSystemDesktop';
import NFTSystemMobile from './NFTSystemMobile';

const NFTSystem = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<NFTSystemDesktop {...props} />}
      mobileView={<NFTSystemMobile {...props} />}
    />
  );
};

export default NFTSystem;
