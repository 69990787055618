import styled from 'styled-components';

export const StyledIntroHTPDesktop = styled.div`
  height: 100%;
  .container {
    max-width: 1190px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: 2000px) {
      justify-content: center;
    }
    .box-img {
      margin-top: 30px;
      img {
        max-width: 860px;
        @media (max-width: 1024px) {
          /* max-width: 800px; */
        }
      }
    }
    .box-center {
      text-align: center;
      .title-wrapper {
        margin-top: -130px;
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        .main-title {
          font-size: 110px;
          line-height: 60px;
          letter-spacing: 0.0125em;
          color: #ffffff;
          /* text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5); */
        }
        .sub-title {
          font-size: 56px;
          line-height: 64px;
          letter-spacing: 0.0125em;
          color: #ffffff;
          text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        }
        @media (max-width: 1024px) {
          .main-title {
            font-size: 80px;
            line-height: 40px;
          }
          .sub-title {
            font-size: 48px;
          }
        }
      }

      .btn-download {
        margin-top: 12px;
        img {
          width: 150px;
        }
      }
      .download-box {
        /* margin-top: 12px; */
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }
  .scroll-down-wrapper {
    margin-top: 40px;
  }
`;
