import styled from 'styled-components';
import nftDetailBgMb from 'src/assets/images/howtoplay/nftDetailBgMb.png';
import nftInfoCard from 'src/assets/images/howtoplay/nftInfoCard.png';

export const StyledNFTDetailMobile = styled.div`
  padding: 80px 0;
  background-image: url(${nftDetailBgMb});
  background-size: cover;
  background-repeat: round;
  .container {
    .title {
      font-family: 'Protector';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 46px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
    }
    .nft-wrapper {
      margin: 60px auto;
      position: relative;
      width: 375px;
      height: 418px;
      @media (max-width: 320px) {
        width: 320px;
      }
      .main-img {
        position: relative;
        img {
          max-width: 200px;
          margin: 0 auto;
          z-index: 2;
        }
        .blur {
          z-index: 1;
          position: absolute;
          width: 250px;
          height: 250px;
          border-radius: 50%;
          background: #fd474c;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          filter: blur(100px);
        }
      }
      .index-pointer {
        position: absolute;
        z-index: 3;
        .img-pointer {
          width: 50px;
        }
        &.pointer-left {
          left: 36px;
          @media (max-width: 320px) {
            left: 10px;
          }
        }
        &.pointer-right {
          right: 40px;
          @media (max-width: 320px) {
            right: 10px;
          }
          .number {
            margin-left: -3px;
          }
        }
        &.index-1 {
          top: 65px;
        }
        &.index-3 {
          top: 140px;
        }
        &.index-5 {
          top: 176px;
        }
        &.index-6 {
          top: 264px;
        }
        &.index-8 {
          top: 302px;
        }
        &.index-2 {
          top: 65px;
        }
        &.index-4 {
          top: 140px;
        }

        &.index-7 {
          top: 285px;
        }
        &.index-9 {
          top: 325px;
        }
      }
    }
  }
  .info-card {
    width: 295px;
    height: 96px;
    margin: 0 auto;
    padding: 16px 24px;
    background-image: url(${nftInfoCard});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    .title {
      font-family: 'Boxed';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #f5f5f5;
      text-shadow: initial;
    }
    .desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #ffffff;
    }
  }
  .card-index {
    margin-top: 16px;
    span {
      font-style: italic;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
    .index {
      padding: 0 16px;
    }
  }
`;

export const IndexNumber = styled.span`
  margin-right: -3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  background: linear-gradient(180deg, #ffbaa1 0%, #fd3f46 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: all 0.35 s;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 1px;
    background: linear-gradient(180deg, #ffbaa1 0%, #fd3f46 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
  }
  &.active {
    letter-spacing: 0.497479px;
    color: #ffffff;

    background: linear-gradient(180deg, #ffbaa1 0%, #fd3f46 100%);
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background-clip: unset;
    text-fill-color: unset;
  }
`;
