import styled from 'styled-components';
import tokenBg from 'src/assets/images/token/tokenBg.png';
import cardTokenBg from 'src/assets/images/token/cardTokenBg.png';

export const StyledTokenDesktop = styled.section`
  padding: 106px 0 140px 0;
  position: relative;
  z-index: 3;
  background-image: url(${tokenBg});
  background-size: cover;
  background-repeat: round;
  .container {
    max-width: 1216px;
    margin: auto;
    .title {
      text-align: center;
      padding-bottom: 24px;
    }

    .contentBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 100px;
      .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #f45c0e;
        margin-bottom: 8px;
        text-align: center;
      }
      .desc {
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0.0125em;
        color: #e9e9ec;
        text-align: center;
        padding-top: 8px;
      }
      .boxItem {
        background: url(${cardTokenBg});
        background-size: 100% 100%;
        box-shadow: inset 0px 8px 17px rgba(255, 255, 255, 0.25);
        border-radius: 20px;
        padding: 40px 24px;
        border: 3px solid #3263d6;
        filter: drop-shadow(4px 4px 16px rgba(215, 55, 164, 0.4));
        border-radius: 4px;
      }
      .boxLeft {
        .coinLogo {
          width: 174px;
          margin-bottom: 24px;
          @media (max-width: 1024px) {
            width: 160px;
          }
          img {
            width: 100%;
          }
        }
      }
      .boxMiddle {
        position: relative;
        flex-direction: column;
        .description {
          position: absolute;
          bottom: 120px;
          max-width: 398px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.0125em;
          color: #e9e9ec;
        }
      }
      .boxRight {
        .coinLogo {
          width: 174px;
          margin-bottom: 24px;
          @media (max-width: 1024px) {
            width: 160px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
`;
