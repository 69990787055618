import React, { useState } from 'react';
import TitleEffect from 'src/components/TitleEffect';
import { caloAddress } from 'src/variables/calo';
import { animationVariant } from 'src/variables/animationVariant';
import MotionVisible from 'src/components/MotionVisible';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import tokenmetric from 'src/assets/images/onepaper/tokenmetric.png';
import iconCopy from 'src/assets/images/copyOutlined.svg';
import { StyledTokenmetricsDesktop } from './styled';
import { Tooltip } from 'antd';

const TokenMetricsDesktop = () => {
  const [copied, setCopied] = useState(false);

  const copyAddress = (address) => {
    setCopied(true);
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address);
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('input');
      ele.value = address;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
    }
  };

  return (
    <StyledTokenmetricsDesktop>
      <div className="container">
        <MotionVisible className="header" variants={animationVariant}>
          <TitleEffect className="t-2" text="TOKEN METRIC" fontSize="70px" />
          <img className="text-center" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible className="token-desc" variants={animationVariant}>
          <div className="desc">
            Calo Token is minted at the Token Generation Event (TGE) with the
            total number of 500,000,000 minted Tokens.
          </div>
          <div className="contract-box flex-between">
            <div className="label">
              Contract address:
              <span className="address">{caloAddress}</span>
            </div>
            <Tooltip
              title={copied ? 'Copied' : 'Copy'}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setCopied(false);
                }
              }}
            >
              <img
                className="icon-copy"
                src={iconCopy}
                onClick={() => copyAddress(caloAddress)}
              />
            </Tooltip>
          </div>
        </MotionVisible>
        <MotionVisible
          className="img-box text-center"
          variants={animationVariant}
        >
          <img className="img-fluid" src={tokenmetric} />
        </MotionVisible>
      </div>
    </StyledTokenmetricsDesktop>
  );
};

export default TokenMetricsDesktop;
