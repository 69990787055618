import { Layout } from 'antd';
import styled from 'styled-components';
import introBannerBg from 'src/assets/images/introduction/desktop/introBannerBg3.png';
import bannerGameplayRelease from 'src/assets/images/introduction/desktop/bannerGameplayRelease.png';
import bannerGameplayReleaseBgMb from 'src/assets/images/introduction/mobile/gameplayReleaseBgMb.png';
import bannerMintingBg from 'src/assets/images/introduction/desktop/bannerMintingBg.png'
import bannerMintingBgMb from 'src/assets/images/introduction/mobile/bannerMintingBgMb.png'
import introOPBg from 'src/assets/images/onepaper/introOPBg.png';
import introOPBgMb from 'src/assets/images/onepaper/introOPBgMb.png';
import bannerHTP from 'src/assets/images/howtoplay/bannerHTP.png';
import bannerHTPMb from 'src/assets/images/howtoplay/bannerHTPMb.png';
import gameBg from 'src/assets/images/onepaper/gameBg2.png';
import gameBgMb from 'src/assets/images/onepaper/gameBgMb3.png';
import tokensBg from 'src/assets/images/onepaper/tokensBg2.png';
import tokensBgMb from 'src/assets/images/onepaper/tokensBgMb.png';

export const StyledLayout = styled(Layout)`
  overflow: hidden;
  background: ${({ theme }) => theme.body};
  min-height: 100vh;
  .intro-section {
    background-image: url(${introBannerBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* max-height: 816px;
    height: 100vh;
    @media (max-width: 1024px) {
      height: 716px;
      max-height: unset;
    }
    @media (max-width: 900px) {
      height: 100%;
    } */
    @media (max-width: 768px) {
      background-image: unset;
      height: 100%;
      max-height: unset;
    }
  }

  .intro-section-event-ga {
    background-image: url(${bannerGameplayRelease});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    @media (max-width: 768px) {
      background-image: url(${bannerGameplayReleaseBgMb});
      height: 100%;
      max-height: unset;
    }
  }

  .intro-section-event-minting {
    background-image: url(${bannerMintingBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    @media (max-width: 768px) {
      background-image: url(${bannerMintingBgMb});
      height: 100%;
      max-height: unset;
    }
  }

  .intro-section-op {
    background-image: url(${introOPBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 900px;
    /* height: 100vh; */
    @media (max-width: 768px) {
      background-image: url(${introOPBgMb});
      background-position: center;
      background-size: 100% 100%;
      height: 100%;
      max-height: unset;
    }
  }

  .intro-section-htp {
    background-image: url(${bannerHTP});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 986px;
    height: 100vh;
    @media (max-width: 768px) {
      background-image: url(${bannerHTPMb});
      background-position: bottom;
      background-size: cover;
      height: 100%;
      min-height: 95vh;
    }
  }

  .game-released-section {
    background-image: url(${gameBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 768px) {
      background-image: url(${gameBgMb});
    }
  }

  .token-section {
    background-image: url(${tokensBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media (max-width: 1440px) {
      /* background-size: 100% 100%;       */
    }
    @media (max-width: 768px) {
      background-image: url(${tokensBgMb});
      /* background-size: 100% 100%; */
    }
  }

  .scroll-down-wrapper {
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    transform: translate(0, -50%);
    @media (max-width: 1024px) {
      margin-top: 24px;
    }
    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      opacity: 0;
      transform: scale3d(0.5, 0.5, 0.5);
      animation: move 3s ease-out infinite;
    }

    .chevron:first-child {
      animation: move 3s ease-out 1s infinite;
    }

    .chevron:nth-child(2) {
      animation: move 3s ease-out 2s infinite;
    }

    .chevron:before,
    .chevron:after {
      content: ' ';
      position: absolute;
      top: 0;
      height: 100%;
      width: 51%;
      background: #fff;
    }

    .chevron:before {
      left: 0;
      transform: skew(0deg, 30deg);
    }

    .chevron:after {
      right: 0;
      width: 50%;
      transform: skew(0deg, -30deg);
    }

    @keyframes move {
      25% {
        opacity: 1;
      }
      33% {
        opacity: 1;
        transform: translateY(30px);
      }
      67% {
        opacity: 1;
        transform: translateY(40px);
      }
      100% {
        opacity: 0;
        transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
      }
    }
  }
`;

export const StyledAnimatedCursor = styled.div`
  > div {
    border: ${({ theme }) => theme.cursorBorder};
    border-inline-style: solid;
    background-color: rgba(255, 255, 255, 0.01);
  }
`;
