import React from 'react';
import { Tooltip } from 'antd';
import { motion } from 'framer-motion';
import TitleEffect from 'src/components/TitleEffect';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import lynnLeft from 'src/assets/images/onepaper/lynnLeft.png';
import lynnRight from 'src/assets/images/onepaper/lynnRight.png';
import { scrollTo } from 'src/utils';
import { StyledIntroOPDesktop } from './styled';

const IntrodOPDesktop = ({ fireEvent }) => {
  const navigateScroll = (toId) => scrollTo({ id: toId });

  return (
    <StyledIntroOPDesktop>
      <div className="container flex-center">
        <div className="box-left">
          <motion.img
            className="img-fluid"
            src={lynnLeft}
            alt="lynnLeft"
            animate={{
              y: ['10px', '0px', '10px'],
            }}
            transition={{ repeat: Infinity, duration: 2 }}
          />
        </div>
        <div className="box-center">
          <div className="title-wrapper">
            <TitleEffect
              text="CALO INDOOR"
              className="main-title"
              fontSize={'72px'}
            />
            <TitleEffect
              text="ONEPAPER"
              className="main-title"
              fontSize={'72px'}
            />
          </div>
          <div className="download-box flex-center">
            <div className="btn-download">
              <a
                href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
        </div>
        <div className="box-right">
          <motion.img
            className="img-fluid"
            src={lynnRight}
            alt="lynnRight"
            animate={{
              y: ['10px', '0px', '10px'],
              delay: 1,
            }}
            transition={{
              repeat: Infinity,
              duration: 2,
            }}
          />
        </div>
      </div>
      <div className="flex-center">
        <div
          className="scroll-down-wrapper"
          onClick={() => navigateScroll('slogan')}
        >
          <div className="chevron" />
          <div className="chevron" />
          <div className="chevron" />
        </div>
      </div>
    </StyledIntroOPDesktop>
  );
};

export default IntrodOPDesktop;
