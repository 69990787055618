import React from 'react';
import lineArrow from 'src/assets/images/onepaper/lineArrowSm.svg';
import tokenB2EMb from 'src/assets/images/onepaper/tokenB2EMb2.png';
import MotionVisible from 'src/components/MotionVisible';
import TitleEffect from 'src/components/TitleEffect';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledB2ETokenMobile } from './styled';

const B2ETokenMobile = () => {
  return (
    <StyledB2ETokenMobile id="b2e">
      <div className="container">
        <MotionVisible className="header" variants={animationVariant}>
          <TitleEffect className="t-1" text="COMBINING" fontSize="40px" />
          <TitleEffect
            className="t-2"
            text="BLOCKCHAIN TECHNOLOGY"
            fontSize="30px"
          />
          <img className="text-center img-fluid" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible variants={animationVariant} className="content-box text-center">
          <img className="img-fluid" src={tokenB2EMb} />
        </MotionVisible>
      </div>
    </StyledB2ETokenMobile>
  );
};

export default B2ETokenMobile;
