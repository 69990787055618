import styled, { css } from 'styled-components';

const StyledTextEffect = styled.span`
  font-family: 'Protector';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || '48px'};
  line-height: ${({ fontSize }) => `calc(${fontSize} - 30px)` || '28px'};
  letter-spacing: 0.05em;
  color: #ffffff;
`;

const StyledTextWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  --stack: ${(props) => props.stacks};
`;

const StyledSpan = styled.span`
  grid-row-start: 1;
  grid-column-start: 1;
  --stack: ${(props) => props.stacks};
  --index: ${(props) => props.index};

  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 100ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 100ms),
    glitch 1.5s ease infinite 2s alternate-reverse;
  &:nth-child(odd) {
    --glitch-translate: 20px;
  }
  &:nth-child(even) {
    --glitch-translate: -20px;
  }
  @keyframes stack {
    0% {
      opacity: 0;
      transform: translateX(-50%);
      text-shadow: -2px 3px 0 #FF4A3E, 2px -3px 0 #10FDE5, -2px -3px 0 #FF4A3E;
    }
    60% {
      opacity: 0.5;
      transform: translateX(50%);
    }
    80% {
      transform: none;
      opacity: 1;
      text-shadow: -2px 3px 0 #FF4A3E, 2px -3px 0 #10FDE5, -2px -3px 0 #FF4A3E;
    }
    100% {
      text-shadow: none;
    }
  }

  @keyframes glitch {
    0% {
      text-shadow: -4px 6px 0 #FF4A3E, 4px -6px 0 #10FDE5;
      transform: translate(var(--glitch-translate));
    }
    5% {
      text-shadow: 4px -6px 0 #FF4A3E, -4px 6px 0 #10FDE5;
    }
    14%,
    100% {
      text-shadow: none;
      transform: none;
    }
  }
`;

export { StyledTextEffect, StyledTextWrap, StyledSpan };
