import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import GameplayDesktop from './GameplayDesktop';
import GameplayMobile from './GameplayMobile';

const Gameplay = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<GameplayDesktop {...props} />}
      mobileView={<GameplayMobile {...props} />}
    />
  );
};

export default Gameplay;
