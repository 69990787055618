import styled from 'styled-components';
import b2eBgMb from 'src/assets/images/howtoplay/b2eBgMb.png';

export const StyledDownloadMobile = styled.div`
  position: relative;
  background-image: url(${b2eBgMb});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    margin-top: 70px;
    width: 100%;
    .col-text {
      .main-title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.0125em;
        color: #ffffff;
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        text-align: center;
      }
      .title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.0125em;
        color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        text-align: center;
      }
      .download-box {
        margin-top: 40px;
        .btn-download {
          img {
            width: 145px;
          }
          &:first-child {
            margin-right: 16px;
          }
        }
      }
    }
    .col-img {
      .box-img {
        text-align: center;
        img {
          max-width: 375px;
        }
      }
    }
  }
`;
