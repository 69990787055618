export const detailInfo = [
  {
    title: 'SPACE',
    desc: 'Higher quality space boost the stone’s attribute',
    value: 1,
  },
  {
    title: 'STONE',
    desc: 'Increase sneaker’s attribute with stone',
    value: 2,
  },
  {
    title: 'QUALITY - LEVEL',
    desc: 'Common, Uncommon, Rare, Epic, Legendary',
    value: 3,
  },
  {
    title: 'REPAIR NFT',
    desc: 'Repair NFT after finishing exercises indoor',
    value: 4,
  },
  {
    title: 'GAMEPLAY',
    desc: 'Each type of shoe will correspond to a game type',
    value: 5,
  },
  {
    title: 'PERFORMANCE',
    desc: 'Enhance FIT earning',
    value: 6,
  },
  {
    title: 'LUCK',
    desc: 'Increase gacha box drop rate',
    value: 7,
  },
  {
    title: 'SPEED',
    desc: 'Enhance CALO earning (at level 30)',
    value: 8,
  },
  {
    title: 'RECOVERY',
    desc: 'Reduces repair costs',
    value: 9,
  },
];
