import { Col, Image, Row } from 'antd';
import React from 'react';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import lineArrowSm from 'src/assets/images/onepaper/lineArrowSm.svg';
import reflexGame from 'src/assets/images/onepaper/reflexGame.png';
import shapeGame from 'src/assets/images/onepaper/shapeGame.png';
import jumpGame from 'src/assets/images/onepaper/jumpGame.png';
import { animationVariant } from 'src/variables/animationVariant';
import TitleEffect from '../TitleEffect';
import MotionVisible from '../MotionVisible';
import { StyledGameReleased } from './styled';
import SliderSlick from '../SliderSlick/SliderSlick';
import breakpoint from 'src/variables/breakpoint';
import ResponsiveLayout from '../ResponsiveLayout';

const GameReleased = () => {
  const slideSettings = {
    className: 'center',
    infinite: false,
    centerMode: true,
    centerPadding: '20px',
    slidesToShow: 3,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: breakpoint.mdMin,
        settings: {
          centerPadding: '0',
          centerMode: true,
          infinite: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '40px',
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '8px',
          height: '8px',
          backgroundColor: '#AAB2C8',
          borderRadius: '50%',
        }}
      />
    ),
  };

  const contentMobile = () => {
    return (
      <div variants={animationVariant} className="items">
        <Image.PreviewGroup>
          <SliderSlick
            showArrow={false}
            settings={slideSettings}
            paddingX="8px"
            paddingY=""
          >
            <MotionVisible
              className="game-item"
              variants={animationVariant}
              key="game-1"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/gameplay"
              >
                <Image src={reflexGame} preview={false} />
              </a>
            </MotionVisible>
            <MotionVisible
              className="game-item"
              variants={animationVariant}
              key="game-1"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/gameplay"
              >
                <Image src={shapeGame} preview={false} />
              </a>
            </MotionVisible>
            <MotionVisible
              className="game-item"
              variants={animationVariant}
              key="game-1"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/gameplay"
              >
                <Image src={jumpGame} preview={false} />
              </a>
            </MotionVisible>
          </SliderSlick>
        </Image.PreviewGroup>
      </div>
    );
  };

  const contentDesktop = () => {
    return (
      <div variants={animationVariant} className="items flex-between">
        <Image.PreviewGroup>
          <MotionVisible
            className="game-item"
            variants={animationVariant}
            key="game-1"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/gameplay"
            >
              <Image src={reflexGame} preview={false} />
            </a>
          </MotionVisible>
          <MotionVisible
            className="game-item"
            variants={animationVariant}
            key="game-1"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/gameplay"
            >
              <Image src={shapeGame} preview={false} />
            </a>
          </MotionVisible>
          <MotionVisible
            className="game-item"
            variants={animationVariant}
            key="game-1"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/gameplay"
            >
              <Image src={jumpGame} preview={false} />
            </a>
          </MotionVisible>
        </Image.PreviewGroup>
      </div>
    );
  };

  return (
    <StyledGameReleased id="game-released">
      <div className="container">
        <MotionVisible className="header-lg" variants={animationVariant}>
          <TitleEffect text="GAME RELEASED" fontSize="72px" />
          <div className="desc">
            Augmented Reality (AR) technology is applied to the game system. In
            each game, players will be allowed to choose 1 out of 3 level: Easy
            - Medium - Hard
          </div>
          <img className="text-center img-fluid" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible className="header-sm" variants={animationVariant}>
          <TitleEffect text="GAME RELEASED" fontSize="40px" />
          <div className="desc">
            Augmented Reality (AR) technology is applied to the game system. In
            each game, players will be allowed to choose 1 out of 3 level: Easy
            - Medium - Hard
          </div>
          <img className="text-center img-fluid" src={lineArrowSm} alt="line" />
        </MotionVisible>
        <ResponsiveLayout
          desktopView={contentDesktop()}
          mobileView={contentMobile()}
        />
      </div>
    </StyledGameReleased>
  );
};

export default GameReleased;
