import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import FindSneakerDesktop from './FindSneakerDesktop';
import FindSneakerMobile from './FindSneakerMobile';

const FindSneaker = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<FindSneakerDesktop {...props} />}
      mobileView={<FindSneakerMobile {...props} />}
    />
  );
};

export default FindSneaker;
