import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from 'antd';
import { useAnimation } from 'framer-motion';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import topic1 from 'src/assets/images/topics/topicNew1.jpg';
import topic2 from 'src/assets/images/topics/topicNew2.jpeg';
import topic3 from 'src/assets/images/topics/topicMinting.png';
import topic4 from 'src/assets/images/topics/topicHiker2.png';
import topic5 from 'src/assets/images/topics/topicTwitter.png';
import { StyledTopics } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';

const TopicsDesktop = (props) => {
  const controls = useAnimation();
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  return (
    <StyledTopics ref={inViewRef} id="topics">
      <div className="container">
        <div className="topics">
          <MotionVisible className="title" variants={animationVariant}>
            <TitleEffect text="Hot Topics" fontSize="100px" />
          </MotionVisible>
          <MotionVisible className="desc" variants={animationVariant}>
            Keep in your eyes this field in order not to miss important news
          </MotionVisible>
          <MotionVisible variants={animationVariant}>
            <Image.PreviewGroup>
              <SliderSlick showArrow paddingX="16px" paddingY="">
                <div className="topic-item">
                  <Image src={topic5} preview={false} />
                </div>
                <div className="topic-item">
                  <Image src={topic4} preview={false} />
                </div>
                <div className="topic-item">
                  <Image src={topic3} preview={false} />
                </div>
                <div className="topic-item">
                  <Image src={topic1} preview={false} />
                </div>
                <div className="topic-item">
                  <Image src={topic2} preview={false} />
                </div>
              </SliderSlick>
            </Image.PreviewGroup>
          </MotionVisible>
        </div>
      </div>
    </StyledTopics>
  );
};

export default TopicsDesktop;
