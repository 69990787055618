import React from 'react';
import StyledButton from './styled';

const Button = ({
  children,
  size = 'md',
  width = 'fit',
  buttonType = 'primary',
  htmlType = 'button',
  onClick,
  disabled = false,
  loading,
  ...other
}) => {
  return (
    <StyledButton
      size={size}
      width={width}
      buttonType={buttonType}
      disabled={disabled}
      onClick={onClick}
      type={htmlType}
      {...other}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
