import slipper from 'src/assets/images/nft-system/slipper2.png';
import pumper from 'src/assets/images/nft-system/pumper2.png';
import clever from 'src/assets/images/nft-system/clever2.png';
import unknown from 'src/assets/images/nft-system/unknown.png';

export const detailContent = {
  PUMPER: {
    name: 'PUMPER',
    desc: 'Equip Pumper sneaker in Fit The Shape, which move your whole body to fit in the shapes in time.',
    img: pumper,
  },
  CLEVER: {
    name: 'CLEVER',
    desc: 'Equip Clever Sneaker in Jumping Battle, which jump on 2 squares at the same time sequentially.',
    img: clever,
  },
  SLIPPER: {
    name: 'SLIPPER',
    desc: 'Equip Slipper in Reflex Game, which use your hands to touch the points around you in order.',
    img: slipper,
  },
  TRAINER: {
    name: '????',
    desc: 'You can use "????" to join all games.',
    img: unknown,
  },
};