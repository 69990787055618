import React from 'react';
import iconClose from 'src/assets/images/iconClose.png';
import TitleEffect from 'src/components/TitleEffect';
import { detailContent } from '../ModalDetailSneaker/detailContent';
// styled
import { StyledModalDetailSneaker } from './styled';

const ModalDetailSneakerMb = (props) => {
  const { selected, visible, onCancel, ...other } = props;

  return (
    <StyledModalDetailSneaker
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      destroyOnClose
      closable
      closeIcon={<img src={iconClose} alt="iconClose" />}
      {...other}
    >
      <div className="detail-content">
        <img
          className="img-fluid"
          src={detailContent[selected]?.img}
          alt="sneakerSample"
        />
        <div className="title">
          <TitleEffect text={detailContent[selected]?.name} />
        </div>
        <div className="description">{detailContent[selected]?.desc}</div>
      </div>
    </StyledModalDetailSneaker>
  );
};

export default ModalDetailSneakerMb;
