import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import RoadmapOPDesktop from './RoadmapOPDesktop';
import RoadmapOPMobile from './RoadmapOPMobile';

const RoadmapOnePaper = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<RoadmapOPDesktop {...props} />}
      mobileView={<RoadmapOPMobile {...props} />}
    />
  );
};

export default RoadmapOnePaper;
