import styled from 'styled-components';
import socialBg from 'src/assets/images/socialBg.png';

export const StyledSocial = styled.section`
  height: 800px;
  position: relative;
  background-image: url(${socialBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    height: 100%;
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .ant-row {
      height: 100%;
    }
    .col-content {
      height: 100%;
      max-width: 665px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: end;
      .object {
        margin-bottom: 20px;
        img {
          width: 177px;
        }
      }
      .title {
        text-align: right;
        margin-bottom: 16px;
      }
      .desc {
        padding: 19px 16px;
        max-width: 560px;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          text-align: right;
          letter-spacing: 0.4px;
          color: #ffffff;
          position: relative;
          z-index: 1;
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-20deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-20deg);
        }
      }
      .icon-wrapper {
        margin-top: 16px;
        width: 360px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        img {
          margin-bottom: 16px;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 16px;
          }
          &:nth-child(4) {
            margin-right: 0;
          }
        }
        .image-social {
          position: relative;
          overflow: hidden;
          img {
            max-width: 60px;
          }
          img.hover-img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.2s ease;
          }
          &:hover {
            img {
              opacity: 0;
              transition: opacity 0.2s ease;
            }
            img.hover-img {
              opacity: 1;
            }
          }
        }
      }
      .download-box {
        padding: 24px 0 42px 0;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
    .col-img {
      left: -100px;
      .mainObject {
        position: absolute;
        bottom: 0;
        right: -10%;
      }
    }
  }
  @media (max-width: 1024px) {
    .container {
      max-width: 982px;
      .title {
        font-size: 54px !important;
      }
      .col-img {
        left: unset;
      }
    }
  }
`;
