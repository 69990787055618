import styled from 'styled-components';
import boxBg from 'src/assets/images/onepaper/boxTokenBg.png';

export const StyledTokenomicDesktop = styled.section`
  .container {
    max-width: 1216px;
    margin: 0 auto;
    padding-top: 64px;
    width: 100%;
    .header {
      text-align: center;
      .t-1 {
        line-height: 80px;
      }
    }
    .content-box {
      margin: 106px 0 192px 0;
      width: 100%;
      padding: 0 16px;
    }
  }
`;
