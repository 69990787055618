import styled from 'styled-components';
import contentBoxBg from 'src/assets/images/onepaper/contentBoxBg.png';
import sloganBg from 'src/assets/images/onepaper/sloganBg.png';
import sloganBgMb from 'src/assets/images/onepaper/sloganBgMb.png';

export const StyledSlogan = styled.section`
  background-image: url(${sloganBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 767px) {
    background-image: url(${sloganBgMb});
  }
  .container {
    max-width: 1216px;
    margin: 0 auto;
    padding-top: 64px;
    width: 100%;
    @media (max-width: 568px) {
      margin-top: 64px;
      padding: 0 16px;
    }
    @media (min-width: 767px) {
      .header-lg {
        text-align: center;
        display: block;
      }
      .header-sm {
        text-align: center;
        display: none;
      }
    }
    @media (max-width: 767px) {
      .header-lg {
        text-align: center;
        display: none;
      }
      .header-sm {
        text-align: center;
        display: block;
        img {
          margin-top: 16px;
        }
      }
    }

    .content-wrapper {
      padding-top: 64px;
      padding-bottom: 230px;
      @media (max-width: 768px) {
        padding-bottom: 64px;
      }
      .content-item {
        max-width: 372px;
        height: 247px;
        background-image: url(${contentBoxBg});
        background-size: 100% 100%;
        @media (max-width: 1024px) {
          max-width: 282px;
        }
        @media (max-width: 768px) {
          max-width: 385px;
          height: 200px;
          margin: 12px 0;
        }
        @media (max-width: 568px) {
          max-width: 295px;
          height: 186px;
        }
        .text-wrapper {
          flex-direction: column;
          height: 100%;
          .label {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #ffffff;
          }
        }
      }
    }
  }
`;
