import React from 'react';
import * as ga from 'src/utils/ga';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import IntroEventGADesktop from './IntroEventGADesktop';
import IntroEventGAMobile from './IntroEventGAMobile';

const IntroEventGA = (props) => {
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get('utm_source');

  const onClickDownload = (key) => {
    ga.event({
      action: `download_${key}`,
      params: {
        category: `Download ${key}`,
        action: `click download ${key}`,
        label: `source-${utm_source}-download-${key}`,
        value: `${key}`,
      },
    });
  };

  return (
    <ResponsiveLayout
      desktopView={
        <IntroEventGADesktop fireEvent={onClickDownload} {...props} />
      }
      mobileView={<IntroEventGAMobile fireEvent={onClickDownload} {...props} />}
    />
  );
};

export default IntroEventGA;
