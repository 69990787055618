import styled from 'styled-components';
import boxPolygon from 'src/assets/images/boxPolygon.png';
import roadmapBg from 'src/assets/images/roadmapBg2.png';

export const StyledRoadmapV2Desktop = styled.div`
  margin-top: -1px;
  padding: 90px 0;
  position: relative;
  background-image: url(${roadmapBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .container {
    padding: 0;
    max-width: 100%;
    margin: 0 auto;
    .title {
      text-align: center;
    }
    .roadmap-wrapper {
      padding-top: 40px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;
