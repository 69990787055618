import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import {
  boxItemLeftVariant,
  boxItemRightVariant,
  nonAnimatingContainer,
} from 'src/variables/animationVariant';
import caloTokenStr from 'src/assets/images/caloTokenStr.png';
import iFitTokenStr from 'src/assets/images/iFitTokenStr.png';
import indoorCenter from 'src/assets/images/token/indoorCenter.png';
import arrowToken from 'src/assets/images/token/arrowTokenX.svg';

import { StyledTokenDesktop } from './styled';
import TitleEffect from 'src/components/TitleEffect';

const TokenDesktop = (props) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });
  const controls = useAnimation();

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '50%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledTokenDesktop ref={inViewRef}>
      <AnimatePresence>
        <motion.div
          className="container"
          initial="hidden"
          animate={controls}
          variants={nonAnimatingContainer}
        >
          <motion.div className="title" variants={animationVariant}>
            <TitleEffect text="Tokenomic" fontSize="100px" />
          </motion.div>

          <motion.div className="contentBox" variants={animationVariant}>
            <div className="boxLeft boxItem">
              <motion.div
                animate={{
                  y: ['10px', '0px', '10px'],
                }}
                variants={boxItemLeftVariant}
                transition={{ repeat: Infinity, duration: 2 }}
                className="coinLogo"
              >
                <img src={iFitTokenStr} />
              </motion.div>
              <div className="name">IFIT TOKEN</div>
              <div className="desc">UNLIMITED</div>
            </div>
            <div className="boxMiddle flex-center">
              <img src={indoorCenter} />
              <div className="description ">
                CALO Token and IFIT Token are both blockchain based currencies
                and belong to the Calo Ecosystem.
              </div>
            </div>
            <div className="boxRight boxItem">
              <motion.div
                animate={{
                  y: ['10px', '0px', '10px'],
                }}
                variants={boxItemRightVariant}
                transition={{ repeat: Infinity, duration: 2 }}
                className="coinLogo"
              >
                <img src={caloTokenStr} />
              </motion.div>
              <div className="name">CALO TOKEN</div>
              <div className="desc">LIMITED</div>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </StyledTokenDesktop>
  );
};

export default TokenDesktop;
