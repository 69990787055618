import React, { useState } from 'react';
import btnHTP from 'src/assets/images/howtoplay/btnHTP.png';
import ModalGameInfo from 'src/components/Modals/ModalGameInfo';
import { StyledGameCard } from './styled';

const GameCard = ({ game }) => {
  const [open, setOpen] = useState(false);

  const toggleInfo = () => {
    setOpen(!open);
  };

  return (
    <StyledGameCard game={game}>
      <ModalGameInfo selected={game} visible={open} onCancel={toggleInfo} />
      <img
        src={btnHTP}
        className="btn-htp img-fluid"
        alt="btn"
        onClick={toggleInfo}
      />
    </StyledGameCard>
  );
};

export default GameCard;
