import React, { useState } from 'react';
import { wrap } from 'popmotion';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import GameplayDesktop from './GameplayDesktop';
import GameplayMobile from './GameplayMobile';
import { gameplayContents } from './gameplayContent';

const Gameplay = (props) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const idx = wrap(0, gameplayContents.length, page);

  return (
    <ResponsiveLayout
      desktopView={
        <GameplayDesktop
          page={page}
          idx={idx}
          direction={direction}
          setPage={setPage}
          {...props}
        />
      }
      mobileView={
        <GameplayMobile
          page={page}
          idx={idx}
          direction={direction}
          setPage={setPage}
          {...props}
        />
      }
    />
  );
};

export default Gameplay;
