import styled from 'styled-components';

export const StyledIntroHTPMobile = styled.div`
  .container {
    margin-top: 30px;
    width: 100%;
    .box-img {
      img {
        max-width: 365px;
      }
    }
    .box-center {
      text-align: center;
      .title-wrapper {
        margin-top: -60px;
        .main-title {
          text-align: center;
          line-height: 50px;
          @media (max-width: 320px) {
            font-size: 48px;
          }
        }
        .sub-title {
          font-family: 'Protector';
          font-weight: 400;
          font-size: 28px;
          line-height: 32px;
          letter-spacing: 0.0125em;
          color: #ffffff;
          text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        }
      }

      .btn-download {
        img {
          width: 150px;
        }
      }
      .download-box {
        margin-top: 32px;
        flex-direction: column;
        .btn-download {
          &:first-child {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
  .scroll-down-wrapper {
    margin-top: 60px !important;
  }
`;
