import React from 'react';
import { motion } from 'framer-motion';
import TitleEffect from 'src/components/TitleEffect';
import lynnLeft from 'src/assets/images/onepaper/lynnLeft.png';
import lynnRight from 'src/assets/images/onepaper/lynnRight.png';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import { StyledIntroOPMobile } from './styled';
import { Tooltip } from 'antd';
import { scrollTo } from 'src/utils';

const IntroOPMobile = ({ fireEvent }) => {
  const navigateScroll = (toId) => scrollTo({ id: toId });

  return (
    <StyledIntroOPMobile>
      <div className="container">
        <div className="box-center">
          <div className="box-title">
            <div className="main-title">
              <TitleEffect text="CALO INDOOR" fontSize="60px" />
            </div>
            <div className="main-title">
              <TitleEffect text="ONEPAPER" fontSize="60px" />
            </div>
          </div>
          <div className="box-img flex-center">
            <motion.img
              className="img-fluid"
              src={lynnLeft}
              alt="lynnLeft"
              animate={{
                y: ['10px', '0px', '10px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            />
            <motion.img
              className="img-fluid"
              src={lynnRight}
              alt="lynnRight"
              animate={{
                y: ['10px', '0px', '10px'],
                delay: 1,
              }}
              transition={{
                repeat: Infinity,
                duration: 2,
              }}
            />
          </div>
        </div>
        <div className="download-box flex-center">
          <div className="btn-download">
            <a
              href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
              target="_blank"
              rel="noreferrer"
            >
              <img src={btnIos} alt="download" />
            </a>
          </div>
          <div className="btn-download">
            <a
              href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
              target="_blank"
              rel="noreferrer"
            >
              <img src={btnAndroid} alt="download" />
            </a>
          </div>
        </div>
        <div
          className="scroll-down-wrapper flex-center"
          onClick={() => navigateScroll('topics')}
        >
          <div className="chevron" />
          <div className="chevron" />
          <div className="chevron" />
        </div>
      </div>
    </StyledIntroOPMobile>
  );
};

export default IntroOPMobile;
