import React from 'react';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import tokenB2E2 from 'src/assets/images/onepaper/tokenB2E2.png';
import MotionVisible from 'src/components/MotionVisible';
import TitleEffect from 'src/components/TitleEffect';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledB2ETokenDesktop } from './styled';

const B2ETokenDesktop = () => {
  return (
    <StyledB2ETokenDesktop id="b2e">
      <div className="container">
        <MotionVisible className="header" variants={animationVariant}>
          <TitleEffect className="t-1" text="COMBINING" fontSize="60px" />
          <TitleEffect
            className="t-2"
            text="BLOCKCHAIN TECHNOLOGY"
            fontSize="70px"
          />
          <img className="text-center" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible variants={animationVariant} className="content-box">
          <img className="img-fluid" src={tokenB2E2} />
        </MotionVisible>
      </div>
    </StyledB2ETokenDesktop>
  );
};

export default B2ETokenDesktop;
