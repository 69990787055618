import styled from 'styled-components';

export const StyledIntroEventGADesktop = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  overflow: visible;
  width: 100%;
  position: relative;

  .container {
    position: relative;
    overflow: visible !important;
    height: 100%;
    margin: 18px 0;
    .col-img {
      height: 100%;
      width: 100%;
      .box-img {
        max-width: 785px;
        position: relative;
        bottom: -42px;
        @media (max-width: 1024px) {
          bottom: -66px;
          max-width: 600px;
        }
      }
    }
    .col-content {
      text-align: center;
      padding-right: 60px !important;
      .sub-title {
        margin-bottom: 24px;
        width: 438px;
      }
      .main-title {
        margin-bottom: 24px;
        width: 665px;
      }
      .desc {
        font-style: normal;
        font-weight: 19;
        font-size: 27.8272px;
        line-height: 106.6%;
        text-align: center;
        letter-spacing: 0.04em;
        color: #ffffff;
        text-shadow: 1px 1px 0px #ff2e00;
      }
      @media (max-width: 1024px) {
        padding-left: 0 !important;
        .sub-title {
          width: 400px;
        }
        .main-title {
          width: 500px;
        }
        .desc {
          font-size: 24px;
        }
      }

      .download-box {
        padding: 40px 0 42px 0;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .btn-bottom {
        button {
          width: 170px;
        }
      }
    }
    .token-corner {
      position: absolute;
      right: -50px;
      top: 100px;
      width: 95px;
    }
    .ifit-token {
      position: absolute;
      right: 0px;
      top: 400px;
      width: 174px;
      @media (max-width: 1024px) {
        width: 124px;
      }
    }
  }
`;
