/* eslint-disable react/style-prop-object */
import React from 'react';
import { StyledSpan, StyledTextEffect, StyledTextWrap } from './styled';

const TitleEffect = ({ text, className, fontSize }) => {
  return (
    <StyledTextEffect className={className} fontSize={fontSize}>
      <StyledTextWrap className="text-wrap" stacks={3}>
        <StyledSpan index={0}>{text}</StyledSpan>
        <StyledSpan index={1}>{text}</StyledSpan>
        <StyledSpan index={2}>{text}</StyledSpan>
      </StyledTextWrap>
    </StyledTextEffect>
  );
};

export default TitleEffect;
