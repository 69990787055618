import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import ModalDetailSneaker from 'src/components/Modals/ModalDetailSneaker';
import nftSystemCenter from 'src/assets/images/nft-system/nftSystemCenter.png';
import boxPumper from 'src/assets/images/nft-system/boxPumper2.png';
import boxClever from 'src/assets/images/nft-system/boxClever2.png';
import boxSlipper from 'src/assets/images/nft-system/boxSlipper2.png';
import boxUnknown from 'src/assets/images/nft-system/boxUnknown2.png';
import { StyledNFTSystemDesktop } from './styled';
import { nonAnimatingContainer } from 'src/variables/animationVariant';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';

const NFTSystemDesktop = () => {
  const [open, setOpen] = useState(false);
  const [keyDetail, setKeyDetail] = useState('');

  const controls = useAnimation();
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '50%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  const onOpenDetail = (key) => {
    setOpen(true);
    setKeyDetail(key);
  };

  const toggleOpen = () => {
    setOpen(false);
    setKeyDetail('');
  };

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledNFTSystemDesktop id="nft-system" ref={inViewRef}>
      <ModalDetailSneaker
        selected={keyDetail}
        visible={open}
        onCancel={toggleOpen}
      />

      <motion.div
        className="container"
        initial="hidden"
        animate={controls}
        variants={nonAnimatingContainer}
      >
        <MotionVisible variants={animationVariant}>
          <TitleEffect className="title" text="NFTs System" fontSize="100px" />
        </MotionVisible>
        <MotionVisible className="desc" variants={animationVariant}>
          In Calo Indoor, players need to equip the appropriate NFT Sneaker
          according to each gameplay.
        </MotionVisible>
        <MotionVisible className="row-content" variants={animationVariant}>
          <div className="box-left">
            <div className="box-item box-top">
              <img
                src={boxSlipper}
                onClick={() => onOpenDetail('SLIPPER')}
                key={1}
              />
            </div>
            <div className="box-item box-bottom">
              <img
                src={boxClever}
                onClick={() => onOpenDetail('CLEVER')}
                key={2}
              />
            </div>
          </div>
          <div className="box-center">
            <div className="main-img img-fluid">
              <img
                className="img-fluid"
                src={nftSystemCenter}
                alt="nft-system"
              />
            </div>
          </div>
          <div className="box-right">
            <div className="box-item box-top">
              <img
                src={boxPumper}
                onClick={() => onOpenDetail('PUMPER')}
                key={3}
              />
            </div>
            <div className="box-item box-bottom">
              <img
                src={boxUnknown}
                onClick={() => onOpenDetail('TRAINER')}
                key={4}
              />
            </div>
          </div>
        </MotionVisible>
      </motion.div>
    </StyledNFTSystemDesktop>
  );
};

export default NFTSystemDesktop;
