import styled from 'styled-components';
import gameplayBgMb from 'src/assets/images/howtoplay/gameplayBgMb2.png';
import reflex from 'src/assets/images/howtoplay/gameplay/reflexMb.png';
import fitShape from 'src/assets/images/howtoplay/gameplay/fitShapeMb.png';
import jumping from 'src/assets/images/howtoplay/gameplay/jumpingMb.png';

export const StyledGameplayMobile = styled.div`
  padding: 60px 0;
  position: relative;
  height: 100%;
  background-image: url(${gameplayBgMb});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    max-width: 1216px;
    margin: 0 auto;
    margin-top: 70px;
    width: 100%;
    padding: 0 24px;
    .title {
      font-family: 'Protector';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 46px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-align: center;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      margin-bottom: 40px;
    }

    .slick-dots {
        bottom: -40px;
    }
  }
`;

export const StyledGameCard = styled.div`
  padding-bottom: 32px;
  height: 450px;
  max-width: 328px;
  margin: 0 auto;
  background-image: ${({ game }) => {
    switch (game) {
      case 'reflex':
        return `url(${reflex})`;
      case 'fitShape':
        return `url(${fitShape})`;
      default:
        return `url(${jumping})`;
    }
  }};
  background-size: 100% 100%;
  position: relative;
  .btn-htp {
    width: 140px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 40px;
    cursor: pointer;
  }
  @media screen and (max-width: 320px) {
    height: 380px;
  }
`;
