import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import Header from 'src/components/Header/Header';
import IntroOnePaper from 'src/components/IntroOnePaper/IntroOnePaper';
import Slogan from 'src/components/Slogan/Slogan';
import StartGame from 'src/components/StartGame/StartGame';
import GameReleased from 'src/components/GameReleased/GameReleased';
import B2EToken from 'src/components/B2EToken/B2EToken';
import Tokenomics from 'src/components/Tokenomics/Tokenomics';
import TokenMetrics from 'src/components/TokenMetrics/TokenMetrics';
import TokenDemand from 'src/components/TokenDemand';
import RoadmapOnePaper from 'src/components/RoadmapOnePaper/RoadmapOnePaper';
import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import { Affix, BackTop } from 'antd';
import { scrollTo } from 'src/utils';
import { StyledLayout } from '../home.styled';

const OnePaper = () => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  useEffect(() => {
    const sectionId = window.location.hash;
    if (sectionId) {
      setTimeout(() => {
        navigateScroll(sectionId.substring(1));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <ThemeProvider theme={themeMode}>
      <StyledLayout>
        <div className="intro-section-op">
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
          <IntroOnePaper />
        </div>
        <Slogan />
        <StartGame />
        <div className="game-released-section">
          <GameReleased />
          <B2EToken />
        </div>
        <div className="token-section">
          <Tokenomics />
          <TokenMetrics />
          <TokenDemand />
        </div>
        <RoadmapOnePaper />
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default OnePaper;
