import React, { useState } from 'react';
import classNames from 'classnames';
import nftDetailMb from 'src/assets/images/howtoplay/nftDetailMb2.png';
import arrowPrev from 'src/assets/images/howtoplay/arrowPrev.svg';
import arrowNext from 'src/assets/images/howtoplay/arrowNext.svg';
import pointerLeft from 'src/assets/images/howtoplay/pointerLeft.png';
import pointerRight from 'src/assets/images/howtoplay/pointerRight.png';
import { IndexNumber, StyledNFTDetailMobile } from './styled';
import { detailInfo } from '../detailInfo';
import MotionVisible from 'src/components/MotionVisible';
import { boxItemRightVariant } from 'src/variables/animationVariant';

const NFTDetailMobile = () => {
  const [index, setIndex] = useState(1);

  const onNext = () => {
    if (index < 9) {
      setIndex((index) => index + 1);
    }
  };

  const onPrev = () => {
    if (index > 1) {
      setIndex((index) => index - 1);
    }
  };

  return (
    <StyledNFTDetailMobile>
      <div className="container">
        <div className="title">NFT DETAIL PAGE</div>
        <div className="nft-wrapper">
          {/* left-col */}
          <div className="index-pointer pointer-left index-1 flex-start">
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 1,
              })}
            >
              1
            </IndexNumber>
            <img className="img-pointer" src={pointerLeft} alt="pointer" />
          </div>
          <div className="index-pointer pointer-left index-3 flex-start">
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 3,
              })}
            >
              3
            </IndexNumber>
            <img className="img-pointer" src={pointerLeft} alt="pointer" />
          </div>
          <div className="index-pointer pointer-left index-5 flex-start">
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 5,
              })}
            >
              5
            </IndexNumber>
            <img className="img-pointer" src={pointerLeft} alt="pointer" />
          </div>
          <div className="index-pointer pointer-left index-6 flex-start">
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 6,
              })}
            >
              6
            </IndexNumber>
            <img className="img-pointer" src={pointerLeft} alt="pointer" />
          </div>
          <div className="index-pointer pointer-left index-8 flex-start">
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 8,
              })}
            >
              8
            </IndexNumber>
            <img className="img-pointer" src={pointerLeft} alt="pointer" />
          </div>
          {/* right-col */}
          <div className="index-pointer pointer-right index-2 flex-start">
            <img className="img-pointer" src={pointerRight} alt="pointer" />
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 2,
              })}
            >
              2
            </IndexNumber>
          </div>
          <div className="index-pointer pointer-right index-4 flex-start">
            <img className="img-pointer" src={pointerRight} alt="pointer" />
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 4,
              })}
            >
              4
            </IndexNumber>
          </div>
          <div className="index-pointer pointer-right index-7 flex-start">
            <img className="img-pointer" src={pointerRight} alt="pointer" />
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 7,
              })}
            >
              7
            </IndexNumber>
          </div>
          <div className="index-pointer pointer-right index-9 flex-start">
            <img className="img-pointer" src={pointerRight} alt="pointer" />
            <IndexNumber
              className={classNames('number flex-center', {
                active: index === 9,
              })}
            >
              9
            </IndexNumber>
          </div>
          {/* center */}
          <div className="main-img flex-center">
            <div className="blur" />
            <img className="img-fluid" src={nftDetailMb} alt="main" />
          </div>
        </div>
        <MotionVisible
          className="info-card"
          variants={boxItemRightVariant}
          key={index}
        >
          <div className="title">{detailInfo[index - 1].title}</div>
          <div className="desc">{detailInfo[index - 1].desc}</div>
        </MotionVisible>
        <div className="flex-center card-index">
          <img
            className="icon prev"
            src={arrowPrev}
            alt="prev"
            onClick={onPrev}
          />
          <span className="flex-center index">{index}/9</span>
          <img
            className="icon next"
            src={arrowNext}
            alt="next"
            onClick={onNext}
          />
        </div>
      </div>
    </StyledNFTDetailMobile>
  );
};

export default NFTDetailMobile;
