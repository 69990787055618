import { Col, Row } from 'antd';
import React from 'react';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import lineArrowSm from 'src/assets/images/onepaper/lineArrowSm.svg';
import cardGameplay from 'src/assets/images/onepaper/cardGameplay.png';
import cardNFT from 'src/assets/images/onepaper/cardNFT.png';
import cardReward from 'src/assets/images/onepaper/cardReward2.png';
import TitleEffect from '../TitleEffect';
import { StyledStartGame } from './styled';

const StartGame = () => {
  return (
    <StyledStartGame id="game">
      <div className="container">
        <div className="header-lg">
          <TitleEffect text="START YOUR GAME" fontSize="72px" />
          <img className="text-center img-fluid" src={lineArrow} alt="line" />
        </div>
        <div className="header-sm">
          <TitleEffect text="START YOUR GAME" fontSize="40px" />
          <img className="text-center img-fluid" src={lineArrowSm} alt="line" />
        </div>
        <Row gutter={[0, 0]} className="content-wrapper" justify="space-around">
          <Col xs={24} md={24} lg={8} className="content-item">
            <div className="text-wrapper flex-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system"
              >
                <img className="img-fluid" src={cardGameplay} />
              </a>
            </div>
          </Col>
          <Col xs={24} md={24} lg={8} className="content-item">
            <div className="text-wrapper flex-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/welcome-to-calo-indoor/indoor-gameplay-system/nft-sneaker-system"
              >
                <img className="img-fluid" src={cardNFT} />
              </a>
            </div>
          </Col>
          <Col xs={24} md={24} lg={8} className="content-item">
            <div className="text-wrapper flex-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/welcome-to-calo-indoor/earning-mechanics"
              >
                <img className="img-fluid" src={cardReward} />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </StyledStartGame>
  );
};

export default StartGame;
