import styled from 'styled-components';
import gameplayBg from 'src/assets/images/howtoplay/gameplayBg2.png';
import reflex from 'src/assets/images/howtoplay/gameplay/reflex.png';
import fitShape from 'src/assets/images/howtoplay/gameplay/fitShape.png';
import jumping from 'src/assets/images/howtoplay/gameplay/jumping.png';
import tab from 'src/assets/images/howtoplay/tab.png';
import tabActive from 'src/assets/images/howtoplay/tabActive.png';

export const StyledGameplayDesktop = styled.div`
  padding-top: 40px;
  position: relative;
  height: 900px;
  background-image: url(${gameplayBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    max-width: 1216px;
    margin: 0 auto;
    width: 100%;
    .title {
      font-family: 'Protector';
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 92px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
      margin-bottom: 40px;
    }
    .game-tabs {
      /* margin: 0 auto; */
      margin-top: 90px;
      /* max-width: 1008px; */
      > div {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
  }
`;

export const StyledGameCard = styled.div`
  min-height: 426px;
  max-width: 1018px;
  margin: 0 auto;
  background-image: ${({ game }) => {
    switch (game) {
      case 'reflex':
        return `url(${reflex})`;
      case 'fitShape':
        return `url(${fitShape})`;
      default:
        return `url(${jumping})`;
    }
  }};
  background-size: 100% 100%;
  position: relative;
  .btn-htp {
    width: 240px;
    position: absolute;
    left: 120px;
    bottom: 90px;
    cursor: pointer;
  }
`;

export const GameTab = styled.div`
  width: 100%;
  height: 120px;
  background-image: url(${tab});
  background-size: 100% 100%;
  cursor: pointer;
  transition: all ease-in-out 0.1s;
  position: relative;
  top: 0;
  &:hover {
    background-image: url(${tabActive});
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.25));
  }
  &.active {
    position: relative;
    top: -24px;
    background-image: url(${tabActive});
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.25));
  }
  .name {
    font-family: 'Boxed';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
    height: 100%;
  }
`;
