import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Row, Col } from 'antd';
import { gameplayContents } from '../gameplayContent';
import {
  animationVariant,
  nonAnimatingContainer,
} from 'src/variables/animationVariant';
import { StyledGameplayMobile } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import FilterButtonGameplay from '../FilterButtonGameplay';
import MotionVisible from 'src/components/MotionVisible';

const gameplayOptions = [
  {
    label: 'Overview Story',
    value: 0,
  },
  {
    label: 'Reflex Game',
    value: 1,
  },
  {
    label: 'Fit the Shape',
    value: 2,
  },
  {
    label: 'Jumping Battle',
    value: 3,
  },
];

const GameplayMobile = () => {
  const controls = useAnimation();
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const MotionCol = motion(Col);

  const [index, setIndex] = useState(0);

  function createMarkup(text) {
    return { __html: text };
  }

  function rawHTML(text) {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledGameplayMobile id="gameplay" ref={inViewRef}>
      <AnimatePresence>
        <motion.div
          className="container"
          initial="hidden"
          animate={controls}
          variants={nonAnimatingContainer}
        >
          <motion.div className="title" variants={animationVariant}>
            <TitleEffect text="Our" />
          </motion.div>
          <motion.div className="title" variants={animationVariant}>
            <TitleEffect text="Gameplay" />
          </motion.div>
          <MotionVisible variants={animationVariant}>
            <Row gutter={0} className="row-content" align="middle">
              <Col
                span={24}
                className="col-img"
                variants={animationVariant}
              >
                <motion.img
                  key={index}
                  initial={{ x: 10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -10, opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  className="img-fluid content-img"
                  src={gameplayContents[index].img}
                />
              </Col>
              <Col span={24} variants={animationVariant}>
                <motion.div
                  className="slider-content"
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  key={index}
                >
                  <div className="content-box">
                    <div className="content-title">
                      {gameplayContents[index].storyTitle}
                    </div>
                    <div className="content-desc">
                      {rawHTML(gameplayContents[index].storyDesc)}
                    </div>
                  </div>
                </motion.div>
                <FilterButtonGameplay
                  selected={index}
                  options={gameplayOptions}
                  onSelect={setIndex}
                />
              </Col>
            </Row>
          </MotionVisible>

        </motion.div>
      </AnimatePresence>
    </StyledGameplayMobile>
  );
};

export default GameplayMobile;
