import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import TokenomicsDesktop from './TokenomicsDesktop';
import TokenomicsMobile from './TokenomicsMobile';

const Tokenomics = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<TokenomicsDesktop {...props} />}
      mobileView={<TokenomicsMobile {...props} />}
    />
  );
};

export default Tokenomics;
