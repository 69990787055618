import styled from 'styled-components';

export const StyledHeader = styled.header`
  width: 100%;
  background: rgba(20, 20, 20, 0.7);
  padding-top: 16px;
  .nav-container {
    padding: 16px 0;
    .nav-wrapper {
      max-width: 1285px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1024px) {
        padding: 0 16px;
      }
    }
    .nav-header {
      .nav-brand {
        .nav-logo {
          /* width: 87px; */
          @media (max-width: 1024px) {
            width: 146px;
          }
        }
      }
    }
  }
  .menu-center-container {
    display: flex;
    align-items: center;
    .menu-container {
      .nav-menu {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }
  .menu-right-container {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1024px) {
    .menu-center-container {
      .menu-container {
        margin-right: 0;
        .nav-menu {
          .nav-link {
            margin-right: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 820px) {
    .nav-container {
      padding: 0 8px;
    }
    .menu-center-container {
      .menu-container {
        margin-right: 0;
        .nav-menu {
          .nav-link {
            margin-right: 12px;
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export const StyledTokenAddress = styled.div`
  min-height: 70px;
  width: 1008px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(180deg, #101222 0%, rgba(16, 18, 34, 0.24) 100%);
  border-radius: 16px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.6px;
    background: linear-gradient(
      90.22deg,
      #ffffff 1.48%,
      rgba(255, 255, 255, 0) 33.59%,
      #ffffff 94.31%
    );
    mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 1;
  }
  .contract {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    z-index: 2;
    .calo {
      padding: 0 8px 0 14px;
      font-weight: 600;
      color: #ff9e58;
    }
    .ifit {
      color: #fd7d74 !important;
    }
    .address {
      font-weight: 600;
      color: #b3b4cd;
    }
    .copy {
      margin-left: 12px;
    }
  }
  .divider {
    margin-left: 26px;
    margin-right: 64px;
  }
  .auditedBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px;
    background: linear-gradient(
      210.66deg,
      rgba(175, 198, 255, 0.157036) 60.09%,
      rgba(255, 255, 255, 0) 93.19%
    );
    border-radius: 16px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      border: 1px solid #afbffa;
      border-radius: 16px;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      padding-right: 16px;
    }
    .status {
      position: absolute;
      left: -10%;
      top: -8%;

      min-height: 20px;
      padding: 4px 12px;
      border-radius: 20px;
      box-sizing: border-box;

      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.01em;

      color: #46cb73;
      border: 0.5px solid #58fe90;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.04)
        ),
        #171f36;
    }
  }
`;

export const ItemLink = styled.a`
  margin-right: 32px;
  cursor: pointer;
  transition: all ease 0.4s;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0125em;
  color: #ffffff;
  &:hover,
  &.active {
    color: #fd7d74;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
