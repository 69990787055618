import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import IntroOPDesktop from './IntroOPDesktop';
import IntroOPMobile from './IntroOPMobile';

const IntroOnePaper = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<IntroOPDesktop {...props} />}
      mobileView={<IntroOPMobile {...props} />}
    />
  );
};

export default IntroOnePaper;
