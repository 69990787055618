import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Home from './pages/Home';
import HowToPlay from './pages/HowToPlay/HowToPlayPage';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import OnePaper from './pages/OnePaper/OnePaperPage';
import PrivacyPage from './pages/Privacy/PrivacyPage';
import TermPage from './pages/Term/TermPage';

export const StyleGlobalTooltip = createGlobalStyle`
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner{
    border-radius: 6px;
    background-color: #171f36;
    font-style: normal;
    font-weight: normal;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const App = () => {
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get('utm_source');

  const setGa = () => {
    window.gtag('set', {
      campaign: {
        source: utm_source || 'organic',
        name: 'calo_indoor',
      },
    });
  };

  useEffect(() => {
    setGa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <>
      <StyleGlobalTooltip />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/one-paper" exact>
            <OnePaper />
          </Route>
          <Route path="/how-to-play" exact>
            <HowToPlay />
          </Route>
          <Route path="/privacy-policy" exact>
            <PrivacyPage />
          </Route>
          <Route path="/terms-and-conditions" exact>
            <TermPage />
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
