import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import IntroHTP from 'src/components/HowToPlay/IntroHTP/IntroHTP';
import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import Header from 'src/components/Header/Header';
import { Affix, BackTop } from 'antd';
import { StyledLayout } from '../home.styled';
import { useAppStore } from 'src/hooks/useAppStore';
import FindSneaker from 'src/components/HowToPlay/FindSneaker/FindSneaker';
import Download from 'src/components/HowToPlay/Download/Download';
import NFTDetail from 'src/components/HowToPlay/NFTDetail/NFTDetail';
import Gameplay from 'src/components/HowToPlay/Gameplay/Gameplay';
import SneakerNFT from 'src/components/HowToPlay/SneakerNFT/SneakerNFT';

const HowToPlay = (props) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;
  const [, updateAppStore] = useAppStore();

  useEffect(() => {
    updateAppStore((draft) => {
      draft.isDarkMode = true;
    });
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <StyledLayout>
        <div className="intro-section-htp">
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
          <IntroHTP />
        </div>
        <Gameplay />
        <SneakerNFT />
        <NFTDetail />
        <FindSneaker />
        <Download />
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default HowToPlay;
