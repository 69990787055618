import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import SneakerNFTDesktop from './SneakerNFTDesktop';
import SneakerNFTMobile from './SneakerNFTMobile';

const SneakerNFT = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<SneakerNFTDesktop {...props} />}
      mobileView={<SneakerNFTMobile {...props} />}
    />
  );
};

export default SneakerNFT;
