import React from 'react';
import { Col, Row } from 'antd';
import MotionVisible from 'src/components/MotionVisible';
import phoneMarket from 'src/assets/images/howtoplay/phoneMarket2.png';
import {
  boxItemLeftVariant,
  boxItemRightVariant,
} from 'src/variables/animationVariant';
import { StyledFindSneakerDesktop } from './styled';

const FindSneakerDesktop = () => {
  return (
    <StyledFindSneakerDesktop>
      <Row align="middle" justify="space-between" className="container">
        <Col span={10} className="col-text">
          <MotionVisible className="main-title" variants={boxItemLeftVariant}>
            FIND A SNEAKER
          </MotionVisible>
          <MotionVisible className="title" variants={boxItemLeftVariant}>
            From the in-app marketplace
          </MotionVisible>
        </Col>
        <Col span={14} className="col-img">
          <div className="content-wrapper flex-center">
            <MotionVisible className="phone-box" variants={boxItemRightVariant}>
              <img className="phoneImg img-fluid" src={phoneMarket} alt="img" />
            </MotionVisible>
          </div>
        </Col>
      </Row>
    </StyledFindSneakerDesktop>
  );
};

export default FindSneakerDesktop;
