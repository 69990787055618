import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from 'antd';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import topic1 from 'src/assets/images/topics/topicNew1.jpg';
import topic2 from 'src/assets/images/topics/topicNew2.jpeg';
import topic3 from 'src/assets/images/topics/topicMinting.png';
import topic4 from 'src/assets/images/topics/topicHiker2.png';
import topic5 from 'src/assets/images/topics/topicTwitter.png';
import breakpoint from 'src/variables/breakpoint';
import { StyledTopics } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';

const TopicsMobile = (props) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const slideSettings = {
    className: 'center',
    infinite: true,
    centerMode: true,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: breakpoint.lgMin,
        settings: {
          centerPadding: '0',
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '40px',
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '8px',
          height: '8px',
          backgroundColor: '#AAB2C8',
          borderRadius: '50%',
        }}
      />
    ),
  };

  return (
    <StyledTopics className="container" ref={inViewRef} id="topics">
      <div className="topicWrapper">
        <MotionVisible className="topicTitle" variants={animationVariant}>
          <TitleEffect text="Hot Topics" />
        </MotionVisible>
        <MotionVisible className="desc" variants={animationVariant}>
          Keep in your eyes this field in order not to miss important news
        </MotionVisible>
        <MotionVisible
          className="activitiesContainer"
          variants={animationVariant}
        >
          <Image.PreviewGroup>
            <SliderSlick
              settings={slideSettings}
              showArrow={false}
              paddingX="8px"
              paddingY="24px"
            >
              <div className="topic-item">
                <Image src={topic5} preview={false} />
              </div>
              <div className="topic-item">
                <Image src={topic4} preview={false} />
              </div>
              <div className="topic-item">
                <Image src={topic3} preview={false} />
              </div>
              <div className="topic-item">
                <Image src={topic1} preview={false} />
              </div>
              <div className="topic-item">
                <Image src={topic2} preview={false} />
              </div>
            </SliderSlick>
          </Image.PreviewGroup>
        </MotionVisible>
      </div>
    </StyledTopics>
  );
};

export default TopicsMobile;
