import styled from 'styled-components';
import b2eBg from 'src/assets/images/howtoplay/b2eBg.png';

export const StyledDownloadDesktop = styled.div`
  /* height: 700px; */
  position: relative;
  background-image: url(${b2eBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .container {
    max-width: 1216px;
    margin: 0 auto;
    margin-top: 70px;
    width: 100%;
    .col-text {
      .main-title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 78px;
        line-height: 92px;
        letter-spacing: 0.0125em;
        color: #ffffff;
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      }
      .title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 55px;
        letter-spacing: 0.0125em;
        color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      }
      .download-box {
        margin-top: 24px;
        .btn-download {
          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }
    .col-img {
      .box-img {
        padding-left: 42px;
        img {
          max-width: 555px;
        }
      }
    }
  }
`;
