import React from 'react';
import lynnSocialMb from 'src/assets/images/lynnSocialMb.png';
import { StyledSocialMobile } from './styled';
import { useAppStore } from 'src/hooks/useAppStore';
import { socials } from '../socials';
import TitleEffect from 'src/components/TitleEffect';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';

const SocialMobile = ({ fireEvent, onDownload }) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledSocialMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="containerTop">
          <div className="title">
            <TitleEffect text="Join" />
            <TitleEffect text="Our Commnunity" />
          </div>
          <div className="description">
            <span>Connect with Calo Indoor community....</span>
          </div>
        </div>
        <div className="containerBottom">
          <div className="icon-wrapper">
            {socials.map((social, index) => (
              <a
                className="image-social"
                href={social.url}
                target="_blank"
                rel="noreferrer"
                onClick={() => fireEvent(social.key)}
                key={index}
              >
                <img src={social.iconWhite} />
              </a>
            ))}
          </div>
          <div className="download-box flex-center">
            <div className="btn-download" onClick={() => onDownload('ios')}>
              <a
                href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download" onClick={() => onDownload('android')}>
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
          <div className="mainObject">
            <img className="img-fluid" src={lynnSocialMb} />
          </div>
        </div>
      </div>
    </StyledSocialMobile>
  );
};

export default SocialMobile;
