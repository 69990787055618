import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Row, Col } from 'antd';
import {
  boxItemRightVariant,
  animationVariant,
} from 'src/variables/animationVariant';
import { StyledGameplayDesktop } from './styled';
import { gameplayContents } from '../gameplayContent';
import TitleEffect from 'src/components/TitleEffect';
import FilterButtonGameplay from '../FilterButtonGameplay';
import MotionVisible from 'src/components/MotionVisible';

const gameplayOptions = [
  {
    label: 'Overview Story',
    value: 0,
  },
  {
    label: 'Reflex Game',
    value: 1,
  },
  {
    label: 'Fit the Shape',
    value: 2,
  },
  {
    label: 'Jumping Battle',
    value: 3,
  },
];

const GameplayDesktop = (props) => {
  const controls = useAnimation();
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const [index, setIndex] = useState(0);

  function createMarkup(text) {
    return { __html: text };
  }

  function rawHTML(text) {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }

  return (
    <StyledGameplayDesktop id="gameplay" ref={inViewRef}>
      <AnimatePresence>
        <div className="container">
          <Row gutter={24} className="row-content" align="middle">
            <Col md={12} xl={12} className="col-img">
              <motion.img
                key={index}
                initial={{ x: 10, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -10, opacity: 0 }}
                transition={{ duration: 0.1 }}
                className="img-fluid content-img"
                src={gameplayContents[index].img}
              />
            </Col>
            <Col md={12} xl={12} className="col-content">
              <MotionVisible variants={animationVariant}>
                <TitleEffect text="Our" fontSize="80px" className="title" />
                <TitleEffect text="Gameplay" fontSize="80px" />
              </MotionVisible>
              <motion.div
                className="slider-content"
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -50, opacity: 0 }}
                transition={{ duration: 0.5 }}
                key={index}
              >
                <div className="content-box">
                  <div className="content-title">
                    {gameplayContents[index].storyTitle}
                  </div>
                  <div className="content-desc">
                    {rawHTML(gameplayContents[index].storyDesc)}
                  </div>
                </div>
              </motion.div>
              <MotionVisible variants={boxItemRightVariant}>
                <FilterButtonGameplay
                  selected={index}
                  options={gameplayOptions}
                  onSelect={setIndex}
                />
              </MotionVisible>
            </Col>
          </Row>
        </div>
      </AnimatePresence>
    </StyledGameplayDesktop>
  );
};

export default GameplayDesktop;
