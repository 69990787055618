import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import Header from 'src/components/Header/Header';
import Introduction from 'src/components/Introduction/Introduction';
import IntroEventGA from 'src/components/Introduction/IntroEventGA';
import Topics from 'src/components/Topics/Topics';
import AppScreen from 'src/components/AppScreen/AppScreen';
import Gameplay from 'src/components/Gameplay/Gameplay';
import NFTSystem from 'src/components/NFTSystem/NFTSystem';
import Token from 'src/components/Token/Token';
import Roadmap from 'src/components/Roadmap/Roadmap';
import DownloadPromote from 'src/components/DownloadPromote/DownloadPromote';
import Team from 'src/components/Team/Team';
import Social from 'src/components/Social/Social';
import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import { StyledLayout } from './home.styled';
import { Affix, BackTop } from 'antd';
import { scrollTo } from 'src/utils';
import IntroEventMinting from 'src/components/Introduction/IntroEventMinting';

const Home = () => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  useEffect(() => {
    const sectionId = window.location.hash;
    if (sectionId) {
      setTimeout(() => {
        navigateScroll(sectionId.substring(1));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <ThemeProvider theme={themeMode}>
      <StyledLayout>
        {/* <div className="intro-section">
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
          <Introduction />
        </div> */}
        <div className="intro-section-event-ga">
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
          <IntroEventGA />
        </div>
        {/* <div className="intro-section-event-minting">
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
          <IntroEventMinting />
        </div> */}
        <Topics />
        <AppScreen />
        <Gameplay />
        <NFTSystem />
        <Token />
        <Roadmap />
        <div id="team">
          <Team />
        </div>
        <Social />
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default Home;
