import styled from 'styled-components';

export const StyledIntroOPDesktop = styled.div`
  .container {
    max-width: 1190px;
    margin: 0 auto;
    margin-top: 70px;
    width: 100%;
    .box-left,
    .box-right {
      img {
        max-width: 400px;
        @media (max-width: 1024px) {
          max-width: 300px;
        }
      }
    }
    .box-center {
      text-align: center;
      width: 387px;
      .title-wrapper {
        padding-top: 210px;
        .main-title {
          text-align: center;
          line-height: 70px;
        }
      }

      .download-box {
        margin-top: 168px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }
  .scroll-down-wrapper {
    margin-top: 20px;
  }
`;
