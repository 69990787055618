import React from 'react';
import TitleEffect from 'src/components/TitleEffect';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import lynnHTPMb from 'src/assets/images/howtoplay/lynnHTPMb.png';
import { scrollTo } from 'src/utils';
import { StyledIntroHTPMobile } from './styled';

const IntroHTPMobile = () => {
  const navigateScroll = (toId) => scrollTo({ id: toId });

  return (
    <StyledIntroHTPMobile>
      <div className="container flex-center">
        <div className="box-center">
          <div className="box-img text-center">
            <img className="img-fluid" src={lynnHTPMb} alt="main-img" />
          </div>
          <div className="title-wrapper">
            <TitleEffect
              text="BURN TO EARN"
              className="main-title"
              fontSize={'58px'}
            />
            <div
              text="Shaping Your Body"
              className="sub-title"
              fontSize={'28px'}
            >
              Shaping Your Body
            </div>
            <div
              text="With AR Technology"
              className="sub-title"
              fontSize={'28px'}
            >
              With AR Technology
            </div>
          </div>
          <div className="download-box flex-center">
            <div className="btn-download">
              <a
                href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
          <div className="flex-center">
            <div
              className="scroll-down-wrapper"
              onClick={() => navigateScroll('gameplay-htp')}
            >
              <div className="chevron" />
              <div className="chevron" />
              <div className="chevron" />
            </div>
          </div>
        </div>
      </div>
    </StyledIntroHTPMobile>
  );
};

export default IntroHTPMobile;
