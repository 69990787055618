import React from 'react';
import * as ga from 'src/utils/ga';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import SocialDesktop from './SocialDesktop';
import SocialMobile from './SocialMobile';

const Social = (props) => {
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get('utm_source');

  const onClickBtnJoinCommunity = (community) => {
    ga.event({
      action: `click_join_${community}`,
      params: {
        category: `Click Join ${community}`,
        action: `Join ${community}`,
        label: `source-${utm_source}-click-join-${community}`,
        value: '2',
      },
    });
  };

  const onClickDownload = (key) => {
    ga.event({
      action: `download_${key}`,
      params: {
        category: `Download ${key}`,
        action: `click download ${key}`,
        label: `source-${utm_source}-download-${key}`,
        value: `${key}`,
      },
    });
  };

  return (
    <div>
      <ResponsiveLayout
        desktopView={
          <SocialDesktop
            fireEvent={onClickBtnJoinCommunity}
            onDownload={onClickDownload}
            {...props}
          />
        }
        mobileView={
          <SocialMobile
            fireEvent={onClickBtnJoinCommunity}
            onDownload={onClickDownload}
            {...props}
          />
        }
      />
    </div>
  );
};

export default Social;
