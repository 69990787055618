import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Affix, Tooltip } from 'antd';
import classNames from 'classnames';
import { useAppStore } from 'src/hooks/useAppStore';
import { scrollTo } from 'src/utils';
import caloIndoorLogo from 'src/assets/images/caloIndoorLogo.svg';
import iconMenuDark from 'src/assets/images/iconMenuDark.png';
import iconMenuLight from 'src/assets/images/iconMenuLight.png';
import iconCopyAddress from 'src/assets/images/iconCopyAddress.svg';
import certikDark from 'src/assets/images/logoCertik.svg';
import divider from 'src/assets/images/divider.svg';
import { StyledHeader, StyledDrawer, ItemLink, StyledTokenAddress } from './styled';
import Button from 'src/components/Button';
import { caloAddress, ifitAddress } from 'src/variables/calo';
import { truncateAddress } from 'src/utils/text';

const HeaderMobile = (props) => {
  const [open, setOpen] = useState(false);
  const { theme, toggleTheme, id } = props;
  const [appStore, updateAppStore] = useAppStore();
  const { isDarkMode } = appStore;
  const history = useHistory();
  const { pathname } = useLocation();

  const isHomePage = pathname === '/';

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const onNavigate = (section) => {
    setOpen(false);
    navigateScroll(section);
  };

  const onNavigatePage = (id) => {
    if (isHomePage) {
      onNavigate(id);
    } else {
      history.push(`/#${id}`);
    }
  };

  const [copied, setCopied] = useState(false);

  const copyAddress = (address) => {
    setCopied(true);
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address);
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('input');
      ele.value = address;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
    }
  };

  return (
    <StyledHeader id={id}>
      <Affix offsetTop={0}>
        <StyledTokenAddress>
          <div className='box-contract'>
            <div className="contract">
              <span className="calo"> CALO </span>
              <a
                href={`https://bscscan.com/token/${caloAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="address">
                  {truncateAddress(caloAddress, 12, '...')}
                </span>
              </a>
              <Tooltip
                title={copied ? 'Copied' : 'Copy'}
                onVisibleChange={(visible) => {
                  if (!visible) {
                    setCopied(false);
                  }
                }}
                placement="bottom"
              >
                <img
                  className="copy link"
                  src={iconCopyAddress}
                  onClick={() => copyAddress(caloAddress)}
                  width="24px"
                />
              </Tooltip>
            </div>
            <div className="contract">
              <span className="calo ifit"> IFIT </span>
              <a
                href={`https://bscscan.com/token/${ifitAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="address">
                  {truncateAddress(ifitAddress, 12, '...')}
                </span>
              </a>
              <Tooltip
                title={copied ? 'Copied' : 'Copy'}
                onVisibleChange={(visible) => {
                  if (!visible) {
                    setCopied(false);
                  }
                }}
                placement="bottom"
              >
                <img
                  className="copy link"
                  src={iconCopyAddress}
                  onClick={() => copyAddress(ifitAddress)}
                  width="24px"
                />
              </Tooltip>
            </div>
          </div>
          <div className="divider">
            <img src={divider} alt="divider" />
          </div>
          <a
            href="https://www.certik.com/projects/calo"
            target="_blank"
            rel="noreferrer"
          >
            <div className="auditedBox">
              <img src={certikDark} alt="certik" />
            </div>
          </a>
        </StyledTokenAddress>
      </Affix>
      <div className="headerWrapper flex-center">
        <div className="btn-docs">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.indoor.fun/"
          >
            <Button size="sm" buttonType="secondary">
              DOCS
            </Button>
          </a>
        </div>
        <a href="/" className="navBrand">
          <img className="navLogo img-fluid" src={caloIndoorLogo} />
        </a>
        <div className="menuIcon" onClick={toggleDrawer}>
          <img
            className="navBar"
            src={isDarkMode ? iconMenuDark : iconMenuLight}
          />
        </div>
      </div>
      <StyledDrawer
        mask
        width={280}
        zIndex={1000}
        closable={false}
        placement={'left'}
        onClose={toggleDrawer}
        visible={open}
      >
        <div className="navMenuContainer">
          <ul className="navMenu">
            <li onClick={() => onNavigatePage('home')}>
              <ItemLink
                className={classNames('navLink', {
                  active: pathname === '/' || pathname === '/#home',
                })}
              >
                Home
              </ItemLink>
            </li>
            <li className="navLink" onClick={() => onNavigatePage('gameplay')}>
              Gameplay
            </li>
            <li
              className="navLink"
              onClick={() => onNavigatePage('nft-system')}
            >
              NFTs System
            </li>
            <li className="navLink" onClick={() => onNavigatePage('team')}>
              Team
            </li>
            <li className="navLink">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.indoor.fun/"
              >
                Whitepaper
              </a>
            </li>
            <Link to="/one-paper">
              <ItemLink
                className={classNames('navLink', {
                  active: pathname === '/one-paper',
                })}
              >
                Onepaper
              </ItemLink>
            </Link>
            <Link to="/how-to-play">
              <ItemLink
                className={classNames('navLink', {
                  active: pathname === '/how-to-play',
                })}
              >
                How to play
              </ItemLink>
            </Link>
          </ul>
        </div>
        <div className="btn-group">
          <a
            href="https://dapps.caloverse.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Button size="sm">Enter Dapp</Button>
          </a>
          <a>
            <Tooltip title="Coming soon" placement="bottom">
              <Button size="sm">Blog</Button>
            </Tooltip>
          </a>
        </div>
      </StyledDrawer>
    </StyledHeader>
  );
};

export default HeaderMobile;
