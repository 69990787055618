import styled from 'styled-components';

export const StyledTokenMetricsMobile = styled.section`
  padding: 80px 0;
  .container {
    margin: auto;
    position: relative;
    .header {
      text-align: center;
      padding-bottom: 8px;
      .t-1 {
        line-height: 32px;
      }
    }
    .token-desc {
      padding: 40px 0 30px 0;
      max-width: 328px;
      margin: 0 auto;
      .desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
      }
      .contract-box {
        padding: 24px 20px 24px 0;
        margin: 0 auto;
        max-width: 327px;
        @media (max-width: 320px) {
          max-width: 297px;
        }
        text-align: left;
        .label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          position: relative;
          z-index: 1;
          .address {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
            padding-left: 8px;
          }
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-10deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-10deg);
        }
        .icon-copy {
          position: relative;
          z-index: 1;
          cursor: pointer;
        }
      }
    }
    .box-img {
      img {
        max-width: 259px;
      }
    }
  }
`;
