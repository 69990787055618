import styled from 'styled-components';
import boxPolygon from 'src/assets/images/boxPolygon.png';
import roadmapBg from 'src/assets/images/roadmapBg2.png';

export const StyledRoadmapV2Desktop = styled.div`
  padding: 90px 0;
  position: relative;
  background-image: url(${roadmapBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .container {
    padding: 0;
    max-width: 100%;
    margin: 0 auto;
    .title {
      text-align: center;
    }
    .roadmap-wrapper {
      padding-top: 40px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .wrapper {
      margin-top: 32px;
      .ant-col {
        margin-bottom: -64px;
      }
      .roadmap-item {
        background-image: url(${boxPolygon});
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        width: 100%;
        height: 280px;
        .content {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          ul {
            width: 100%;
            padding: 0 0 32px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #ffffff;
            margin: 0;
            /* list-style: none; */
          }
        }
        .milestone {
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translate(-50%, 0);
          margin-top: 24px;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.0125em;
          background: linear-gradient(
            334.82deg,
            #f72f69 -6.69%,
            #ff2d33 16.13%,
            #ff5122 38.23%,
            #fceb25 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
`;
