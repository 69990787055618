import React from 'react';
import phoneMarket from 'src/assets/images/howtoplay/phoneMarket2.png';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledFindSneakerMobile } from './styled';

const FindSneakerMobile = () => {
  return (
    <StyledFindSneakerMobile>
      <div className="container">
        <div span={10} className="col-text">
          <MotionVisible className="main-title" variants={animationVariant}>
            FIND A SNEAKER
          </MotionVisible>
          <MotionVisible className="title" variants={animationVariant}>
            From the in-app marketplace
          </MotionVisible>
        </div>
        <div span={14} className="col-img">
          <div className="content-wrapper flex-center">
            <MotionVisible className="phone-box" variants={animationVariant}>
              <img className="phoneImg img-fluid" src={phoneMarket} alt="img" />
            </MotionVisible>
          </div>
        </div>
      </div>
    </StyledFindSneakerMobile>
  );
};

export default FindSneakerMobile;
