import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import RoadmapDesktop from './RoadmapDesktop';
import RoadmapMobile from './RoadmapMobile';

const Roadmap = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<RoadmapDesktop {...props} />}
      mobileView={<RoadmapMobile {...props} />}
    />
  );
};

export default Roadmap;
