import { Drawer } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  background: rgba(20, 20, 20, 0.5);
  .headerWrapper {
    text-align: center;
    position: relative;
    margin-top: 20px;
    padding: 24px 0;
    z-index: 1;
    max-width: 500px;
    margin: 0 auto;

    .navLogo {
      max-width: 128px;
    }
    .btn-docs {
      position: absolute;
      top: 45px;
      left: 20px;
    }
    .menuIcon {
      position: absolute;
      top: 45px;
      right: 20px;
      z-index: 100;
      color: #ffffff;
      font-size: 18px;
    }
  }
`;

export const StyledTokenAddress = styled.div`
  min-height: 73px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(20, 20, 20, 0.5);
  .contract {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0.0125em;
    &:first-child {
      padding-bottom: 6px;
    }
    .calo {
      padding-right: 14px;
      font-weight: 600;
      color: #f67d3e;
      flex: 1;
    }
    .ifit {
      color: #fd7d74 !important;
    }
    .address {
      font-weight: 600;
      color: #b3b4cd;
    }
    .copy {
      margin-left: 12px;
    }
  }
  .divider {
    margin-left: 16px;
    margin-right: 16px;
  }
  .auditedBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background: linear-gradient(
      210.66deg,
      rgba(175, 198, 255, 0.157036) 60.09%,
      rgba(255, 255, 255, 0) 93.19%
    );
    border-radius: 16px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      border: 1px solid #afbffa;
      border-radius: 16px;
    }
  }
  @media (max-width: 428px) {
    .contract {
      font-size: 12px;
    }
  }
  @media (max-width: 320px) {
    .contract {
      font-size: 12px;
    }
    .divider {
      margin-left: 8px;
      margin-right: 8px;
    }
    .auditedBox {
      padding: 8px;
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background: #0b1c41;
  }
  .navMenuContainer {
    margin-right: 64px;
    .navMenu {
      list-style: none;
      margin: 0;
      padding: 0;
      .navLink {
        color: #999999;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 24px;
        display: block;
        a {
          color: #999999;
        }
      }
    }
  }

  .btn-group {
    a {
      padding-bottom: 16px;
      display: block;
    }
  }
`;

export const ItemLink = styled.a`
  color: #999999;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  display: block;
  &:hover,
  &.active {
    color: #fd7d74 !important;
  }
`;
