import styled from 'styled-components';
import introBannerBgMb from 'src/assets/images/introduction/mobile/introBgMb.png';

export const StyledIntroductionMobile = styled.div`
  max-width: 900px;
  margin: 0 auto;
  overflow: visible;
  width: 100%;
  background-image: url(${introBannerBgMb});
  background-position: center;
  background-size: 100% 100%;
  .container {
    padding: 40px 16px 70px 16px;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    .box-center {
      position: relative;
      text-align: center;
      width: 100%;

      .main-title {
        line-height: 42px;
        text-align: center;
      }
      .sub-title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: #ffffff;
        padding-top: 8px;
      }
      .desc {
        padding: 24px 8px;
        margin: 0 auto;
        max-width: 311px;
        text-align: left;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
          color: #ffffff;
          position: relative;
          z-index: 1;
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-10deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-10deg);
        }
      }
      .box-img {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        text-align: center;
        .token {
          position: absolute;
          top: 33%;
          left: 28%;
          transform: translate(-50%, -50%);
          width: 120px;
          @media (min-width: 425px) {
            width: 135px;
          }
          @media (min-width: 768px) {
            width: 150px;
          }
        }
      }
    }
    .download-box {
      flex-direction: column;
      margin: 24px 0;
      .btn-download {
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
    .scroll-down-wrapper {
        cursor: pointer;
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: 16px;
        margin-left: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        .chevron {
          position: absolute;
          width: 28px;
          height: 8px;
          opacity: 0;
          transform: scale3d(0.5, 0.5, 0.5);
          animation: move 3s ease-out infinite;
        }

        .chevron:first-child {
          animation: move 3s ease-out 1s infinite;
        }

        .chevron:nth-child(2) {
          animation: move 3s ease-out 2s infinite;
        }

        .chevron:before,
        .chevron:after {
          content: ' ';
          position: absolute;
          top: 0;
          height: 100%;
          width: 51%;
          background: #fff;
        }

        .chevron:before {
          left: 0;
          transform: skew(0deg, 30deg);
        }

        .chevron:after {
          right: 0;
          width: 50%;
          transform: skew(0deg, -30deg);
        }

        @keyframes move {
          25% {
            opacity: 1;
          }
          33% {
            opacity: 1;
            transform: translateY(30px);
          }
          67% {
            opacity: 1;
            transform: translateY(40px);
          }
          100% {
            opacity: 0;
            transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
          }
        }
      }
  }
`;
