import styled from 'styled-components';
import nftSystemBgMb from 'src/assets/images/nft-system/nftSystemBgMb.png';

export const StyledNFTSystemMobile = styled.section`
  padding: 40px 0;
  background-image: url(${nftSystemBgMb});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .container {
    padding: 0 16px;
    max-width: 450px;
    margin: 0 auto;
    .title {
      font-family: 'Airstrike';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 35px;
      text-align: center;
      color: #e9e9ec;
    }
    .desc {
      padding-top: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
    }
    .main-img {
      text-align: center;
      margin-top: 32px;
    }
    .items {
      flex-wrap: wrap;
      margin-top: -32px;
      .box-item {
        width: 140px;
      }
    }
  }
`;
