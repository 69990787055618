import styled from 'styled-components';
import appScreenBg from 'src/assets/images/appScreenBg.png';

export const StyledAppScreenDesktop = styled.div`
  padding: 100px 0;

  background-image: url(${appScreenBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .container {
    padding: 0;
    max-width: 1216px;
    margin: 0 auto;
    .title {
      text-align: center;
      margin-bottom: 24px;
    }
    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
    }
    .img-wrapper {
      text-align: center;
      padding-top: 72px;
      .img-a {
        max-width: 800px;
        position: relative;
        z-index: 2;
        @media (max-width: 1440px) {
          /* max-width: 714px; */
        }
        @media (max-width: 1024px) {
          max-width: 511px;
        }
      }
    }
  }
`;
