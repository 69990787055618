import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Affix, Tooltip } from 'antd';
import classNames from 'classnames';
import Button from 'src/components/Button';
import caloIndoorLogo from 'src/assets/images/caloIndoorLogoWhite.svg';
import iconCopyAddress from 'src/assets/images/iconCopyAddress.svg';
import certikDark from 'src/assets/images/logoCertik.svg';
import divider from 'src/assets/images/divider.svg';
import { scrollTo } from 'src/utils';
import { ItemLink, StyledHeader, StyledTokenAddress } from './styled';
import { caloAddress, ifitAddress } from 'src/variables/calo';
import { truncateAddress } from 'src/utils/text';

const HeaderDesktop = (props) => {
  const { id } = props;
  const history = useHistory();
  const { pathname } = useLocation();

  const isHomePage = pathname === '/';

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  const onNavigatePage = (id) => {
    if (isHomePage) {
      navigateScroll(id);
    } else {
      history.push(`/#${id}`);
    }
  };

  const [copied, setCopied] = useState(false);

  const copyAddress = (address) => {
    setCopied(true);
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address);
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('input');
      ele.value = address;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
    }
  };

  return (
    <StyledHeader id={id} isHomePage={isHomePage}>
      <Affix offsetTop={16}>
        <StyledTokenAddress>
          <div className="contract">
            <span className="calo"> CALO </span>
            <a
              href={`https://bscscan.com/token/${caloAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="address">
                {truncateAddress(caloAddress, 18, '...')}
              </span>
            </a>
            <Tooltip
              title={copied ? 'Copied' : 'Copy'}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setCopied(false);
                }
              }}
              placement="bottom"
            >
              <img
                className="copy link"
                src={iconCopyAddress}
                onClick={() => copyAddress(caloAddress)}
                width="24px"
              />
            </Tooltip>
          </div>
          <div className="contract">
            <span className="calo ifit"> IFIT </span>
            <a
              href={`https://bscscan.com/token/${ifitAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="address">
                {truncateAddress(ifitAddress, 18, '...')}
              </span>
            </a>
            <Tooltip
              title={copied ? 'Copied' : 'Copy'}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setCopied(false);
                }
              }}
              placement="bottom"
            >
              <img
                className="copy link"
                src={iconCopyAddress}
                onClick={() => copyAddress(ifitAddress)}
                width="24px"
              />
            </Tooltip>
          </div>
          <div className="divider">
            <img src={divider} alt="divider" />
          </div>
          <a
            href="https://www.certik.com/projects/calo"
            target="_blank"
            rel="noreferrer"
          >
            <div className="auditedBox">
              <span>Audited by</span>
              <img src={certikDark} alt="certik" />
              <div className="status">Verified</div>
            </div>
          </a>
        </StyledTokenAddress>
      </Affix>

      <nav className="nav-container">
        <div className="nav-wrapper">
          <div className="nav-header">
            <Link to="/" className="nav-brand">
              <img className="nav-logo img-fluid" src={caloIndoorLogo} />
            </Link>
          </div>
          <div className="menu-center-container">
            <div className="menu-container">
              <ul className="nav-menu">
                <ItemLink
                  className="nav-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.indoor.fun/"
                >
                  Whitepaper
                </ItemLink>
                <Link to="/one-paper">
                  <ItemLink
                    className={classNames('nav-link', {
                      active: pathname === '/one-paper',
                    })}
                  >
                    Onepaper
                  </ItemLink>
                </Link>
                <Link to="/how-to-play">
                  <ItemLink
                    className={classNames('nav-link', {
                      active: pathname === '/how-to-play',
                    })}
                  >
                    How to play
                  </ItemLink>
                </Link>
                <ItemLink
                  className="nav-link link"
                  onClick={() => onNavigatePage('gameplay')}
                >
                  Gameplay
                </ItemLink>
                <ItemLink
                  className="nav-link link"
                  onClick={() => onNavigatePage('team')}
                >
                  Team
                </ItemLink>
              </ul>
            </div>
          </div>
          <div className="menu-right-container">
            <a
              href="https://dapps.caloverse.io/"
              target="_blank"
              rel="noreferrer"
            >
              <Button buttonType="secondary">Enter Dapp</Button>
            </a>
          </div>
        </div>
      </nav>
    </StyledHeader>
  );
};

export default HeaderDesktop;
