import React from 'react';
import sneakerDetail from 'src/assets/images/howtoplay/nftDetail.png';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledNFTDetailDesktop } from './styled';

const NFTDetailDesktop = (props) => {
  return (
    <StyledNFTDetailDesktop>
      <div className="container">
        <MotionVisible variants={animationVariant} className="title">
          NFT DETAIL PAGE
        </MotionVisible>
        <div className="content-wrapper flex-center">
          <div className="nft-detail">
            <MotionVisible variants={animationVariant}>
              <img className="img-fluid" src={sneakerDetail} alt="img-detail" />
            </MotionVisible>
          </div>
        </div>
      </div>
    </StyledNFTDetailDesktop>
  );
};

export default NFTDetailDesktop;
