import styled from 'styled-components';
import fsBg from 'src/assets/images/howtoplay/fsBg.png';

export const StyledFindSneakerDesktop = styled.div`
  padding: 104px 0 0 0;
  background-image: url(${fsBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .container {
    width: 100%;
    max-width: 1216px;
    margin: auto;
    .col-text {
      .main-title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 78px;
        line-height: 92px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #ffffff;
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      }
      .title {
        font-family: 'Protector';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #ffffff;
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      }
      @media screen and (max-width: 1024px) {
        .main-title {
          font-size: 60px;
        }
        .title {
          font-size: 28px;
        }
      }
    }
  }
`;
