import styled from 'styled-components';
import cardTokenBg from 'src/assets/images/token/cardTokenBg.png';

export const StyledTokenMobile = styled.section`
  margin: 46px 0;
  position: relative;
  z-index: 3;

  .container {
    max-width: 900px;
    margin: auto;
    padding-top: 32px;
    .box-top {
      position: relative;
      text-align: center;
      .title {
        font-size: 40px;
        line-height: 35px;
        color: #e9e9ec;
        padding-bottom: 32px;
      }
      .description {
        max-width: 328px;
        margin: auto;
        margin-top: -150px;
        padding-top: 32px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #e9e9ec;
      }
      .indoor-logo {
        max-width: 528px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
    .arrow {
      padding-top: 24px;
    }
    .box-token {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding-top: 8px;
      @media (min-width: 567px) {
        max-width: 400px;
        margin: 0 auto;
      }
      .name {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.0125em;
        color: #f45c0e;
        margin-bottom: 8px;
        text-align: center;
      }
      .desc {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0125em;
        color: #e9e9ec;
        text-align: center;
        padding-top: 8px;
      }
      .box-item {
        background: url(${cardTokenBg});
        background-size: 100% 100%;
        box-shadow: inset 0px 8px 17px rgba(255, 255, 255, 0.25);
        padding: 40px 16px 20px 16px;
        border: 2px solid #3263d6;
        filter: drop-shadow(4px 4px 16px rgba(215, 55, 164, 0.4));
        border-radius: 8px;
      }
      .box-left {
        .coinLogo {
          width: 110px;
          margin-bottom: 16px;
          img {
            width: 100%;
          }
        }
      }
      .box-right {
        .coinLogo {
          width: 110px;
          margin-bottom: 16px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
`;
