/* eslint-disable indent */
import styled from 'styled-components';
import { Modal } from 'antd';
import modalDetailBgMb from 'src/assets/images/modalDetailBgMb.png';

const StyledModalDetailSneaker = styled(Modal)`
  width: 330px !important;
  height: 459px;
  @media (max-width: 320px) {
    width: 297px !important;
    height: unset;
  }
  background-image: url(${modalDetailBgMb});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .ant-modal-content {
    background: transparent;
    height: 100%;
    .ant-modal-close {
      top: 0 !important;
      right: -10px !important;
      .ant-modal-close-x {
        width: 30px;
        height: 30px;
        img {
          width: 30px;
          height: 30px;
          display: block;
        }
      }
    }
    .ant-modal-body {
      padding: 48px 28px;
    }
  }
  .ant-modal-body {
    height: 100%;
  }
  .detail-content {
    height: 100%;
    text-align: center;
    img {
      width: 240px;
      padding-bottom: 32px;
    }
    .title {
      line-height: 31px;
      color: #fff;
    }
    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #e9e9ec;
      padding-top: 16px;
      text-align: left;
    }
  }
`;

export { StyledModalDetailSneaker };
