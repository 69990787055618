import React from 'react';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import tokenomic from 'src/assets/images/onepaper/tokenomic3.png';
import MotionVisible from 'src/components/MotionVisible';
import TitleEffect from 'src/components/TitleEffect';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledTokenomicDesktop } from './styled';

const TokenomicsDesktop = () => {
  return (
    <StyledTokenomicDesktop id="tokenomics">
      <div className="container">
        <MotionVisible className="header" variants={animationVariant}>
          <TitleEffect className="t-2" text="TOKENOMICS" fontSize="70px" />
          <img className="text-center" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible variants={animationVariant} className="content-box">
          <img className="img-fluid text-center" src={tokenomic} />
        </MotionVisible>
      </div>
    </StyledTokenomicDesktop>
  );
};

export default TokenomicsDesktop;
