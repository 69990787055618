import styled, { css } from 'styled-components';

const sizes = {
  sm: css`
    padding: 10px 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
  `,
  md: css`
    padding: 16px 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    color: #ffffff;
  `,
};

const buttonTypes = {
  primary: css`
    background: linear-gradient(180deg, #FFBAA1 0%, #FD3F46 100%);
    color: #ffffff;
  `,
  secondary: css`
    background: transparent;
    color: #fd7d74 !important;
    border: 1.5px solid #fd7d74;
  `,
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  box-sizing: border-box;
  ${({ buttonType }) => buttonTypes[buttonType]};
  ${({ size }) => sizes[size]};
  width: ${({ width }) => (width === 'fit' ? 'fit-content' : '100%')};
  border-radius: 36px !important;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
  cursor: pointer;

  &:hover {
    &:not([disabled]),
    &:not(:disabled) {
      opacity: 0.7;
    }
  }
`;

export default StyledButton;
