import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

function MotionVisible({ children, onSetView, ...props }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 100);
      onSetView(inView);
    }
  }, [controls, inView, onSetView]);

  return (
    <motion.div {...props} ref={ref} initial="hidden" animate={controls}>
      {children}
    </motion.div>
  );
}
MotionVisible.propTypes = {
  children: PropTypes.any,
  onSetView: PropTypes.func,
};

MotionVisible.defaultProps = {
  onSetView: () => {},
};

export default MotionVisible;
