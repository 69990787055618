import React from 'react';
import appScreenMb from 'src/assets/images/appScreenMb2.png';
import { StyledAppScreenMobile } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import { animationVariant } from 'src/variables/animationVariant';
import MotionVisible from 'src/components/MotionVisible';

const AppScreenMobile = () => {
  return (
    <StyledAppScreenMobile>
      <div className="container">
        <div className="box-content">
          <MotionVisible className="title" variants={animationVariant}>
            <TitleEffect text="Let's join us to play" fontSize="32px" />
          </MotionVisible>
          <MotionVisible className="desc" variants={animationVariant}>
            Calo Indoor is built on the AR gameplay system base with an NFT
            Sneaker and its function
          </MotionVisible>
          <MotionVisible className="img-wrapper" variants={animationVariant}>
            <img
              className="img-app img-fluid"
              src={appScreenMb}
              alt="appScreen"
            />
          </MotionVisible>
        </div>
      </div>
    </StyledAppScreenMobile>
  );
};

export default AppScreenMobile;
