import React, { Suspense, useMemo, useState } from 'react';
import classNames from 'classnames';
// import { Canvas } from '@react-three/fiber';
// import { useLoader } from '@react-three/fiber';
// import { OrbitControls, useProgress } from '@react-three/drei';
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import MotionVisible from 'src/components/MotionVisible';
import slipper from 'src/assets/images/howtoplay/sneaker/slipper.png';
import pumper from 'src/assets/images/howtoplay/sneaker/pumper.png';
import clever from 'src/assets/images/howtoplay/sneaker/clever.png';
import unknown from 'src/assets/images/howtoplay/sneaker/unknown.png';
import {
  animationVariant,
  boxItemLeftVariant,
} from 'src/variables/animationVariant';
import { BubbleInfo, SneakerTab, StyledNFTDetailDesktop } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import { renderImgModel, renderInfoDesc, renderInfoName } from '../method';

// const Scene = ({ type }) => {
//   const materials = useLoader(MTLLoader, `models/${type}.mtl`);
//   const obj = useLoader(OBJLoader, `models/${type}.obj`, (loader) => {
//     materials.preload();
//     loader.setMaterials(materials);
//   });
//   const copiedScene = useMemo(() => obj.clone(), [obj]);

//   return <primitive object={copiedScene} scale={3.2} />;
// };

const SneakerNFTDesktop = () => {
  const [selected, setSelected] = useState('slipper');

  return (
    <StyledNFTDetailDesktop>
      <div className="container">
        <MotionVisible variants={animationVariant} className="title">
          Sneaker NFTs
        </MotionVisible>
        <MotionVisible variants={animationVariant} className="description">
          Different types of shoes give different access to certain games of the
          system
        </MotionVisible>
        <div className="content-wrapper">
          <div className="col-tabs flex-center">
            <div className="item-tabs flex-between">
              <SneakerTab
                className={classNames({
                  active: selected === 'slipper',
                })}
                onClick={() => setSelected('slipper')}
                key="slipper"
              >
                <div className="wrapper">
                  <img className="img-fluid" src={slipper} alt="slipper" />
                  <span className="name flex-center">Slipper</span>
                </div>
              </SneakerTab>
              <SneakerTab
                className={classNames({
                  active: selected === 'pumper',
                })}
                onClick={() => setSelected('pumper')}
                key="pumper"
              >
                <div className="wrapper">
                  <img className="img-fluid" src={pumper} alt="pumper" />
                  <span className="name flex-center">Pumper</span>
                </div>
              </SneakerTab>
              <SneakerTab
                className={classNames({
                  active: selected === 'clever',
                })}
                onClick={() => setSelected('clever')}
                key="clever"
              >
                <div className="wrapper">
                  <img className="img-fluid" src={clever} alt="clever" />
                  <span className="name flex-center">Clever</span>
                </div>
              </SneakerTab>
              <SneakerTab
                className={classNames({
                  active: selected === 'unknown',
                })}
                onClick={() => setSelected('unknown')}
                key="unknown"
              >
                <div className="wrapper text-center unknown">
                  <img className="img-fluid" src={unknown} alt="unknown" />
                </div>
              </SneakerTab>
            </div>
          </div>
          <MotionVisible
            variants={boxItemLeftVariant}
            className="nft-model"
            key={selected}
          >
            {/* <Canvas
              camera={{ fov: 40, near: 1, far: 100, position: [20, 5, 1] }}
            >
              <ambientLight />
              <spotLight
                intensity={2}
                position={[40, 50, 50]}
                shadow-bias={-0.00005}
                penumbra={1}
                angle={Math.PI / 6}
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                castShadow
              />
              <Suspense fallback={<Loader />}>
                <Scene type={selected} />
                <OrbitControls enableZoom={false} />
              </Suspense>
            </Canvas> */}
            <div className="img-model">
              <img className="img-fluid" src={renderImgModel(selected)} />
            </div>
            <BubbleInfo>
              <div className="wrapper flex-center">
                <TitleEffect text={renderInfoName(selected)} fontSize="40px" />
                <div className="desc">{renderInfoDesc(selected)}</div>
              </div>
            </BubbleInfo>
          </MotionVisible>
        </div>
      </div>
    </StyledNFTDetailDesktop>
  );
};

export default SneakerNFTDesktop;
