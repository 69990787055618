import styled from 'styled-components';

export const StyledGameReleased = styled.section`
  .container {
    max-width: 1216px;
    margin: 0 auto;
    padding-top: 64px;
    width: 100%;
    @media (max-width: 568px) {
      /* padding: 0 16px; */
    }
    @media (min-width: 767px) {
      .header-lg {
        text-align: center;
        display: block;
        .desc {
          max-width: 595px;
          margin: 0 auto;
          padding: 16px 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
      }
      .header-sm {
        text-align: center;
        display: none;
      }
    }
    @media (max-width: 767px) {
      .header-lg {
        text-align: center;
        display: none;
      }
      .header-sm {
        text-align: center;
        display: block;
        img {
          margin-top: 16px;
        }
        .desc {
          padding: 16px 16px 0 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
    .items {
      padding-top: 95px;
      padding-bottom: 190px;
      @media (max-width: 767px) {
        padding-top: 56px;
        padding-bottom: 82px;
      }
      .game-item {
        img {
          max-width: 384px;
        }
      }
      .slick-dots {
        bottom: -50px;
      }
    }
  }
`;
