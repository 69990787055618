import React, { useEffect, useState } from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import TopicsDesktop from './TopicsDesktop';
import TopicsMobile from './TopicsMobile';

const Topics = (props) => {
  return (
    <div>
      <ResponsiveLayout
        desktopView={
          <TopicsDesktop {...props} />
        }
        mobileView={
          <TopicsMobile {...props} />
        }
      />
    </div>
  );
};

export default Topics;
