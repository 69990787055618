import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import nftSystemMb from 'src/assets/images/nft-system/nftSystemMb.png';
import boxPumperMb from 'src/assets/images/nft-system/boxPumperMb2.png';
import boxSlipperMb from 'src/assets/images/nft-system/boxSlipperMb2.png';
import boxCleverMb from 'src/assets/images/nft-system/boxCleverMb2.png';
import boxUnknownMb from 'src/assets/images/nft-system/boxUnknownMb2.png';
import { StyledNFTSystemMobile } from './styled';
import ModalDetailSneakerMb from 'src/components/Modals/ModalDetailSneakerMb';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';

const NFTSystemMobile = () => {
  const [open, setOpen] = useState(false);
  const [keyDetail, setKeyDetail] = useState('');

  const controls = useAnimation();
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  const onOpenDetail = (key) => {
    setOpen(true);
    setKeyDetail(key);
  };

  const toggleOpen = () => {
    setOpen(false);
    setKeyDetail('');
  };

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledNFTSystemMobile id="nft-system" ref={inViewRef}>
      <ModalDetailSneakerMb
        selected={keyDetail}
        visible={open}
        onCancel={toggleOpen}
      />
      <div className="container">
        <MotionVisible className="title" variants={animationVariant}>
          <TitleEffect text="NFTs System" />
        </MotionVisible>
        <MotionVisible className="desc" variants={animationVariant}>
          In Calo Indoor, players need to equip the appropriate NFT Sneaker
          according to each gameplay.
        </MotionVisible>
        <MotionVisible className="row-content" variants={animationVariant}>
          <div className="main-img">
            <motion.img
              className="img-fluid"
              src={nftSystemMb}
              alt="nft-system"
            />
          </div>
          <div className="items flex-around">
            <div className="box-item" onClick={() => onOpenDetail('SLIPPER')}>
              <motion.img className="img-fluid" src={boxSlipperMb} />
            </div>
            <div className="box-item" onClick={() => onOpenDetail('PUMPER')}>
              <motion.img className="img-fluid" src={boxPumperMb} />
            </div>
            <div className="box-item" onClick={() => onOpenDetail('CLEVER')}>
              <motion.img className="img-fluid" src={boxCleverMb} />
            </div>
            <div className="box-item" onClick={() => onOpenDetail('TRAINER')}>
              <motion.img className="img-fluid" src={boxUnknownMb} />
            </div>
          </div>
        </MotionVisible>
      </div>
    </StyledNFTSystemMobile>
  );
};

export default NFTSystemMobile;
