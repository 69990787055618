import styled from 'styled-components';

export const StyledIntroShoeMintingDesktop = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  overflow: visible;
  width: 100%;
  position: relative;

  .container {
    position: relative;
    overflow: visible !important;
    height: 100%;
    margin: 18px 0;
    .col-img {
      height: 100%;
      width: 100%;
      .box-img {
        position: relative;
        bottom: -42px;
        max-width: 590px;
        @media (max-width: 1024px) {
          max-width: 450px;
          bottom: -66px;
        }
      }
    }
    .col-content {
      text-align: center;
      padding-right: 60px !important;
      .sub-title {
        width: 636px;
      }
      @media (max-width: 1024px) {
        padding-right: 0 !important;
        .sub-title {
          width: 400px;
        }
        .main-title {
          width: 500px;
        }
        .desc {
          font-size: 24px;
        }
      }

      .download-box {
        padding: 40px 0 42px 0;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .btn-bottom {
        button {
          width: 170px;
        }
      }
    }
    .token-corner {
      position: absolute;
      right: -50px;
      top: 100px;
      width: 95px;
    }
    .ifit-token {
      position: absolute;
      left: 50px;
      top: 100px;
      width: 174px;
      @media (max-width: 1024px) {
        width: 124px;
      }
    }
  }
`;
