import styled from 'styled-components';
import nftDetailBg from 'src/assets/images/howtoplay/nftDetailBg.png';

export const StyledNFTDetailDesktop = styled.div`
  padding: 80px 0;
  background-image: url(${nftDetailBg});
  background-size: cover;
  background-repeat: round;
  .container {
    max-width: 1076px;
    margin: auto;
    padding: 0;
    .title {
      font-family: 'Protector';
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 92px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
    }
    .content-wrapper {
      margin: 60px 0;
    }
  }
`;
