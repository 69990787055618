import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { nonAnimatingContainer } from 'src/variables/animationVariant';
import roadmap from 'src/assets/images/roadmap4.svg';
import { StyledRoadmapV2Desktop } from './styled';
import TitleEffect from 'src/components/TitleEffect';

const RoadmapDesktop = () => {
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const controls = useAnimation();

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '10%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledRoadmapV2Desktop ref={inViewRef} id="roadmap">
      <motion.div
        className="container"
        initial="hidden"
        animate={controls}
        variants={nonAnimatingContainer}
      >
        <motion.div className="title" variants={animationVariant}>
          <TitleEffect text="Calo Roadmap" fontSize="100px" />
        </motion.div>
        <motion.div className="roadmap-wrapper" variants={animationVariant}>
          <img src={roadmap} />
        </motion.div>
      </motion.div>
    </StyledRoadmapV2Desktop>
  );
};

export default RoadmapDesktop;
