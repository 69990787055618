import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import lynnStart from 'src/assets/images/howtoplay/lynnStartMb.png';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import { StyledDownloadMobile } from './styled';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';

const DownloadMobile = () => {
  return (
    <StyledDownloadMobile>
      <div className="container">
        <Row align="middle" justify="space-between">
          <Col span={24} className="col-text">
            <MotionVisible className="main-title" variants={animationVariant}>
              Let’s Burn To Earn
            </MotionVisible>
            <MotionVisible className="title" variants={animationVariant}>
              With CALO INDOOR
            </MotionVisible>
            <div className="download-box flex-center">
              <div className="btn-download">
                <a
                  href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnIos} alt="download" />
                </a>
              </div>
              <div className="btn-download">
                <a
                  href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnAndroid} alt="download" />
                </a>
              </div>
            </div>
          </Col>
          <Col span={24} className="col-img">
            <div className="box-img">
              <img className="img-fluid" src={lynnStart} alt="img" />
            </div>
          </Col>
        </Row>
      </div>
    </StyledDownloadMobile>
  );
};

export default DownloadMobile;
