import styled from 'styled-components';
import spaceLayer from 'src/assets/images/spaceLayer2.png';

export const StyledNFTSystemDesktop = styled.section`
  height: 612px;
  .container {
    max-width: 1258px;
    margin: auto;
    position: relative;
    @media (max-width: 1024px) {
      max-width: 958px;
    }
    .row-content {
      .red {
        position: absolute;
        top: 10px;
        left: -80px;
        width: 239px;
        z-index: 1;
      }
      .title {
        position: relative;
        z-index: 2;
        margin-top: 104px;
        font-family: 'BRONSON';
        font-style: normal;
        font-weight: 900;
        font-size: 60px;
        line-height: 70px;
        text-transform: uppercase;
        color: #e9e9ec;
        text-shadow: 1px -1px 10px #ff3f2a;
        @media (max-width: 1024px) {
          font-size: 48px;
        }
        p {
          margin: 0;
        }
      }
      .download-box {
        padding-top: 70px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .col-right {
        position: relative;
        height: 612px;
        .main-wrapper {
          position: absolute !important;
          bottom: 0;
          right: 0;
          height: 100%;
          @media (max-width: 1024px) {
            height: auto;
          }
          .token {
            position: absolute;
          }
          .left {
            left: 10%;
            top: 50%;
            width: 82px;
          }
          .top-right {
            right: 40px;
            top: 5%;
            width: 61px;
          }
          .bottom-right {
            right: 30px;
            bottom: 5%;
            width: 161px;
          }
        }
        .layer {
          position: absolute;
          bottom: 0;
          height: 139px;
          width: 100%;
          background-image: url(${spaceLayer});
          background-size: cover;
          background-repeat: round;
          mix-blend-mode: color-dodge;
        }
      }
    }
  }
`;
