import React, { useState } from 'react';
import btnHTP from 'src/assets/images/howtoplay/btnHTPMb.png';
import { StyledGameCard, StyledGameplayMobile } from './styled';
import MotionVisible from 'src/components/MotionVisible';
import { boxItemRightVariant } from 'src/variables/animationVariant';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import ModalGameInfo from 'src/components/Modals/ModalGameInfo';

const GameplayMobile = () => {
  const [selected, setSelected] = useState('');
  const [open, setOpen] = useState(false);

  const toggleInfo = (game) => {
    setOpen(!open);
    setSelected(game);
  };

  const slideSettings = {
    className: 'center',
    autoplay: true,
    autoplaySpeed: 100000,
    pauseOnHover: false,
    infinite: false,
    centerMode: true,
    centerPadding: '0px',
    speed: 500,
    dots: true,
    rows: 1,
    slidesPerRow: 1,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: '#AAB2C8',
          borderRadius: '50%',
        }}
      ></div>
    ),
  };

  return (
    <StyledGameplayMobile id="gameplay-htp">
      <ModalGameInfo
        selected={selected}
        visible={open}
        onCancel={() => {
          setOpen(false);
          setSelected('');
        }}
      />

      <div className="container">
        <div className="title">Game Play</div>
        <SliderSlick
          showArrow={false}
          settings={slideSettings}
          paddingX="0"
          paddingY="32px"
        >
          <MotionVisible key="reflex" variants={boxItemRightVariant}>
            <StyledGameCard game="reflex">
              <img
                src={btnHTP}
                className="btn-htp img-fluid text-center flex-center"
                alt="btn"
                onClick={() => toggleInfo('reflex')}
              />
            </StyledGameCard>
          </MotionVisible>
          <MotionVisible key="fitShape" variants={boxItemRightVariant}>
            <StyledGameCard game="fitShape">
              <img
                src={btnHTP}
                className="btn-htp img-fluid text-center flex-center"
                alt="btn"
                onClick={() => toggleInfo('fitShape')}
              />
            </StyledGameCard>
          </MotionVisible>
          <MotionVisible key="jumping" variants={boxItemRightVariant}>
            <StyledGameCard game="jumping">
              <img
                src={btnHTP}
                className="btn-htp img-fluid text-center flex-center"
                alt="btn"
                onClick={() => toggleInfo('jumping')}

              />
            </StyledGameCard>
          </MotionVisible>
        </SliderSlick>
      </div>
    </StyledGameplayMobile>
  );
};

export default GameplayMobile;
