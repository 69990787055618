import slipperModel from 'src/assets/images/howtoplay/sneaker/slipperModel.png';
import pumperModel from 'src/assets/images/howtoplay/sneaker/pumperModel.png';
import cleverModel from 'src/assets/images/howtoplay/sneaker/cleverModel.png';
import unknownModel from 'src/assets/images/howtoplay/sneaker/unknownModel.png';

export const renderImgModel = (type) => {
  switch (type) {
    case 'slipper':
      return slipperModel;
    case 'pumper':
      return pumperModel;
    case 'clever':
      return cleverModel;
    default:
      return unknownModel;
  }
};

export const renderInfoName = (type) => {
  switch (type) {
    case 'slipper':
      return 'Slipper';
    case 'pumper':
      return 'Pumper';
    case 'clever':
      return 'Clever';
    default:
      return 'Coming soon';
  }
};

export const renderInfoDesc = (type) => {
  switch (type) {
    case 'slipper':
      return 'For Reflex Game';
    case 'pumper':
      return 'For Fit The Shape';
    case 'clever':
      return 'For Jumping Battle';
    default:
      return null;
  }
};
