import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import roadmapMb from 'src/assets/images/roadmapMb3.svg';
import roadmap from 'src/assets/images/roadmap4.svg';
import { StyledRoadmapMobile } from './styled';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';

const RoadmapMobile = () => {
  return (
    <StyledRoadmapMobile id="roadmap">
      <MotionVisible className="container" variants={animationVariant}>
        <div className="title">
          <TitleEffect text="Calo Roadmap" />
        </div>
        <div className="roadmap-wrapper">
          <img className="img-fluid roadmap-mb" src={roadmapMb} />
          <img className="img-fluid roadmap-tbl" src={roadmap} />
        </div>
      </MotionVisible>
    </StyledRoadmapMobile>
  );
};

export default RoadmapMobile;
