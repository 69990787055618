import styled from 'styled-components';
import introBannerBgMb from 'src/assets/images/onepaper/introOPBgMb.png';

export const StyledIntroOPMobile = styled.div`
  overflow: visible;
  width: 100%;
  /* background-image: url(${introBannerBgMb});
  background-position: center;
  background-size: 100% 100%; */
  .container {
    padding: 40px 16px 70px 16px;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    .box-center {
      position: relative;
      text-align: center;
      width: 100%;
      .box-title {
        margin: 95px 0 42px 0;
        .main-title {
          line-height: 62px;
          text-align: center;
          > span {
            line-height: 62px;
            @media (max-width: 320px) {
              font-size: 48px;
            }
          }
        }
      }
      .box-img {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        text-align: center;
        img {
          max-width: 185px;
          @media (max-width: 320px) {
            max-width: 140px;
            }
        }
      }
    }
    .download-box {
      margin: 85px 0 0 0;
      .btn-download {
        img {
          width: 135px;
        }
        &:first-child {
          margin-right: 16px;
        }
      }
    }
    .scroll-down-wrapper {
      margin-top: 8px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
