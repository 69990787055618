import styled from 'styled-components';

export const StyledIntroductionDesktop = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  overflow: visible;
  width: 100%;
  height: calc(100% - 173px);
  position: relative;

  .container {
    position: relative;
    overflow: visible !important;
    height: 100%;
    .col-img {
      height: 100%;
      width: 100%;
      .box-img {
        margin-bottom: -5.9px;
        max-width: 550px;
        .main {
          position: absolute;
          bottom: 0;
        }
        .token-wrapper {
          position: relative;
          .token {
            position: absolute;
            top: 45%;
            left: 20%;
            width: 180px;
            @media (max-width: 1440px) {
              top: 38%;
            }
            @media (max-width: 1024px) {
              top: 40%;
            }
          }
        }
      }
    }
    .col-content {
      text-align: left;
      padding-left: 80px !important;
      .main-title {
        text-align: center;
        line-height: 80px;
      }
      .desc {
        padding: 24px 16px 32px 16px;
        max-width: 626px;
        margin-left: 24px;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
          position: relative;
          z-index: 1;
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-20deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-20deg);
        }
      }
      @media (max-width: 1024px) {
        padding-left: 48px !important;
        .main-title {
          font-size: 62px;
        }
        .desc {
          max-width: 440px;
          span {
            font-size: 14px;
          }
        }
      }

      .download-box {
        padding: 40px 0 42px 0;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .btn-bottom {
        button {
          width: 170px;
        }
      }
    }
  }
`;
