import styled from 'styled-components';

export const StyledTopics = styled.section`
  background: linear-gradient(180deg, #0d2553 5.48%, #0b1d41 84.67%);
  .container {
    padding: 40px 0;
    position: relative;

    .title {
      text-align: center;
    }

    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.5px;
      color: #ffffff;
      margin: 14px 0 95px 0;
    }

    .topics {
      max-width: 1216px;
      margin: auto;
      padding: 80px 0 100px 0;
      .topic-item {
        position: relative;
        top: 0;
        transition: top ease 0.5s;
        img {
          display: block;
          width: 100%;
        }
        .ant-image-mask {
          display: none;
        }
        &:hover {
          position: relative;
          top: -24px;
          transition: top ease 0.5s;
          opacity: 1 !important;
        }
      }
      .slick-list {
        padding-top: 24px;
        &:hover {
          .activityItem {
            opacity: 0.5;
          }
        }
      }
      .slick-dots {
        bottom: -120px;
      }
    }
  }
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.65) !important;
  }
  .ant-image-preview-img {
    /* cursor: none !important; */
    pointer-events: none !important;
  }
`;
