import styled from 'styled-components';
import sneakerNFTBgMb from 'src/assets/images/howtoplay/sneakerNFTBgMb.png';
import sneakerNFTBgTbl from 'src/assets/images/howtoplay/sneakerNFTBgTbl2.png';
import sneakerTab from 'src/assets/images/howtoplay/sneakerTab.png';
import sneakerTabActive from 'src/assets/images/howtoplay/sneakerTabActive.png';
import bubbleBg from 'src/assets/images/howtoplay/bubbleBg.png';

export const StyledNFTDetailMobile = styled.div`
  padding: 56px 0;
  background-image: url(${sneakerNFTBgMb});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media (min-width: 568px) {
    background-image: url(${sneakerNFTBgTbl});
  }
  .container {
    .title {
      font-family: 'Protector';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 46px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
    }
    .description {
      max-width: 295px;
      margin: auto;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.0125em;
      color: #ffffff;
      text-align: center;
    }
    .content-wrapper {
      padding-top: 40px;
      position: relative;
      .box-tabs {
        width: 254px;
        margin: 0 auto;
        .item-tabs {
          flex-wrap: wrap;
          position: relative;
          height: 100%;
          width: 100%;
          gap: 24px;
        }
      }
      .nft-model {
        /* z-index: 5;
        overflow: visible;
        position: relative;
        top: 50px;
        canvas {
          height: 650px;
        } */
        margin-bottom: 24px;
        .img-model {
          width: 311px;
          margin: 0 auto;
        }
      }
    }
  }
`;

export const BubbleInfo = styled.div`
  width: 263px;
  height: 100px;
  background-image: url(${bubbleBg});
  background-size: 100% 100%;
  text-align: center;
  margin: auto;
  .wrapper {
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-transform: uppercase;
  }
  .desc {
    font-family: 'Boxed';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
    color: #ffffff;
  }
`;

export const SneakerTab = styled.div`
  width: 107px;
  height: 107px;
  background-image: url(${sneakerTab});
  background-size: 100% 100%;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  position: relative;
  &:hover {
    background-image: url(${sneakerTabActive});
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  }
  &.active {
    position: relative;
    background-image: url(${sneakerTabActive});
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  }
  .wrapper {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.unknown {
      img {
        width: 85px;
      }
    }
  }
  .name {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    color: #ffffff;
    height: 100%;
    margin-top: -16px;
  }
`;
