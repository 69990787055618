import { Col, Row } from 'antd';
import React from 'react';
import iconClose from 'src/assets/images/iconClose.png';
import TitleEffect from 'src/components/TitleEffect';
import { detailContent } from './detailContent';
// styled
import { StyledModalDetailSneaker } from './styled';

const ModalDetailSneaker = (props) => {
  const { selected, visible, onCancel, ...other } = props;

  return (
    <StyledModalDetailSneaker
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      destroyOnClose
      closable
      closeIcon={<img src={iconClose} alt="iconClose" />}
      {...other}
    >
      <Row className="detail-content" align="middle" justify="center">
        <Col span={11}>
          <img
            className="img-fluid"
            src={detailContent[selected]?.img}
            alt="sneakerSample"
          />
        </Col>
        <Col span={13} className="col-content">
          <TitleEffect text={detailContent[selected]?.name} fontSize="80px" />
          <div className="description">{detailContent[selected]?.desc}</div>
        </Col>
      </Row>
    </StyledModalDetailSneaker>
  );
};

export default ModalDetailSneaker;
