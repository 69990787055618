import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import { useAppStore } from 'src/hooks/useAppStore';
import TitleEffect from 'src/components/TitleEffect';
import lynnSocial from 'src/assets/images/lynnSocial.png';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import { StyledSocial } from './styled';
import { socials } from '../socials';

const SocialDesktop = ({ fireEvent, onDownload }) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledSocial isDarkMode={isDarkMode}>
      <div className="container">
        <Row justify="space-between">
          <Col span={10} className="col-img">
            <div className="mainObject">
              <img className="img-fluid" src={lynnSocial} />
            </div>
          </Col>
          <Col span={14} className="col-content">
            <div className="title">
              <TitleEffect text="Join" fontSize="90px" />
            </div>
            <div className="title">
              <TitleEffect text="Our Community" fontSize="90px" />
            </div>
            <div className="desc flex-end">
              <span>Connect with Calo Indoor community....</span>
            </div>
            <div className="icon-wrapper">
              {socials.map((social, index) => (
                <a
                  className="image-social"
                  href={social.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => fireEvent(social.key)}
                  key={index}
                >
                  <img src={social.iconWhite} />
                  <img className="hover-img" src={social.iconColor} />
                </a>
              ))}
            </div>
            <div className="download-box flex-end">
              <div className="btn-download" onClick={() => onDownload('ios')}>
                <a
                  href="https://apps.apple.com/us/app/calo-indoor/id6443533828"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnIos} alt="download" />
                </a>
              </div>
              <div className="btn-download" onClick={() => onDownload('android')}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.calometaverse.indoor"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnAndroid} alt="download" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledSocial>
  );
};

export default SocialDesktop;
