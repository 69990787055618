import styled from 'styled-components';

export const StyledB2ETokenMobile = styled.section`
  .container {
    width: 100%;
    padding-top: 64px;
    width: 100%;
    .header {
      text-align: center;
      .t-1 {
        line-height: 60px;
        @media (min-width: 568px) {
          font-size: 50px;
        }
      }
      .t-2 {
        line-height: 30px;
        @media (min-width: 568px) {
          font-size: 60px;
        }
      }
      img {
        margin-top: 16px;
      }
    }
    .content-box {
      margin: 32px 0 192px 0;
      width: 100%;
      img {
        max-width: 295px;
      }
    }
  }
`;
