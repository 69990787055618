import styled from 'styled-components';
import socialBgMb from 'src/assets/images/socialBgMb.png';

export const StyledSocialMobile = styled.section`
  min-height: 692px;
  padding-top: 48px;
  position: relative;
  background-image: url(${socialBgMb});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .container {
    margin: auto;
    padding: 0;

    .containerTop {
      .title {
        line-height: 42px;
        text-align: center;
        padding-bottom: 16px;
      }
      .description {
        max-width: 243px;
        margin: 0 auto;
        text-align: center;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.4px;
          text-align: center;
          color: #ffffff;
          position: relative;
          z-index: 1;
        }
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            270deg,
            rgba(16, 36, 89, 0.48) 3.07%,
            rgba(18, 50, 136, 0) 89.1%
          );
          transform: skewX(-20deg);
        }
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          padding: 1px;
          background: linear-gradient(
            270deg,
            #3263d6 1.2%,
            rgba(253, 71, 76, 0) 100%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          transform: skewX(-20deg);
        }
      }
    }
    .containerBottom {
      margin-top: 48px;
      position: relative;
      min-height: 452px;
      .mainObject {
        text-align: center;
        img {
          max-width: 300px;
        }
      }
      @media (min-width: 767px) {
        max-width: 450px;
        margin: 48px auto 0 auto;
      }
      .icon-wrapper {
        margin-top: 16px;
        margin: 0 auto;
        width: 224px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        img {
          margin-bottom: 16px;
          margin-right: 16px;
          width: 40px;
        }
      }
      .download-box {
        img {
          width: 160px;
          margin: 0 8px;
        }
      }
    }
  }
`;
