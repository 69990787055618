import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components';
import TitleEffect from 'src/components/TitleEffect';
import { StyledPage404NotFound } from './styled';

const NotFoundPage = () => {
  return (
    <StyledPage404NotFound>
      <div className="wrapper flex-center">
        <TitleEffect className="t-1" text="404" fontSize="160px" />
        <TitleEffect className="t-2" text="Page Not Found" fontSize="90px" />
        <Link to="/" className="btn-home">
          <Button>GO BACK</Button>
        </Link>
      </div>
    </StyledPage404NotFound>
  );
};

export default NotFoundPage;
