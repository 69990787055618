import styled from 'styled-components';
import nftSystemBg from 'src/assets/images/nft-system/nftSystemBg.png';

export const StyledNFTSystemDesktop = styled.section`
  padding: 80px 0 0 0;
  background-image: url(${nftSystemBg});
  background-size: cover;
  background-repeat: round;
  .container {
    max-width: 1216px;
    margin: auto;
    position: relative;
    .title {
      text-align: center;
    }
    .desc {
      padding-top: 24px;
      max-width: 794px;
      margin: 0 auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
    }
    .row-content {
      position: relative;
      height: 781px;
      width: 100%;
      margin-top: 50px;
      .box-center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        max-width: 772px;
        .main-img {
          text-align: center;
        }
      }
      .box-left {
        height: 570px;
        position: absolute;
        left: 0;
        .box-item {
          cursor: pointer;
          animation: upDown 2s infinite;
          img {
            width: 205px;
            &:hover {
              filter: drop-shadow(0px 4px 5px rgba(255, 255, 255, 0.6));
            }
          }
        }
        .box-top {
          position: absolute;
          top: 40px;
          left: 20px;
        }
        .box-bottom {
          position: absolute;
          bottom: -140px;
          left: 20px;
        }
      }
      .box-right {
        height: 570px;
        position: absolute;
        right: 0;
        .box-item {
          cursor: pointer;
          animation: upDown 2s infinite;
          img {
            width: 205px;
            &:hover {
              filter: drop-shadow(0px 4px 5px rgba(255, 255, 255, 0.6));
            }
          }
        }
        .box-top {
          position: absolute;
          top: 40px;
          right: 20px;
        }
        .box-bottom {
          position: absolute;
          bottom: -140px;
          right: 20px;
        }
      }
      @keyframes upDown {
        50% {
          transform: translateY(-10px);
        }
      }
    }
  }
`;
