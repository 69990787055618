import styled from 'styled-components';

export const StyledTokenDemand = styled.div`
  padding: 80px 0 180px 0;
  @media (max-width: 768px) {
    padding: 64px 0 64px 0;
  }
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      padding: 0 24px;
    }
    .header {
      text-align: center;
    }
    .header-sm {
      text-align: center;
      .t-1 {
        line-height: 32px;
      }
    }
    .img-wrapper {
      padding-top: 80px;
      @media (max-width: 1024px) {
        padding-left: 10%;
        padding-right: 10%;
      }
      @media (max-width: 768px) {
        padding-top: 32px;
        padding-left: 0;
        padding-right: 0;
      }
      .img-demand {
        @media (max-width: 768px) {
          width: 400px;
          margin: 0 auto;
        }
        @media (max-width: 568px) {
          width: 300px;
        }
      }
      .text-demand {
        @media (max-width: 768px) {
          width: 400px;
          margin: 0 auto;
        }
        @media (max-width: 568px) {
          width: 360px;
        }
      }
      .mt-4 {
        margin-top: 40px;
      }
    }
  }
`;
