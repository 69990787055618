import React from 'react';
import TitleEffect from 'src/components/TitleEffect';
import appScreen from 'src/assets/images/appScreen3.png';
import { StyledAppScreenDesktop } from './styled';
import MotionVisible from 'src/components/MotionVisible';
import { animationVariant } from 'src/variables/animationVariant';

const AppScreenDesktop = () => {
  return (
    <StyledAppScreenDesktop>
      <div className="container">
        <MotionVisible className="title" variants={animationVariant}>
          <TitleEffect text="Let's join us to play" fontSize="100px" />
        </MotionVisible>
        <MotionVisible className="desc" variants={animationVariant}>
          Calo Indoor is built on the AR gameplay system base with an NFT
          Sneaker and its function
        </MotionVisible>
        <MotionVisible className="img-wrapper" variants={animationVariant}>
          <img className="img-fluid" src={appScreen} alt="appScreen" />
        </MotionVisible>
      </div>
    </StyledAppScreenDesktop>
  );
};

export default AppScreenDesktop;
