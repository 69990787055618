import React from 'react';
import TitleEffect from 'src/components/TitleEffect';
import MotionVisible from 'src/components/MotionVisible';
import lineArrow from 'src/assets/images/onepaper/lineArrow.svg';
import roadmap from 'src/assets/images/roadmap4.svg';
import { animationVariant } from 'src/variables/animationVariant';
import { StyledRoadmapV2Desktop } from './styled';

const RoadmapOPDesktop = () => {
  return (
    <StyledRoadmapV2Desktop id="roadmap">
      <div className="container">
        <MotionVisible className="header text-center" variants={animationVariant}>
          <TitleEffect
            className="t-2"
            text="STARTING YOUR JOURNEY"
            fontSize="70px"
          />
          <img className="text-center" src={lineArrow} alt="line" />
        </MotionVisible>
        <MotionVisible className="roadmap-wrapper" variants={animationVariant}>
          <img src={roadmap} />
        </MotionVisible>
      </div>
    </StyledRoadmapV2Desktop>
  );
};

export default RoadmapOPDesktop;
